import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getAll = () => {
  return Axios.get(API_URL + "slider");
};

export const getSingle = (id) => {
  return Axios.get(API_URL + "slider/show/" + id);
};

 