import { useEffect, useState } from "react";
import "./termsandconditions.scss";
import { getAll } from "../../service/terms";
import { Markup } from "interweave";
import Loader from "../loader/loader";

const TermsandConditions = () => {
  // States
  const [laoding, setLoading] = useState(false);
  const [state, setState] = useState([]);

  // UseEffect
  useEffect(() => {
    setLoading(true);
    getAll()
      .then((response) => {
        setState(response?.data?.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {laoding ? (
        <Loader />
      ) : (
        <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-12 px-8 terms-and-conditions">
          <div className="col-span-10 py-0 xl:py-20 lg:py-20 xs:py-10">
            {state &&
              state.map((value, index) => (
                <div key={index}>
                  {value.type === null && (
                    <h1 className="text-left font-brandonBlack text-2xl 2xl:text-homeSplashHeading xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-heading">
                      <Markup content={value.title} />
                    </h1>
                  )}
                  {value.type == "heading" && (
                    <>
                      <h2 className="text-left font-brandonBlack text-2xl xl:text-termandconditions lg:text-termandconditions md:text-termandconditions xs:text-xl leading-normal text-heading pt-8">
                        <Markup content={value.sub_title} />
                      </h2>
                    </>
                  )}

                  {value.type == "paragraph" && (
                    <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph text-heading">
                      {value.description}
                    </p>
                  )}

                  {value.type == "check_description" && (
                    <li className="text-heading text-left font-brandonLight font-bold text-smarBuildingParagraph">
                      {value.description}
                    </li>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TermsandConditions;
