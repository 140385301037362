import React from "react";
import CheckImage from "../../../images/smart-building/check.svg";
// import DesingImage from "../../../images/smart-building/design.png";
// import MieregemPlein from "../../../images/smart-building/mieregem_plein.png";
// import LaurinsGarden from "../../../images/smart-building/laurins_garden.png";
// import ResReinaert from "../../../images/smart-building/res_reinaert.png";
// import RedisentHotel from "../../../images/smart-building/rhg.png";
// import LombardEnveld from "../../../images/smart-building/lombardenveld.png";
import "./smartBuilding.scss";
import parse from "html-react-parser";

const Design = (props) => {
  const { data } = props;
  return (
    <div className="">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 xs:grid-cols-1 gap-3 xl:gap-20 lg:gap-20 py-0 xl:py-20 lg:py-20 xs:py-10 text-heading">
          <div className="order-last lg:order-first xl:order-first">
            <img
              src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                data && data[1] && data[1].image
              }`}
              alt=""
              className="w-3/4 xl:w-auto lg:w-auto mx-auto xl:mx-0 lg-mx-0"
            />
          </div>
          <div className="">
            <h1 className="text-left font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal">
              {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
            </h1>
            <div className="grid grid-cols-2 gap-2 font-brandonLight font-bold text-smarBuildingParagraph py-6">
              <div className="col-span-2 xl:col-span-1 lg:col-span-1 md:col-span-1">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-1 my-auto">
                    <img src={CheckImage} className="" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left">
                      {" "}
                      {parse(`<p>${data && data[2] && data[2]?.sub_title}</p>`)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-2 xl:col-span-1 lg:col-span-1 md:col-span-1">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-1 my-auto">
                    <img src={CheckImage} className="" />
                  </div>
                  <div className="col-span-11">
                    <p className="text-left">
                      {" "}
                      {parse(`<p>${data && data[3] && data[3]?.sub_title}</p>`)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph">
              {parse(`<p>${data && data[4] && data[4]?.description}</p>`)}
            </p>
            <div className="grid grid-cols-3 gap-10 py-0 xl:py-20 lg:py-20">
              <div className="">
                <img
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[5] && data[5].image
                  }`}
                  className=""
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[6] && data[6].image
                  }`}
                  className=""
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[7] && data[7].image
                  }`}
                  className=""
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[8] && data[8].image
                  }`}
                  className=""
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[9] && data[9].image
                  }`}
                  className=""
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
