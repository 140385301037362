import React from "react";
import Card from "./card";
import "./style.scss";
import parse from "html-react-parser";

const KubernatesCluster = (props) => {
  const { data, kubernetesTable } = props;

  return (
    <div className="bg-custom main-kubernetes">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-1 lg:grid-cols-1 xs:grid-cols-1 gap-3 xl:gap-20 lg:gap-20 py-0 xl:py-20 lg:py-20 xs:py-10 text-heading">
          <div className="">
            <h1 className="text-left uppercase font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal">
              {/* <span className="text-orange">Kubernetes </span>
              <span>managed plateforme</span> */}
              {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
            </h1>
            <p className="  font-brandonLight font-bold text-smarBuildingParagraph pt-4">
              {/* Agifly also offers{" "}
              <span className="text-orange font-brandonBold">
                pre-packaged Kubernetes infrastructure
              </span>{" "}
              that suits all kind of projects, from a develop environment to a
              staging environment to a production environment. The offers
              include;{" "}
              <span className="text-orange font-brandonBold">
                Kubernetes cluster
              </span>
              ,{" "}
              <span className="text-orange font-brandonBold">
                clustered database
              </span>{" "}
              (MySql, PosgreSql, MongoDB,…) as well as{" "}
              <span className="text-orange font-brandonBold">
                Object and Block storage
              </span>
              .
              <br />
              The platforms are{" "}
              <span className="text-orange font-brandonBold">
                fully managed and supervised
              </span>{" "}
              and integrated in our devtool (Gitlab) to offer a fully integrated
              pipeline for{" "}
              <span className="text-orange font-brandonBold">
                CI/CD purposes
              </span>
              . If required, we can also integrate them into your infrastructure
              / tools. */}
              {parse(`<p>${data && data[1] && data[1]?.description}</p>`)}
            </p>
            <div className="grid xl:grid-cols-4 sm:grid-cols-2 lg:grid-cols-4 xs:grid-cols-1 text-left font-brandonLight font-bold text-sustainabilityParagraph py-5 xl:py-10 lg:py-10 gap-8 xl:gap-8 lg:gap-8 pt-16">
              {kubernetesTable?.map((single) => (
                <Card data={JSON.parse(single?.description)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KubernatesCluster;
