import { useEffect, useState } from "react";
import TopSection from "../../components/privacyPolicy/topSection";
import AboutCookies from "./../../components/privacyPolicy/aboutCookies";
import Statistics from "./../../components/privacyPolicy/statistics";
import Contact from "./../../components/privacyPolicy/contact";
import { getAll } from "../../service/privacy";
import Loader from "../../components/loader/loader";
const Privacy = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    policy: [],
    cookies: [],
    cookiesTable: [],
    statistics: [],
    statisticsTable: [],
    contact: [],
  });

  // Use Effect
  useEffect(() => {
    setLoading(true);
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          policy: response?.data?.data?.filter(
            (row) => row.section === "policy"
          ),
          cookies: response?.data?.data?.filter(
            (row) => row.section === "cookies"
          ),
          cookiesTable: response?.data?.data?.filter(
            (row) => row.section === "cookies-table"
          ),
          statistics: response?.data?.data?.filter(
            (row) => row.section === "statistics"
          ),
          statisticsTable: response?.data?.data?.filter(
            (row) => row.section === "statistics-table"
          ),
          contact: response?.data?.data?.filter(
            (row) => row.section === "contact"
          ),
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TopSection data={state.policy} />
          <AboutCookies data={state.cookies} tableData={state.cookiesTable} />
          <Statistics
            data={state.statistics}
            tableData={state.statisticsTable}
          />
          <Contact data={state.contact} />
        </div>
      )}
    </>
  );
};
export default Privacy;
