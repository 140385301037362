import React from "react";
import Lottie from "react-lottie";
import { Markup } from "interweave";
import Sustainability from "../../../lottiesAnimation/sustainability.json";
import "./sustainability.scss";

const SustainabilityTopSection = (props) => {
  const { data } = props;
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: Sustainability,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="bg-primary">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1">
          {data.length > 0 && data.map((single, index) => (
            <>
              <div className="xl:bg-forms-homepage-tri lg:bg-forms-homepage-tri py-10 xl:py-20 lg:py-20 col-span-7 xl:col-span-7 lg:col-span-7 md:col-span-6 xs:col-span-6">
                <h1 className="text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl font-brandonBlack leading-homeSplashHeadin text-center lg:text-left xl:text-left text-white">
                  <Markup content={single.title} />
                </h1>
                <p className="text-tiny xl:text-homeSplashDetail lg:text-homeSplashDetail text-center xl:text-left lg:text-left md:text-center text-white font-brandonLight">
                  <Markup content={single.description} />
                </p>
              </div>
              <div className="col-span-5 xl:col-span-5 lg:col-span-5 md:col-span-6 xs:col-span-6 order-last xs:order-first md:order-first lg:order-last xl:order-last mx-0 xl:mx-0 lg:mx-0 xs:mx-auto">
                <Lottie options={defaultOptions} style={{ width: "80%" }} />
              </div>
            </>))}

        </div>
      </div>
    </div>
  );
};

export default SustainabilityTopSection;
