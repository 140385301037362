import BackArrow from "../../images/left.png";
// import Logo from "../../images/projectsHeaderLogo.png";
// import VideoPlaceholder from "../../images/videoPlaceholder.png";
import VideoPlayer from "react-simple-video-player";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const ProjectVideo = (props) => {
  const { data } = props;
  let Navigation = useNavigate();
  return (
    <div>
      <div className="max-w-7xl sm:w-full project_videos_section mx-auto px-6 py-12">
        {/* header */}
        <div className="flex flex-col items-center lg:flex-row lg:justify-between py-6">
          {/* left side back icon and title*/}
          <div className="py-2 w-full">
            <div className="flex items-center">
              <img src={BackArrow} alt="" className="h-4 " />
              <h4
                className="text-yellow-400 text-md border-b border-yellow-400 cursor-pointer "
                onClick={() => {
                  Navigation("/projects");
                }}
              >
                Back to all projects
              </h4>
            </div>
            <div className="py-5 px-2">
              <h2 className="text-4xl lg:text-left xs:text-center textPrimaryDark font-bold font-brandonBlack">
                {data?.title || ""}
              </h2>
            </div>
          </div>
          {/* righ side */}
          <div className="">
            {data?.logo && (
              <img
                src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${data?.logo}`}
                className="rounded lg:w-2/5 xs:w-2/6 mx-auto float-none xl:float-right lg:float-right"
                alt=""
              />
            )}
          </div>
        </div>
        {data && data.video && (
          <div className="w-full px-2 videoContainer">
            <VideoPlayer
              url={data?.video}
              // poster={data?.videoPlaceholder}
              autoplay
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ProjectVideo;
