import comingsoonimage from "../../images/logo_agifly.png";

const Comingsoon = () => {
  return (
    <div className="h-4/5 pb-12">
      <div className="flex flex-col lg:flex-row lg:flex-center items-center lg:justify-center ">
      <div class="self-center mt-16">
      <h1 className="text-homeSplashHeading  text-heading font-brandonBlack text-center">
              SOMETHING REALLY COOL IS
              </h1>
              <h1 className="text-homeSplashHeading  text-heading font-brandonBlack text-center">COMING
              <span className="text-homeSplashHeading  text-heading font-brandonBlack text-center text-orange"> SOON</span>      
            </h1>
        
        <img class="m-auto" src={comingsoonimage}></img>
        </div>
      </div>
    </div>
  );
};
export default Comingsoon;