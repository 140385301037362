import React from "react";
// import AreasImage from "../../../images/sustainability/areas.png";
import "./sustainability.scss";
import parse from "html-react-parser";

const Areas = (props) => {
  const { data } = props;
  return (
    <div className="">
      <div className="max-w-7xl mx-auto px-4 sm:px-4 text-heading">
        <div className="grid xl:grid-cols-1 lg:grid-cols-1 xs:grid-cols-1 pt-10 xl:pt-10 lg:pt-10  gap-10">
          <h1 className="text-left font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-tight">
            {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
          </h1>
          <img
            src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
              data && data[1] && data[1].image
            }`}
            alt=""
          />
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 xs:grid-cols-1 text-left font-brandonLight font-bold text-sustainabilityParagraph py-5 xl:py-10 lg:py-10 gap-10 xl:gap-20 lg:gap-20">
          <div className="">
            <p className=" pb-2 font-brandonBold">
              {parse(`<p>${data && data[2] && data[2]?.sub_title}</p>`)}
            </p>
            <p>{parse(`<p>${data && data[3] && data[3]?.description}</p>`)}</p>
          </div>
          <div className="">
            <p className=" pb-2 font-brandonBold">
              {parse(`<p>${data && data[4] && data[4]?.sub_title}</p>`)}
            </p>
            <p>{parse(`<p>${data && data[5] && data[5]?.description}</p>`)}</p>
          </div>
          <div className="">
            <p className=" pb-2 font-brandonBold">
              {parse(`<p>${data && data[6] && data[6]?.sub_title}</p>`)}
            </p>
            <p>{parse(`<p>${data && data[7] && data[7]?.description}</p>`)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Areas;
