/* eslint-disable import/no-anonymous-default-export */
import React from "react";

import ReactPlayer from "react-player";
import "./style.scss";

export default (props) => {
  const { data } = props;
  const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
  return (
    <div class="max-w-7xl mx-auto lg:px-4 sm:px-4  main-animation-bird fnt-brandon-bold">
      <div class="inner-column">
        <ReactPlayer
          url={AssetsUrl + data[0]?.image_url}
          playing={true}
          width={"100%"}
          height={"100%"}
          className="react-player "
          muted={true}
        />
        {/* <VideoPlayer url={Vedio} autoplay={true} /> */}
      </div>
    </div>
  );
};
