import React from "react";
import Lottie from "react-lottie";
import { Markup } from "interweave";
import ProjectAnimation from "../../lottiesAnimation/pixels.json";
import "./projectTopSection.scss";

const ProjectsSplashSection = (props) => {
  const { data } = props;
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: ProjectAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="bg-primary pb-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 xs:grid-cols-1">
          {data.length > 0 && data.map((single, index) => (
            <>
              <div className="xl:bg-forms-homepage-tri lg:bg-forms-homepage-tri pt-0 xl:pt-24 lg:pt-24">
                <h1 className="text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl font-brandonBlack xl:leading-homeSplashHeading lg:leading-homeSplashHeading text-center lg:text-left xl:text-left text-white">
                  <Markup content={single.title} />
                </h1>
                <h5 className="text-tiny xl:text-homeSplashDetail lg:text-homeSplashDetail text-center xl:text-left lg:text-left text-white font-brandonLight">
                  <Markup content={single.description} />
                </h5>
              </div>
              <div className="lottieAnimationProject order-last xs:order-first md:order-first lg:order-last xl:order-last">
                <Lottie options={defaultOptions} />
              </div>
            </>))}

        </div>
      </div>
    </div>
  );
};

export default ProjectsSplashSection;
