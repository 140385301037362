import React, { useCallback, useEffect, useState } from "react";
import { Markup } from "interweave";
import "./services.scss";

const ServicesScreen = (props) => {
  const { data } = props;
  const [design, setDesign] = useState([]);
  const [uiux, setUiux] = useState([]);
  const [dev, setDev] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [maintenanceimg, setMaintenanceimg] = useState([]);
  const [devimg, setDevimg] = useState([]);

  const setstyleDesign = () => {
    setUiux("");
    setDev("");
    setMaintenance("");

    setDesign("2xl:mt-32 xl:mt-32 lg:mt-32 md:mt-32 xs:mt-72");
  };

  const setstyleUiux = () => {
    setDesign("");
    setDev("");
    setMaintenance("");
    setUiux("2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-28 xs:pt-72");
  };
  const setstyleMaintenance = () => {
    setUiux("");
    setDesign("");
    setDev("");
    setMaintenance("2xl:mt-40 xl:mt-40 lg:mt-32 md:mt-32 xs:mt-72");
    setMaintenanceimg("2xl:mt-40 xl:mt-40 lg:mt-32 md:mt-32 xs:mt-0");
  };

  const setstyledev = () => {
    setUiux("");
    setDesign("");
    setMaintenance("");
    setDev("2xl:mt-32 xl:mt-32 lg:mt-32 md:mt-32 xs:mt-72");
    // setDevimg("2xl:mt-80 xl:mt-32 lg:mt-32 md:mt-32 xs:mt-0");
  };

  return (
    <div class="grid auto-cols-auto ">
      {/* Navbar Div*/}
      {/* <Sticky mode="bottom" stickyClassName={"sticky-menu"}> */}
      {/* <div className="xs:hidden lg:block md:block"> */}
      <div class="grid 2xl:grid-cols-10 xl:grid-cols-10 lg:grid-cols-10 md:grid-cols-10 -mt-8 sticky 2xl:top-90px xl:top-90px lg:top-90px md:top-90px xs:top-0  2xl:visible xl:visible lg:visible md:visible xs:invisible">
        <div class="xs:hidden lg:block md:block transform hover:scale-110 motion-reduce:transform-none  grid 2xl:col-start-2   2xl:border-b-1 2xl:col-span-2 xl:col-start-2 xl:border-b-1 xl:col-span-2 lg:col-start-2 lg:border-b-1 lg:col-span-2 md:col-start-2   md:col-span-2  border-b borderColor-orange 2xl:mr-3 xl:mr-3 lg:mr-3 md:mx-0 xs:mx-3 rounded-sm bg-white text-orange font-brandonLight text-homeSplashDetail pb-4 ">
          <a href="#DEDICATED">
            <button
              class="mt-5 font-brandonBold text-lg transform motion-safe:hover:scale-110"
              onClick={setstyleDesign}
            >
              DEDICATED
              <br /> TEAM
            </button>
          </a>
        </div>
        <div class=" xs:hidden lg:block md:block transform hover:scale-110 motion-reduce:transform-none  grid 2xl:col-start-4 2xl:border-b-1 2xl:col-span-2 xl:col-start-4 xl:border-b-1 xl:col-span-2 lg:col-start-4 lg:border-b-1 lg:col-span-2 md:col-start-4   border-b md:col-span-2 2xl:mr-3 xl:mr-3 lg:mr-3 md:mx-1.5 xs:mx-3 rounded-sm bg-white text-orange font-brandonLight text-homeSplashDetail pb-4">
          <a href="#COLLABORATIVE">
            <button
              class="mt-5 font-brandonBold text-lg transform motion-safe:hover:scale-110"
              onClick={setstyleUiux}
            >
              COLLABORATIVE <br /> WORKSHOP
            </button>
          </a>
        </div>
        <div class="xs:hidden lg:block md:block transform hover:scale-110 motion-reduce:transform-none  grid 2xl:col-start-6 2xl:border-b-1 2xl:col-span-2 xl:col-start-6 xl:border-b-1 xl:col-span-2 lg:col-start-6 lg:border-b-1 lg:col-span-2 md:col-start-6   border-b md:col-span-2 2xl:mr-3 xl:mr-3 lg:mr-3 md:ml-1.5 xs:mx-3 rounded-sm bg-white text-orange font-brandonLight text-homeSplashDetail pb-4">
          <a href="#TECHNOLOGY">
            <button
              class="mt-5 font-brandonBold text-lg transform motion-safe:hover:scale-110"
              onClick={setstyledev}
            >
              TECHNOLOGY <br /> STACK
            </button>
          </a>
        </div>
        <div class="xs:hidden lg:block md:block transform hover:scale-110 motion-reduce:transform-none  grid 2xl:col-start-8 2xl:border-b-1 2xl:col-span-2 xl:col-start-8 xl:border-b-1 xl:col-span-2 lg:col-start-8 lg:border-b-1 lg:col-span-2 md:col-start-8   border-b md:col-span-2 2xl:mr-3 xl:mr-3 lg:mr-3 md:mx-1.5 xs:mx-3 rounded-sm bg-white text-orange font-brandonLight text-homeSplashDetail pb-4">
          <a href="#INCREMENTAL">
            <button
              class="mt-5 font-brandonBold text-lg transform motion-safe:hover:scale-110"
              onClick={setstyleMaintenance}
            >
              INCREMENTAL
              <br /> DELIVERY
            </button>
          </a>
        </div>
      </div>
      {/* </div> */}
      {/* </Sticky> */}
      { }
      <div class="max-w-7xl mx-auto px-4 sm:px-6 py-16" id="DEDICATED">
        <div className="flex flex-wrap items-center">
          <div class="lg:w-1/2 xs:w-full text-left xs:px-0 lg:px-4 md:w-1/2 md:px-4 ">
            <div className={design}>
              <h1 class="font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-heading ">
                <Markup content={data.dedicated && data.dedicated[0]?.title} />
              </h1>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph  text-heading pt-2 ">
                <Markup content={data.dedicated && data.dedicated[1]?.description} />
              </p>
              <div className="flex  pt-8">
                <div>
                  <img className="lg:w-12 xs:w-24" src={data.dedicated && process.env.REACT_APP_BACKEND_ASSETS_URL + data.dedicated[2]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title"><Markup content={data.dedicated && data.dedicated[2]?.title_text} /></h2>
                  <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                    <Markup content={data.dedicated && data.dedicated[3]?.description} />
                  </p>
                </div>
              </div>
              <div className="flex pt-6">
                <div>
                  <img className="lg:w-12 xs:w-24" src={data.dedicated && process.env.REACT_APP_BACKEND_ASSETS_URL + data.dedicated[4]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title">
                    <Markup content={data.dedicated && data.dedicated[4]?.title_text} />
                  </h2>
                  <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                    <Markup content={data.dedicated && data.dedicated[5]?.description} />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 xs:w-full xs:pt-4 xs:px-0 lg:px-4 md:w-1/2 md:px-4">
            <img class="h-80  m-auto" src={data.dedicated && process.env.REACT_APP_BACKEND_ASSETS_URL + data.dedicated[6]?.image} alt="" />
          </div>
        </div>
      </div>
      <div className="bg-custom" id="COLLABORATIVE">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 py-16" id="ui-ux">
          <div className={`${uiux} flex flex-wrap items-center`}>
            <div class="lg:w-1/2 xs:w-full xs:px-0 lg:px-4 md:w-1/2 md:px-4 xs:order-last xs:pt-4 lg:order-none md:order-none">
              <img src={data.collaborative && process.env.REACT_APP_BACKEND_ASSETS_URL + data.collaborative[1]?.image} alt="" />
            </div>
            <div
              class="lg:w-1/2 xs:w-full xs:px-0 text-left lg:px-4 md:w-1/2 md:px-4"
              id="DESIGN"
            >
              <div>
                <h1 class="font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-heading ">
                  <Markup content={data.collaborative && data.collaborative[0]?.title} />
                </h1>
                <p class="font-brandonLight font-bold text-smarBuildingParagraph  text-heading pt-2 ">
                  <Markup content={data.collaborative && data.collaborative[2]?.description} />
                </p>
                <p class="font-brandonLight font-bold text-smarBuildingParagraph  text-heading pt-4 ">
                  In a relaxed and safe environment.
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap pt-6 ... text-left">
            <div class="w-2/3 lg:w-1/3 xs:w-full xs:px-0 ... lg:px-4 md:w-1/2 md:px-4">
              <div className="flex pt-8 items-center">
                <div>
                  <img className="w-8 h-6 " src={data.collaborative && process.env.REACT_APP_BACKEND_ASSETS_URL + data.collaborative[3]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title"><Markup content={data.collaborative && data.collaborative[3]?.title_text} /></h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.collaborative && data.collaborative[4]?.description} />
              </p>
            </div>
            <div class="lg:w-1/3 w-2/3 xs:w-full xs:px-0 ... lg:px-4 md:w-1/2 md:px-4">
              <div className="flex pt-8 items-center">
                <div>
                  <img className="w-8  h-6" src={data.collaborative && process.env.REACT_APP_BACKEND_ASSETS_URL + data.collaborative[5]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title">
                    <Markup content={data.collaborative && data.collaborative[5]?.title_text} />
                  </h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.collaborative && data.collaborative[6]?.description} />
              </p>
            </div>
            <div class="lg:w-1/3 w-2/3 xs:w-full xs:px-0 ... lg:px-4 md:w-1/2 md:px-4">
              <div className="flex pt-8 items-center">
                <div>
                  <img className="w-8  h-6" src={data.collaborative && process.env.REACT_APP_BACKEND_ASSETS_URL + data.collaborative[7]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title">
                    <Markup content={data.collaborative && data.collaborative[7]?.title_text} />
                  </h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.collaborative && data.collaborative[8]?.description} />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="max-w-7xl mx-auto px-4 sm:px-6 py-16" id="TECHNOLOGY">
        <div className={`${dev} flex flex-wrap items-center`}>
          <div
            class="lg:w-1/2 xs:w-full xs:px-0 text-left lg:px-4 md:w-1/2 md:px-4"
          // id="TECHNOLOGY"
          >
            <div>
              <h1 class="font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-heading ">
                <Markup content={data.stack && data.stack[0]?.title} />
              </h1>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph  text-heading pt-2 ">
                <Markup content={data.stack && data.stack[1]?.description} />
              </p>
            </div>
          </div>
          <div class="lg:w-1/2 xs:w-full xs:px-0 lg:px-4 md:w-1/2 md:px-4">
            <img class="h-72 m-auto" src={data.stack && process.env.REACT_APP_BACKEND_ASSETS_URL + data.stack[9]?.image} alt="" />
          </div>
        </div>
        <div className="flex flex-wrap pt-8">
          <div
            class="lg:w-1/2 xs:w-full xs:px-0 text-left lg:px-4 md:w-1/2 md:px-4"
            id="DESIGN"
          >
            <div>
              <div>
                <div className="flex pt-8">
                  <div>
                    <img className="w-8 h-6  " src={data.stack && process.env.REACT_APP_BACKEND_ASSETS_URL + data.stack[2]?.title_img} alt="" />
                  </div>
                  <div className="pl-4">
                    <h2 className="fnt-brandon-black clr-sub-title">
                      <Markup content={data.stack && data.stack[2]?.title_text} />
                    </h2>
                  </div>
                </div>
                <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                  <Markup content={data.stack && data.stack[3]?.description} />
                </p>
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 xs:w-full xs:px-0 text-left lg:px-4 md:w-1/2 md:px-4">
            <div>
              <div className="flex pt-8">
                <div>
                  <img className="w-8 h-6  " src={data.stack && process.env.REACT_APP_BACKEND_ASSETS_URL + data.stack[4]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title">
                    <Markup content={data.stack && data.stack[4]?.title_text} />
                  </h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.stack && data.stack[5]?.description} />
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap pt-8">
          <div
            class="lg:w-1/2 xs:w-full xs:px-0 text-left lg:px-4 md:w-1/2 md:px-4"
          // id="DESIGN"
          >
            <div>
              <div className="flex pt-8">
                <div>
                  <img className="w-8 h-6  " src={data.stack && process.env.REACT_APP_BACKEND_ASSETS_URL + data.stack[6]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title">
                    <Markup content={data.stack && data.stack[6]?.title_text} />
                  </h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.stack && data.stack[7]?.description} />
              </p>
            </div>
          </div>
          <div class="lg:w-1/2 xs:w-full xs:px-0 text-left lg:px-4 md:w-1/2 md:px-4">
            <div>
              <div className="flex pt-8">
                <div>
                  <img className="w-8 h-6  " src={data.stack && process.env.REACT_APP_BACKEND_ASSETS_URL + data.stack[8]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title"><Markup content={data.stack && data.stack[8]?.title_text} /></h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.stack && data.stack[10]?.description} />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-custom" id="INCREMENTAL">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 py-16" id="ui-ux">
          <div className="flex flex-wrap items-center">
            <div class="lg:w-1/2 xs:w-full xs:px-0 lg:px-4 md:w-1/2 md:px-4 xs:order-last xs:pt-4 lg:order-none md:order-none">
              <img src={data.delivery && process.env.REACT_APP_BACKEND_ASSETS_URL + data.delivery[2]?.image} alt="" />
            </div>
            <div
              class="lg:w-1/2 xs:w-full xs:pt-4 xs:px-0 text-left lg:px-4 md:w-1/2 md:px-4"
            // id="DESIGN"
            >
              <div>
                <h1 class="font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-heading ">
                  <Markup content={data.delivery && data.delivery[0]?.title} />
                </h1>
                <p class="font-brandonLight font-bold text-smarBuildingParagraph  text-heading pt-2 ">
                  <Markup content={data.delivery && data.delivery[1]?.description} />
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap pt-6 ... text-left">
            <div class="w-2/3 lg:w-1/3 xs:w-full xs:px-0 ... lg:px-4 md:w-1/2 md:px-4">
              <div className="flex pt-8 items-center">
                <div>
                  <img className="w-8 h-6 " src={data.delivery && process.env.REACT_APP_BACKEND_ASSETS_URL + data.delivery[3]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title">
                    <Markup content={data.delivery && data.delivery[3]?.title_text} />
                  </h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.delivery && data.delivery[4]?.description} />
              </p>
            </div>
            <div class="w-2/3 lg:w-1/3 xs:w-full xs:px-0 ... lg:px-4 md:w-1/2 md:px-4">
              <div className="flex pt-8 items-center">
                <div>
                  <img className="w-8  h-6" src={data.delivery && process.env.REACT_APP_BACKEND_ASSETS_URL + data.delivery[5]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title"><Markup content={data.delivery && data.delivery[5]?.title_text} /></h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.delivery && data.delivery[6]?.description} />
              </p>
            </div>
            <div class="w-2/3 lg:w-1/3 xs:w-full xs:px-0 ... lg:px-4 md:w-1/2 md:px-4">
              <div className="flex pt-8 items-center">
                <div>
                  <img className="w-8  h-6" src={data.delivery && process.env.REACT_APP_BACKEND_ASSETS_URL + data.delivery[7]?.title_img} alt="" />
                </div>
                <div className="pl-4">
                  <h2 className="fnt-brandon-black clr-sub-title"><Markup content={data.delivery && data.delivery[7]?.title_text} /></h2>
                </div>
              </div>
              <p class="font-brandonLight font-bold text-smarBuildingParagraph pt-3 text-heading ">
                <Markup content={data.delivery && data.delivery[8]?.description} />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="grid xl:grid-cols-10 lg:grid-cols-10 md:grid-cols-10">
        <div
          class="grid 2xl:col-start-2 2xl: col-span-3 xl:col-start-2 xl: col-span-3 lg:col-start-2 lg: col-span-3 md:col-start-2 md: col-span-3 self-center 2xl:text-left xl:text-left lg:text-left md:text-left xs:text-center"
          id="DEVELOPMENT"
        >
          <div className={dev}>
            <h1 class="font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-heading pt-16">
              INCREMENTAL{" "}
              <span class="font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-orange">
                DELIVERY
              </span>{" "}
            </h1>
            <p class="text-heading font-brandonLight font-bold text-smarBuildingParagraph">
              Our multidisciplinary team #developers have extensive experience
              in IT development. We have a wide range of development competences
              ranging from Web, Desktop apps & mobile applications.
            </p>
            <br></br>
            <p class="text-heading font-brandonLight font-bold text-smarBuildingParagraph">
              Our development is entirely done inhouse We are flexible in
              scaling up and down of (very specific) technical profiles within
              the Agilfy community
            </p>
          </div>
        </div>
        <div class="grid 2xl:col-start-7 2xl:col-span-4 xl:col-start-7 xl:col-span-4 lg:col-start-7 lg:col-span-4 md:col-start-7 md:col-span-4 xs:col-start-2 xs:col-span-8 self-center float-right">
          <div className={devimg}>
            <img
              className="2xl:h-auto block mx-auto w-full  xl:h-auto block mx-auto w-full lg:h-auto block mx-auto w-full md:h-auto block mx-auto w-full xs:h-72 block mx-auto w-full"
              src="development.png"
            ></img>
          </div>
        </div>
      </div> */}
      {/* <div
        className="grid xl:grid-cols-10 lg:grid-cols-10 md:grid-cols-10 bg-custom"
        id="MAINTENANCE"
      >
        <div class="grid 2xl:col-start-2 2xl:col-span-3 xl:col-start-2 xl:col-span-3 lg:col-start-2 lg:col-span-3 md:col-start-2 md:col-span-3 xs:order-last self-center">
          <div className={maintenanceimg}>
            <img
              className="2xl:h-auto block mx-auto w-full  xl:h-auto block mx-auto w-full lg:h-auto block mx-auto w-full md:h-auto block mx-auto w-full xs:h-72 block mx-auto w-full"
              src={ImageThree}
              alt=""
            />
          </div>
        </div>

        <div class="grid 2xl:col-start-7 2xl:col-span-3 xl:col-start-7 xl:col-span-3 lg:col-start-7 lg:col-span-3 md:col-start-7 md:col-span-3 sm:order-last self-center 2xl:text-right xl:text-right lg:text-right md:text-right xs:text-center">
          <div className={maintenance}>
            <h1 class="font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-heading pt-16">
              DEDICATED DEV{" "}
              <span class="font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-orange">
                WORKFORCE
              </span>
            </h1>
            <p class="text-heading font-brandonLight font-bold text-smarBuildingParagraph">
              After building your digital product, we ensure to keep the best
              experience for the user. We provide you a support tool to manage
              together the maintenance of you product.
            </p>
            <br></br>
            <p class="text-heading font-brandonLight font-bold text-smarBuildingParagraph"></p>
            <br></br>
            <br></br>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ServicesScreen;
