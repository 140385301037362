import React from "react";
import Check from "../../../images/hosting/check.png";
import { cmsHosting } from "./constant";
import LineImg from "../../../images/hosting/line.png";
import SingleLineImg from "../../../images/hosting/line_costum.png";
import { scroller } from "react-scroll";
import parse from "html-react-parser";
import Card from "./cmsHostingCard";

import "./style.scss";

const CmsHosting = (props) => {
  const { data, cmsTable } = props;

  const scrollToSection = () => {
    scroller.scrollTo("ContactForm", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };
  return (
    <div className="main-kubernetes">
      <div className="max-w-7xl mx-auto px-4 sm:px-4 ">
        <div className="grid xl:grid-cols-1 lg:grid-cols-1 xs:grid-cols-1 gap-3 xl:gap-20 lg:gap-20 py-0 xl:pt-20 lg:pt-20 xs:py-10 text-heading">
          <div className="">
            <h1 className="text-left uppercase font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal">
              {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
            </h1>
            <p className="  font-brandonLight font-bold text-smarBuildingParagraph pt-4">
              {parse(`<p>${data && data[1] && data[1]?.description}</p>`)}
            </p>
          </div>
        </div>
      </div>
      <div className="xl:w-11/12 lg:w-10/12 xs:-w-full px-4 m-auto pb-8">
        <div className="grid xl:grid-cols-5 sm:grid-cols-2 lg:grid-cols-5 xs:grid-cols-1 text-left font-brandonLight font-bold text-sustainabilityParagraph gap-8 xl:gap-8 lg:gap-8 pt-8">
          {cmsTable?.map((single) => (
            <Card data={JSON.parse(single?.description)} />
          ))}
          {/* {JSON.parse(cmsTable?>)?.map((single) => (
            <div className={`${single?.bgColor} font-brandonLight`}>
              <div className="py-6 text-center border-b">
                <h2 className="font-brandonBlack text-2xl xl:text-2xl lg:text-2xl md:text-2xl xs:text-base text-white">
                  {single?.name}
                </h2>
              </div>

              <div className="border-b">
                {single?.content?.map((single) => (
                  <div className="text-center py-2">
                    <div className="flex px-8 ">
                      <div className=" ">
                        <img className="h-4 w-4" src={Check} alt="" />
                      </div>
                      <div className="pl-4 text-left">
                        <h2 className="text-sm text-white">{single?.text}</h2>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="py-6 text-center  ">
                <h2
                  className="font-brandonBlack text-xl xl:text-xl lg:text-xl md:text-xl xs:text-base text-white"
                  onClick={scrollToSection}
                >
                  {single?.footer}
                </h2>
              </div>
            </div>
          ))} */}
        </div>
        <div className="grid flex xl:grid-cols-3 xl:flex lg:flex sm:hidden lg:grid-cols-3 xs:hidden text-left font-brandonLight font-bold text-sustainabilityParagraph gap-8 xl:gap-8 lg:gap-8  ">
          <div className="flex-auto">
            <div>
              <img className="h-16 m-auto" src={LineImg} alt="" />
              <h1 className="clr-blue text-center uppercase font-brandonBlack text-xl xs:text-xl leading-normal pt-4">
                SHARED
              </h1>
            </div>
          </div>
          <div className="flex-auto">
            <div>
              <img className="h-16  m-auto" src={LineImg} alt="" />
              <h1 className="clr-blue text-center uppercase font-brandonBlack text-xl xs:text-xl leading-normal pt-4">
                DEDICATED
              </h1>
            </div>
          </div>
          <div className="flex-1">
            <div>
              <img className="h-16  m-auto" src={SingleLineImg} alt="" />
              <h1 className="clr-blue text-center uppercase font-brandonBlack text-xl xs:text-xl leading-normal pt-4">
                CUSTOM
              </h1>
            </div>
          </div>
        </div>
        {/* <p className="text-left font-brandonLight font-bold text- pt-4">
              You require a fully managed CMS ? We provide <span className="text-orange font-brandonBold">Managed Wordpress Solution</span> for an extra ExVAT €49 / month</p>
       */}
      </div>
    </div>
  );
};

export default CmsHosting;
