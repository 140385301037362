import "./homeProjects.scss";
import FormImage from "../../images/home/cjh.svg";
import IxellesGif from "../../images/home/GIF-IXELLES.gif";
import Gaelens from "../../images/home/NEW-GIF-CJH.gif";
import AdercoGif from "../../images/home/NEW-AGIFLY-GIF-ADERCO.gif";
import DotedImages from "../../images/home/dotedImage.svg";
import ScreenImg from "../../images/home/Groupe-249.svg";
import { useNavigate } from "react-router-dom";
import { Markup } from "interweave";
const HomeProjects = (props) => {
  const { data } = props;
  const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;

  const windowWidth = window.innerWidth;
  let navigate = useNavigate();
  // method for card sequence
  const sequenceHandler = (index) => {
    let sequence = [];
    for (let i = 1; i < data?.length; i++) {
      sequence?.push({ id: i * 3 - 1 });
    }
    return sequence?.find((single) => single?.id === index)
      ? "mx-3 pt-16"
      : "mx-3 pt-8";
  };

  const imageHeighHandler = (value) => {
    switch (value) {
      case 1:
        return "h_180";
      case 2:
        return "h_170";
      case 3:
        return "h_100p";
    }
  };

  return (
    <div className="home-projects-container">
      {/* main_div_HomeProjects */}
      <div className="container mx-auto py-8">
        {/* All_content_div */}
        <div className="All_content_div items-center py-8 ">
          {/* heading_div */}
          <div className="heading_div text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl font-bold heading_dark">
            <h1>
              <Markup content={data[0]?.title_text} />
            </h1>
          </div>
          {/* text_main_div */}
          <div className="text_main_div flex justify-center">
            {/* text_div */}
            {/* <div className="text_div w-3/6 text-lg text_light font-semibold">
              <p className="pb-3.5 pt-2.5">
                we are digital co-creation agency focusing on projects that
                increase sustainability and prepare the world for low-carbon
                circular economy
              </p>

              <p>
                To do this we make the process of development smooth,exciting
                and efective. Chechk the about us page to learn more about to
                how we learn this
              </p>
            </div> */}
          </div>
        </div>
        {/* cards_div */}
        <div className="flex flex-wrap justify-center all_cards_container">
          {data?.map(
            (single, index) =>
              index > 0 && (
                <div
                  className={`lg:w-1/4 w-full ${sequenceHandler(
                    parseInt(index)
                  )}`}
                >
                  <div
                    className={`w-full rounded common-box font-brandonLight `}
                    style={{
                      background: index % 2 === 0 ? "#194d8b" : "#2a70c4",
                    }}
                    key={index}
                  >
                    {/* card_content_div */}
                    {single.image_title === "ADERCO MARINE" && (
                      <div
                        className="text-center py-2 px-5 flex hover_box cursor-pointer"
                        onClick={() => {
                          if (windowWidth > 1025) {
                            navigate("/project-detail/3");
                          }
                        }}
                      >
                        {/* image_heading_div */}
                        <div className="w-1/2">
                          {/* image_div */}
                          <div className="flex justify-center pt-2 aderco-box">
                            <img
                              src={AssetsUrl + single.image_url}
                              alt=""
                              className="h-full md:h-80"
                            />
                          </div>
                          <div className="flex  justify-center pt-2 aderco-box-gif">
                            <img
                              src={AssetsUrl + single.gif_url}
                              loading="lazy"
                              alt=""
                              className={`h-full`}
                            />
                          </div>
                        </div>
                        {/* heading_text_button_div */}
                        <div className="w-1/2 pt-2 px-2 flex flex-col justify-center">
                          <div>
                            {/* heading_div */}
                            <div>
                              <h1 className="m-0 font-extrabold text-xl text-white  aderco-content font-brandonBlack">
                                {single.image_title}
                              </h1>
                            </div>
                            {/* text_div */}
                            <div className="pt-4 pb-3 aderco-content">
                              <p className="text-white">{single.image_text}</p>
                            </div>
                          </div>
                          {/* button_div */}
                          <div className="py-4">
                            <button
                              className="border border-solid border-white rounded-sm text-white py-2 px-4"
                              onClick={() => {
                                navigate("/project-detail/3");
                              }}
                            >
                              TAKE A LOOK
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {single.image_title === "KULTXL" && (
                      <div
                        className="text-center py-2 px-5 positions_container hover_box cursor-pointer"
                        onClick={() => {
                          if (windowWidth > 1025) {
                            navigate("/project-detail/5");
                          }
                        }}
                      >
                        {/* image_heading_div */}
                        <div>
                          {/* image_div */}
                          <div className="flex justify-center image-container">
                            <img
                              src={AssetsUrl + single.image_url}
                              alt=""
                              className={`h_195 outside_screen`}
                            />
                          </div>
                          {/* heading_div */}
                          <div className="title_container">
                            <h1 className="m-0 font-extrabold text-xl text-white  font-brandonBlack">
                              {single.image_title}
                            </h1>
                          </div>
                        </div>
                        <div className="hover_image_container pt-4 box-one flex justify-center">
                          <img
                            src={AssetsUrl + single.gif_url}
                            loading="lazy"
                            alt=""
                            className={``}
                          />
                        </div>
                        <div className="content_btn_container">
                          {/* text_div */}
                          <div className="">
                            <p className="text-white">{single.image_text}</p>
                          </div>
                        </div>
                        {/* button_div */}
                        <div className="pb-4 pt-8 button_container pt-5">
                          <button
                            className="border border-solid border-white rounded-sm text-white py-2 px-4"
                            onClick={() => {
                              navigate("/project-detail/5");
                            }}
                          >
                            TAKE A LOOK
                          </button>
                        </div>
                      </div>
                    )}
                    {single.image_title === "CHECK JE HUIS" && (
                      <div
                        className="text-center py-2 px-5 positions_container hover_box cursor-pointer"
                        onClick={() => {
                          if (windowWidth > 1025) {
                            navigate("/project-detail/19");
                          }
                        }}
                      >
                        {/* image_heading_div */}
                        <div>
                          {/* image_div */}
                          <div className="flex justify-center image-container">
                            <img
                              src={ScreenImg}
                              alt=""
                              className={`h_195 outside_screen`}
                            />
                            <img
                              src={FormImage}
                              alt=""
                              className={`inside_screen_image shadow-lg outside_screen`}
                            />
                          </div>
                          {/* heading_div */}
                          <div className="title_container">
                            <h1 className="m-0 font-extrabold text-xl text-white  font-brandonBlack">
                              {single.image_title}
                            </h1>
                          </div>
                        </div>
                        <div className="hover_image_container pt-4 flex justify-center">
                          <img
                            loading="lazy"
                            src={AssetsUrl + single.gif_url}
                            alt=""
                            // className={`${imageHeighHandler(single.id)}`}
                          />
                        </div>
                        <div className="content_btn_container">
                          {/* text_div */}
                          <div className="">
                            <p className="text-white">{single.image_text}</p>
                          </div>
                        </div>
                        {/* button_div */}
                        <div className="pb-4 pt-8 button_container pt-5">
                          <button
                            className="border border-solid border-white rounded-sm text-white py-2 px-4"
                            onClick={() => {
                              navigate("/project-detail/19");
                            }}
                          >
                            TAKE A LOOK
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
        {/* button_div */}
        <div className="button_div py-8 z-10">
          <button
            className="rounded-sm py-2 px-4 bg-white text-md font-medium font-brandonBold"
            onClick={() => navigate("projects")}
          >
            VIEW ALL PROJECTS
          </button>
        </div>
      </div>
      <div className="absolute absolue_img_container">
        <img src={DotedImages} alt="" className="w-full" />
      </div>
    </div>
  );
};
export default HomeProjects;
