import React, { useState, useRef, useEffect } from "react";

import "./usecase-renocity.css";

// asset imports here
import axios from "axios";
import { toast } from "react-toastify";
import HeaderLogo from "../../images/usecase-alt/navigation/logo_color.svg";
import IconLogo from "../../images/usecase-alt/navigation/icon_logo.svg";
import MessageIcon from "../../images/usecase-alt/navigation/bx-message-square-detail.svg";
import Background from "../../images/usecase-renocity/section1_background.png";
import Laptop from "../../images/usecase-renocity/Screen-header.png";
import MultiViews from "../../images/usecase-renocity/how-does-it-work.svg";
import BackgroundSecondary from "../../images/usecase-alt/section3/section345_background.svg";
import Check from "../../images/usecase-alt/section3/icon_check.svg";
import clientImage from "../../images/usecase-renocity/image (1).png";
import Connection from "../../images/usecase-renocity/connection.svg";
import Graph from "../../images/usecase-renocity/graph.svg";
import Screen from "../../images/usecase-renocity/screen.svg";
import Stylo from "../../images/usecase-renocity/stylo.svg";
import BackgroundTeritary from "../../images/usecase-alt/section7/section78_background.svg";
import OptifluxDesign from "../../images/usecase-renocity/logo-agc.svg";
import LaptopWBackground from "../../images/usecase-renocity/ask-us-anything.png";
import FooterLogo from "../../images/usecase-alt/footer/logo_footer.svg";
import FooterLinkedin from "../../images/usecase-alt/footer/Icon awesome-linkedin-in.svg";
import WhyAgifly from "../../images/usecase-renocity/why-agifly.svg";

// carousel parts
import Screen1 from "../../images/usecase-renocity/screen1.png";
import Screen2 from "../../images/usecase-renocity/screen2.png";
import Screen3 from "../../images/usecase-renocity/screen3.png";
import Screen4 from "../../images/usecase-renocity/screen4.png";

// react icons here
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

const UsecaseRenocity = () => {
    const [ carouselIndex, setCarouselIndex ] = useState(1);
    const [ firstname, setFirstname ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ message, setMessage ] = useState("");
    const [ renderPhase, setRenderPhase ] = useState(0);

    const targetRef = useRef(null);

    const pageScrollRef = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", handlePageScroll);
        setRenderPhase(1);

        return () => {
            window.removeEventListener("scroll", handlePageScroll);
        }
    }, [])

    const handleCarouselLeft = () => {
        if(carouselIndex === 0) {
            setCarouselIndex(0);
        } else {
            setCarouselIndex(carouselIndex - 1);
        }
    }

    const handleCarouselRight = () => {
        if(carouselIndex === 3) {
            setCarouselIndex(3);
        } else {
            setCarouselIndex(carouselIndex + 1);
        }
    }

    const scrollToBottom = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToHome = () => {
        window.location.href = 'https://www.agifly.be/';
    }

    const handleFirstnameChange = (event) => {
        setFirstname(event.target.value)
    }
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const handlePageScroll = (event) => {
        console.log(window.scrollY)
        if(window.scrollY >= 950) {
            setRenderPhase(3);
        }
        else if(window.scrollY >= 500) {
            setRenderPhase(2);
        }
        else if(window.scrollY >= 300) {
            setRenderPhase(1)
        }
    }

    const onFormSubmit = () => {
        // send email here
        axios
        .post(process.env.REACT_APP_BACKEND_URL + "/email/send", {
            "email": email,
            "name": firstname,
            "message": message
        })
        .then((response) => {
          if (response.status === 200) {
            // Notification
            window.lintrk('track', { conversion_id: 13977202 });
            toast.success("Your message has been sent successfully!", {
                position: "top-right",
            });
          }
        })
        .catch((error) => {});
    }

    return(
        <>
            {window.screen.width > 600 ? (
                <div className = "w-full flex flex-col overflow-y-hidden" style = {{backgroundColor: "#FAFAFA"}} ref = {pageScrollRef} onScroll = {handlePageScroll}>
                    <div className = "w-full flex bg-white items-center justify-between box-border relative z-10" style = {{paddingLeft: "72px", paddingRight: "72px", height: "72px"}}>
                        <div className = "flex">
                            <a href="https://www.agifly.be/">
                                <img className = "object-contain" style = {{height: "47px"}} src = {HeaderLogo} alt = "header logo" />
                            </a>
                        </div>
                        <div className = "flex items-center">
                            <button type = "button" className = "usecase-alt-header-button-one font-sofia" style = {{marginRight: "18px"}} onClick = {scrollToBottom}>
                                <img className = "object-contain" style = {{height: "17px", marginRight: "14px"}} src = {MessageIcon} alt = "message icon" />
                                <p>CONTACT US</p>
                            </button>
                            <button type = "button" className = "usecase-alt-header-button-two font-sofia" onClick = {goToHome}>
                                <img className = "object-contain" style = {{height: "15px", marginRight: "10px"}} src = {IconLogo} alt = "icon logo" />
                                <p>OUR WEBSITE</p>
                            </button>
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex items-center relative" style = {{paddingLeft: "72px", paddingRight: "72px", height: "calc(100vh - 72px)"}}>
                        <img className = "w-full object-cover absolute left-0" style = {{top: "-10%"}} src = {Background} alt = "background" />
                        <div className = "w-full h-full absolute left-0 bg-white duration-700 ease-in-out" style = {{transitionDelay: "0.3s", top: renderPhase >= 1 ? "-100%" : "0%"}} />
                        <div className = "flex flex-1 shrink-0 flex-col items-start justify-start relative" style = {{height: "461px"}}>
                            <p className = "font-sofia font-black text-white usecase-alt-heading duration-500" style = {{transitionDelay: "1s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateY(0px)" : "translateY(15px)"}}>Renocity</p>
                            <p className = "font-sofia font-regular text-white text-left duration-500" style = {{transitionDelay: "1.1s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateY(0px)" : "translateY(15px)", fontSize: 24}}>An innovative web app to improve the energy efficiency of homes</p>
                        </div>
                        <div className = "flex shrink-0 relative justify-center" style = {{flex: 2}}>
                            <img className = "object-fit duration-700 w-full" style = {{transitionDelay: "1.2s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateX(0px)" : "translateX(15px)"}} src = {Laptop} alt = "laptop" />
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex flex-col items-center justify-center" style = {{marginTop: "160px"}}>
                        <p className = "font-sofia font-black text-black duration-500 ease-in-out" style = {{fontSize: "24px", opacity: renderPhase >= 2 ? 1 : 0, transform: renderPhase >= 2 ? "translateY(0px)" : "translateY(-15px)"}}>Renocity platform is a groundbreaking web application (owned by AGC) that helps home owners to<br/> improve the energy efficiency of their homes. It is a collaboration between<br/> AGC Glass Europe, EnergyVille and Agifly.</p>
                     </div>
                    <div className = "w-full flex flex-col box-border relative" style = {{marginTop: "70px"}}>
                        {/* background layer */}
                        <div className = "flex flex-col w-full absolute top-0 left-0">
                            <img className = "w-full object-contain relative" alt = "background secondary" src = {BackgroundSecondary} />
                            <img className = "w-full object-contain relative" style = {{marginTop: "400px"}} alt = "background territary" src = {BackgroundTeritary} />
                        </div>
                        <div className = "flex w-full box-border relative" style = {{paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                <img className = "w-full object-contain duration-1000" alt = "multiviews" src = {MultiViews} style = {{opacity: renderPhase >= 2 ? 1 : 0, transform: renderPhase >= 2 ? "translateX(0px)" : "translateX(-25px)",}} />
                            </div>
                            <div className = "flex" style = {{flex: 1.5, marginTop: "60px"}}>
                                <div className = "flex flex-1" />
                                <div className = "flex flex-col duration-1000" style = {{flex: 5, opacity: renderPhase >= 2 ? 1 : 0, transform: renderPhase >= 2 ? "translateY(0px)" : "translateY(-25px)", transitionDelay: "100ms"}}>
                                    <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>How does it work?</p>
                                    <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px", width:"92%"}}>The Renocity platform uses open data to assess a home’s energy efficiency. As a user, you enter your address and let the tool do the rest. It combines public data sources with advanced algorithms to provide an accurate estimate of your home’s energy loss. Next, you either experiment with different renovation techniques yourself or you request tailor-made recommendations to achieve a particular level of energy efficiency.<br/> As a next step, the platform enables the home owner to book the visit of a renovation expert from AGC Glass Europe. This is where the platform's role ends while the home owner’s renovation journey is completed with the expertise developed by AGC. The expert will effectively visit the home owner’s house and then select for him reliable and experienced contractors in domains such as insulation, heating systems, solar panels, et cetera.</p>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "158px"}}>
                            <div className = "w-full flex justify-center items-center relative overflow-hidden" style = {{height: "487px"}}>
                                <div className = "usecase-alt-carousel-button absolute left-0 z-10 duration-300" onClick = {() => handleCarouselLeft()} style = {{opacity: carouselIndex === 0 ? 0 : 1, marginLeft: "72px"}}>
                                    <BiLeftArrowAlt size = "32px" color = "white" />
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(0 - carouselIndex) * 60}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Screen1} alt = "screen 1" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(1 - carouselIndex) * 60}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Screen2} alt = "screen 2" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(2 - carouselIndex) * 60}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Screen3} alt = "screen 3" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(3 - carouselIndex) * 60}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Screen4} alt = "screen 4" />  
                                </div>
                                <div className = "usecase-alt-carousel-button absolute right-0 z-10 duration-300" onClick = {() => handleCarouselRight()} style = {{opacity: carouselIndex === 3 ? 0 : 1, marginRight: "72px"}}>
                                    <BiRightArrowAlt size = "32px" color = "white" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style = {{marginTop: "48px"}}>
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 0 ? 1 : 0.3}} onClick={() => setCarouselIndex(0)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 1 ? 1 : 0.3}} onClick={() => setCarouselIndex(1)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 2 ? 1 : 0.3}} onClick={() => setCarouselIndex(2)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 3 ? 1 : 0.3}} onClick={() => setCarouselIndex(3)}/>
                            </div>
                        </div>
                        <div className = "w-full flex box-border flex flex-col items-center justify-center" style = {{marginTop: "40px", paddingLeft: "15px", paddingRight: "15px"}}>
                            <p className = "font-sofia font-black text-black" style = {{fontSize: "38px"}}>When and where did we come in?</p>
                            <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", maxWidth: "800px", marginTop: "16px"}}>Agifly was involved from the get-go. By and large, we split the project into 4 stages</p>
                        </div>
                        <div className = "w-full box-border flex items-center justify-center relative" style = {{marginTop: "30px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "62px"}} src = {Stylo} alt = "light" />
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "16px", marginTop: "10px"}}>Service Design</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "5px"}}>A number of workshops helped us to define the starting point and the end goal. We did user research, mapped the available data, and looked with AGC for ways to translate all available input into an easy-to-use end product.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "66px"}} src = {Connection} alt = "pencil" />
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "16px", marginTop: "10px"}}>Product design</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "5px"}}>We then designed a couple of clickable prototypes to compare the most promising concepts. Once all stakeholders decided on the final direction to take, we developed a complete UX flow that was tested from A to Z as well.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{width: "58px"}} src = {Screen} alt = "screen" />
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "16px", marginTop: "20px"}}>Product development</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "5px"}}>Afterwards, our development team brought the desired flows to life by building a fully functioning web application.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{width: "58px"}} src = {Graph} alt = "screen" />
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "16px", marginTop: "20px"}}>Product improvement</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "5px"}}>two years after the initial launch, we developed a second version of the app for AGC that significantly speed up the tool’s calculation times.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center relative" style = {{marginTop: "82px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-col justify-center" style = {{flex: 2}}>
                                <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>Why Agifly?</p>
                                <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px", width: "85%"}}>
                                    In order to reach the climate goals, we should triple the current renovation rate in
                                    Belgium, from 1% to 3%. AGC took the challenge! They wanted to demonstrate
                                    that they could more than double the renovation rate by combining the power of
                                    digital (through the Renocity platform) with a professional face-to-face service to
                                    the home owner.<br/><br/>
                                    To achieve this, a key success factor was to motivate the home owner to take the
                                    first step. And the Renocity platform definitely proved to be an incredible ‘call to
                                    action’ success. Out of 1000 houses, more than 100 audits where booked in the
                                    first few weeks alone.<br/><br/>
                                    AGC asked Agifly to help achieve its targets because of our twofold expertise. On
                                    the one hand, we’re accustomed to translating rough R&D data into market-ready
                                    solutions. On the other hand, we supplement such a technical skillset with our
                                    proven UX track record. <br/><br/>
                                    Oh, and was the ambitious target of more than doubling the renovation rate
                                    reached? Yes, it was! Are your targets next?
                                </p>
                            </div>
                            <div className = "flex flex-3 justify-center items-center">
                                <img className = "object-contain" style = {{height: "317px", marginRight: "100px"}} src = {WhyAgifly} alt = "optiflux design" />
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center relative" style = {{marginTop: "82px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-1 justify-center items-center">
                                <img className = "object-contain" style = {{height: "317px"}} src = {OptifluxDesign} alt = "optiflux design" />
                            </div>
                            <div className = "flex flex-col justify-center" style = {{flex: 2}}>
                                <p className = "font-sofia font-regular text-black text-left italic" style = {{fontSize: "24px"}}>Agifly’s expertise proved an invaluable asset to turn<br/> the Renocity platform into a success. They translated complex data<br/> into clear recommendations, while building<br/> a top-notch online user experience at the same time.</p>
                                <p className = "font-sofia font-bold text-black text-left" style = {{fontSize: "16px", marginTop: "16px"}}>Valerie barlet,<br></br>New Business Development & Renovation Strategies AGC Glass Europe</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center relative" style = {{marginTop: "50px"}} ref={targetRef}>
                            <div className = "flex w-full items-center">
                                <div className = "flex flex-1">
                                    <img className = "object-contain" style = {{height: "803px"}} src = {LaptopWBackground} alt = "laptop with background" />
                                </div>
                                <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                    <div className = "flex flex-col" style = {{width: "396px", marginLeft: "50px"}}>
                                        <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>Ask us anything</p>
                                        <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px"}}>Want to validate an idea, build a digital product or improve<br/> an existing application<br></br>Contact us.</p>
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "First name" value = {firstname} onChange = {handleFirstnameChange} style = {{marginTop: "32px"}} />
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Email" value = {email} onChange = {handleEmailChange} style = {{marginTop: "16px"}} />
                                        <textarea className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Message" value = {message} onChange = {handleMessageChange} style = {{marginTop: "16px", height: "166px", paddingTop: "16px"}} />
                                        <button type = "button" className = "usecase-alt-button font-sofia" style = {{marginTop: "21px"}} onClick = {onFormSubmit}>CONTACT US</button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full flex justify-between items-center box-border relative" style = {{marginTop: "0px", paddingLeft: "72px", paddingRight: "72px", height: "85px", backgroundColor: "#072D5A"}}>
                            <div className = "flex items-center">
                                <img className = "object-contain" style = {{height: "36px"}} src = {FooterLogo} alt = "footer logo" />
                                <p className = "font-sofia font-regular text-white" style ={{fontSize: "12px", marginLeft: "10px"}}>© 2023 Agifly . Privacy | Terms and Conditions</p>
                            </div>
                            <div className = "flex">
                                <img className = "object-contain" style = {{height: "24px"}} src = {FooterLinkedin} alt = "footer linkedin" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className = "w-full flex flex-col" style = {{backgroundColor: "#FAFAFA"}}>
                    <div className = "w-full flex bg-white items-center justify-between box-border relative z-10" style = {{paddingLeft: "15px", paddingRight: "15px", height: "72px"}}>
                        <div className = "flex">
                            <a href="https://www.agifly.be/">
                                <img onClick={goToHome} className = "object-contain" style = {{height: "47px"}} src = {HeaderLogo} alt = "header logo" />
                            </a>
                        </div>
                        <div className = "flex items-center">
                            <button type = "button" className = "usecase-alt-header-button-one font-sofia" style = {{marginRight: "18px"}} onClick = {scrollToBottom}>
                                <img className = "object-contain" style = {{height: "17px"}} src = {MessageIcon} alt = "message icon" />
                                {/* <p>NOUS CONTACTER</p> */}
                            </button>
                            <button type = "button" className = "usecase-alt-header-button-two font-sofia" onClick = {goToHome}>
                                <img className = "object-contain" style = {{height: "15px"}} src = {IconLogo} alt = "icon logo" />
                                {/* <p>NOTRE SITE</p> */}
                            </button>
                        </div>
                    </div>
                    <div className = "w-full box-border flex flex-col items-center relative" style = {{paddingLeft: "15px", paddingRight: "15px"}}>
                        <img className = "w-full object-cover absolute left-0 top-0" src = {Background} alt = "background" />
                        <div className = "w-full flex shrink-0 flex-col items-center justify-center relative">
                            <p className = "font-sofia font-black text-white text-center" style = {{fontSize: "72px"}}>Renocity</p>
                            <p className = "font-sofia font-regular text-white usecase-alt-header-subtext text-left">An innovative web app to improve the energy efficiency of homes</p>
                        </div>
                        <div className = "w-full flex shrink-0 relative">
                            <img className = "object-fit w-full" style = {{marginTop: '24px'}} src = {Laptop} alt = "laptop" />
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex flex-col items-center justify-center" style = {{marginTop: "15px", paddingLeft: "15px", paddingRight: "15px"}}>
                        <p className = "font-sofia font-black text-black" style = {{fontSize: "24px"}}>Renocity platform is a groundbreaking web application (owned by AGC) that helps home owners to<br/>improve the energy efficiency of their homes. It is a collaboration between<br/> AGC Glass Europe, EnergyVille and Agifly.</p>
                    </div>
                    <div className = "w-full flex flex-col box-border relative" style = {{marginTop: "25px"}}>
                        {/* background layer */}
                        <div className = "flex flex-col w-full absolute top-0 left-0">
                            <img className = "w-full object-contain relative" alt = "background secondary" src = {BackgroundSecondary} />
                            <img className = "w-full object-contain relative" style = {{marginTop: "400px"}} alt = "background territary" src = {BackgroundTeritary} />
                        </div>
                        <div className = "flex flex-col w-full box-border relative" style = {{paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                <img className = "w-full object-contain" alt = "multiviews"  src = {MultiViews} />
                            </div>
                            <div className = "flex" style = {{flex: 1.5, marginTop: "20px"}}>
                                <div className = "flex flex-col" style = {{flex: 5}}>
                                    <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>How does it work?</p>
                                    <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px", width:"92%"}}>The Renocity platform uses open data to assess a home’s energy efficiency. As a user, you enter your address and let the tool do the rest. It combines public data sources with advanced algorithms to provide an accurate estimate of your home’s energy loss. Next, you either experiment with different renovation techniques yourself or you request tailor-made recommendations to achieve a particular level of energy efficiency.<br/> As a next step, the platform enables the home owner to book the visit of a renovation expert from AGC Glass Europe. This is where the platform's role ends while the home owner’s renovation journey is completed with the expertise developed by AGC. The expert will effectively visit the home owner’s house and then select for him reliable and experienced contractors in domains such as insulation, heating systems, solar panels, et cetera.</p>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px"}}>
                            <div className = "w-full flex justify-center items-center relative overflow-hidden" style = {{height: "250px"}}>
                                <div className = "usecase-alt-carousel-button absolute left-0 z-10 duration-300" onClick = {() => handleCarouselLeft()} style = {{opacity: carouselIndex === 0 ? 0 : 1, marginLeft: "15px"}}>
                                    <BiLeftArrowAlt size = "32px" color = "white" />
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(0 - carouselIndex) * 100}%`}}>
                                    <img className = "object-contain disable-highlight w-full" src = {Screen1} alt = "screen 1" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(1 - carouselIndex) * 100}%`}}>
                                    <img className = "object-contain disable-highlight w-full" src = {Screen2} alt = "screen 2" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(2 - carouselIndex) * 100}%`}}>
                                    <img className = "object-contain disable-highlight w-full" src = {Screen3} alt = "screen 3" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(3 - carouselIndex) * 100}%`}}>
                                    <img className = "object-contain disable-highlight w-full" src = {Screen4} alt = "screen 4" />  
                                </div>
                                <div className = "usecase-alt-carousel-button absolute right-0 z-10 duration-300" onClick = {() => handleCarouselRight()} style = {{opacity: carouselIndex === 3 ? 0 : 1, marginRight: "15px"}}>
                                    <BiRightArrowAlt size = "32px" color = "white" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style = {{marginTop: "25px"}}>
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 0 ? 1 : 0.3}} onClick={() => setCarouselIndex(0)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 1 ? 1 : 0.3}} onClick={() => setCarouselIndex(1)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 2 ? 1 : 0.3}} onClick={() => setCarouselIndex(2)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 3 ? 1 : 0.3}} onClick={() => setCarouselIndex(3)}/>
                            </div>
                        </div> 
                        <div className = "w-full flex box-border flex flex-col items-center justify-center" style = {{marginTop: "30px", paddingLeft: "15px", paddingRight: "15px"}}>
                            <p className = "font-sofia font-black text-black" style = {{fontSize: "38px"}}>When and where did we come in?</p>
                            <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", maxWidth: "800px", marginTop: "16px"}}>Agifly was involved from the get-go. By and large, we split the project into 4 stages</p>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center justify-between relative" style = {{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px"}}>
                            <div className = "flex w-full flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "42px"}} src = {Stylo} alt = "light" />
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "16px", marginTop: "20px"}}>Service Design</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>A number of workshops helped us to define the starting point and the end goal. We did user research, mapped the available data, and looked with AGC for ways to translate all available input into an easy-to-use end product.</p>
                            </div>
                            <div className = "flex w-full flex-col items-center usecase-alt-card" style = {{marginTop: "25px"}}>
                                <img className = "object-contain" style = {{height: "36px"}} src = {Connection} alt = "pencil" />
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "16px", marginTop: "20px"}}>Product design</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>We then designed a couple of clickable prototypes to compare the most promising concepts. Once all stakeholders decided on the final direction to take, we developed a complete UX flow that was tested from A to Z as well.</p>
                            </div>
                            <div className = "flex flex-col items-center w-full usecase-alt-card" style = {{marginTop: "25px"}}>
                                <img className = "object-contain" style = {{width: "58px"}} src = {Screen} alt = "screen" />
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "16px", marginTop: "20px"}}>Product development</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Afterwards, our development team brought the desired flows to life by building a fully functioning web application.</p>
                            </div>
                            <div className = "flex flex-col items-center w-full usecase-alt-card" style = {{marginTop: "25px"}}>
                                <img className = "object-contain" style = {{width: "58px"}} src = {Graph} alt = "screen" />
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "16px", marginTop: "20px"}}>Product improvement</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>two years after the initial launch, we developed a second version of the app for AGC that significantly speed up the tool’s calculation times.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex flex-col w-full justify-center items-center" style = {{marginTop: "25px"}}>
                                <p className = "font-sofia font-regular text-black text-center" style = {{fontSize: "24px"}}>Why Agifly?</p>
                                <p className = "font-sofia font-bold text-black text-center" style = {{fontSize: "16px", marginTop: "16px", width: "85%"}}>
                                    In order to reach the climate goals, we should triple the current renovation rate in 
                                    Belgium, from 1% to 3%. AGC took the challenge! They wanted to demonstrate
                                    that they could more than double the renovation rate by combining the power of
                                    digital (through the Renocity platform) with a professional face-to-face service to
                                    the home owner.<br/><br/>
                                    To achieve this, a key success factor was to motivate the home owner to take the
                                    first step. And the Renocity platform definitely proved to be an incredible ‘call to
                                    action’ success. Out of 1000 houses, more than 100 audits where booked in the
                                    first few weeks alone.<br/><br/>
                                    AGC asked Agifly to help achieve its targets because of our twofold expertise. On
                                    the one hand, we’re accustomed to translating rough R&D data into market-ready
                                    solutions. On the other hand, we supplement such a technical skillset with our
                                    proven UX track record. <br/><br/>
                                    Oh, and was the ambitious target of more than doubling the renovation rate
                                    reached? Yes, it was! Are your targets next?
                                </p>
                            </div>
                            <div className = "flex w-full justify-center items-center">
                                <img className = "object-contain" style = {{height: "317px"}} src = {WhyAgifly} alt = "optiflux design" />
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex w-full justify-center items-center">
                                <img className = "object-contain" style = {{height: "317px"}} src = {OptifluxDesign} alt = "optiflux design" />
                            </div>
                            <div className = "flex flex-col w-full justify-center items-center" style = {{marginTop: "25px"}}>
                                <p className = "font-sofia font-regular text-black text-center italic" style = {{fontSize: "24px"}}>Agifly’s expertise proved an invaluable asset to turn<br/> the Renocity platform into a success. They translated complex data<br/> into clear recommendations, while building<br/> a top-notch online user experience at the same time.</p>
                                <p className = "font-sofia font-bold text-black text-center" style = {{fontSize: "16px", marginTop: "16px"}}>Valerie barlet,<br></br>New Business Development & Renovation Strategies AGC Glass Europe</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px"}} ref={targetRef}>
                            <div className = "flex flex-col w-full items-center">
                                <div className = "flex w-full">
                                    <img className = "object-contain" src = {LaptopWBackground} alt = "laptop with background" />
                                </div>
                                <div className = "flex w-full" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                                    <div className = "flex flex-col w-full">
                                        <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>Ask us anything</p>
                                        <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "16px"}}>Want to validate an idea, build a digital product or improve<br/> an existing application? Contact us.<br></br>Contact us.</p>
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "First name" value = {firstname} onChange = {handleFirstnameChange} style = {{marginTop: "32px"}} />
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Email" value = {email} onChange = {handleEmailChange} style = {{marginTop: "16px"}} />
                                        <textarea className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Message" value = {message} onChange = {handleMessageChange} style = {{marginTop: "16px", height: "166px", paddingTop: "16px"}} />
                                        <button type = "button" className = "usecase-alt-button font-sofia" style = {{marginTop: "21px"}} onClick = {onFormSubmit}>CONTACT US</button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full flex flex-col box-border items-center justify-center relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px", height: "100px", backgroundColor: "#072D5A"}}>
                            <div className = "w-full flex items-center justify-between">
                                <div className = "flex items-center">
                                    <img className = "object-contain" style = {{height: "36px"}} src = {FooterLogo} alt = "footer logo" />
                                </div>
                                <div className = "flex">
                                    <img className = "object-contain" style = {{height: "24px"}} src = {FooterLinkedin} alt = "footer linkedin" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style ={{marginTop: "15px"}}>
                                <p className = "font-sofia font-regular text-white" style ={{fontSize: "12px"}}>© 2023 Agifly . Privacy | Terms and Conditions</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>  
    )
}

export default UsecaseRenocity;