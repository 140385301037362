import React, { useState } from "react";
import BackArrow from "../../images/left.png";
import FormComponent from "../productOwner/form";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";

import parse from "html-react-parser";

function ProductOwner() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <>
      <FormComponent open={open} setOpen={setOpen} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 product-owner">
        <section className="py-16 px-4">
          <div className="flex items-center -ml-4">
            <img src={BackArrow} alt="" className="h-4" />
            <h4
              className="text-yellow-400 text-md border-b border-yellow-400 cursor-pointer"
              onClick={() => {
                navigate("/join-us");
              }}
            >
              Back to all offers
            </h4>
          </div>

          <div className="flex flex-wrap -mx-4 -mb-8 pt-12">
            {location?.state?.data?.map((single, index) => (
              <div
                key={single?.id}
                className={
                  index === 0
                    ? "md:w-1/3 lg:w-1/3 xs:w-full sm:w-1/2 px-4 mb-8 text-left bg-color-blue shadow-sm pt-4 pb-16"
                    : "md:w-1/3 lg:w-1/3 xs:w-full sm:w-1/2 px-4 mb-8 text-left shadow-sm pt-4 pb-16"
                }
              >
                <div>
                  <h1
                    className={
                      index === 0
                        ? "lg:text-2xl fnt-brandon-black mb-2"
                        : "lg:text-1xl fnt-brandon-black mb-2"
                    }
                  >
                    {single?.title}
                  </h1>
                </div>
                <p className="fnt-brandon-light clr-for-text">
                  {parse(`<p>${single?.description}</p>`)}
                </p>
              </div>
            ))}
            <div className="md:w-1/3 lg:w-1/3 xs:w-full sm:w-1/2 items-center justify-center flex px-4 mb-8 text-left shadow-sm pt-4 pb-16">
              <div className="apply-btn-owner">
                <button
                  type="button"
                  className=" font-brandonBlack "
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <h2 className="font-semibold text-1xl text-white">APPLY</h2>
                </button>
                {/* <h2 className="font-semibold text-1xl"><a href="mailto:info@agifly.be">info@agifly.be</a></h2> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ProductOwner;
