import React from "react";
import { Markup } from "interweave";
import ReactPlayer from "react-player";
import "./style.scss";

// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;

const AboutGallerysection = (props) => {
  const { data } = props;
  return (
    <div className="max-w-7xl mx-auto p-4 sm:px-4 font-brandonBlack text-2xl xl:text-photoGallery lg:text-photoGallery md:3xl xs:text-xl leading-aboutPhotoGallery xl:leading-aboutPhotoGallery lg:leading-aboutPhotoGallery md:leading-tight xs:leading-tight">
      <div className="grid grid-cols-1 xl:grid-cols-12 lg:grid-cols-12 gap-3">

        <div className="col-span-0 xl:col-span-3 lg:col-span-3">
          <img
            src={AssetsUrl + data.fun[1]?.image}
            className="w-auto xl:w-auto lg:w-auto md:w-full xs:w-full"
          />

        </div>

        <div className="col-span-0 xl:col-span-4 lg:col-span-4">
          <div className="py-0 xl:pt-6 lg:pt-6 xl:pb-2 lg:pb-2 text-left">
            <Markup content={data.fun[0]?.title} />
          </div>

          <div className="">
            <img src={AssetsUrl + data.fun[2]?.image} className="w-auto xl:w-auto lg:w-auto xs:w-full" />
          </div>

        </div>


        <div className="col-span-0 xl:col-span-3 lg:col-span-3 h-auto xl:h-80 lg:h-80">
          <img
            src={AssetsUrl + data.drinks[1]?.image}
            className="w-auto xl:w-auto lg:w-auto md:w-full xs:w-full h-auto xl:h-full lg:h-full"
          />
        </div>
        <div className="my-auto text-left col-span-0 lg:col-span-2 xl:col-span-2">
          <Markup content={data.drinks[0]?.title} />
        </div>
      </div>
      {/* Second */}
      <div className="grid grid-cols-1 xl:grid-cols-12 lg:grid-cols-12 gap-3">
        <div className="col-span-0 xl:col-span-4 lg:col-span-4">
          <img
            src={AssetsUrl + data.lunch[1]?.image}
            className="w-auto xl:w-auto lg:w-auto md:w-full h-full xs:h-auto"
          />
        </div>
        <div className="col-span-0 xl:col-span-3 lg:col-span-3 my-auto">
          <div className="text-right">
            <Markup content={data.lunch[0]?.title} />
          </div>
        </div>
        <div className="col-span-0 xl:col-span-2 lg:col-span-2 xl:flex lg:flex xl:items-end lg:items-end py-0 xl:py-3 lg:py-3">
          <img
            src={AssetsUrl + data.lunch[2]?.image}
            className="w-auto xl:w-auto lg:w-auto md:w-full h-full xs:h-auto"
          />
        </div>
        <div className="col-span-0 xl:col-span-3 lg:col-span-3 py-0 xl:py-3 lg:py-3">
          <img
            src={AssetsUrl + data.lunch[3]?.image}
            className="w-auto xl:w-auto lg:w-auto md:w-full h-full xs:h-auto"
          />
        </div>
      </div>
      {/* Third */}
      <div className="grid grid-cols-1 xl:grid-cols-12 lg:grid-cols-12 gap-3">
        <div className="col-span-0 xl:col-span-4 lg:col-span-4">
          <div className="text-right py-3 xl:py-10 lg:py-10">
            <Markup content={data.payroll[0]?.title} />
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-3">
            <img src={AssetsUrl + data.payroll[1]?.image} className="w-auto xl:w-auto lg:w-auto md:w-full" />
            <div className="text-right xl:text-right lg:text-right py-0 xl:py-10 lg:py-10 block xl:hidden lg:hidden">
              <Markup content={data.flexible[0]?.title} />
            </div>
            <ReactPlayer
              url={AssetsUrl + data.payroll[2]?.video}
              playing={true}
              width="100%"
              height="269px"
              className="react-player hidden xl:block lg:block"
              muted={true}
            />
          </div>
        </div>
        <div className="col-span-0 xl:col-span-5 lg:col-span-5">
          <img src={AssetsUrl + data.flexible[1]?.image} className="w-auto xl:w-auto lg:w-auto md:w-full" />
          <div className="text-right xl:text-right lg:text-right py-0 xl:py-10 lg:py-10 hidden xl:block lg:block">
            <Markup content={data.flexible[0]?.title} />
          </div>
        </div>
        <div className="col-span-0 xl:col-span-3 lg:col-span-3">
          <div className="text-left xl:text-right lg:text-right py-0 xl:py-10 lg:py-10">
            <Markup content={data.workation[0]?.title} />
          </div>
          <img src={AssetsUrl + data.workation[1]?.image} className="w-auto xl:w-auto lg:w-auto md:w-full" />
        </div>
      </div>
      {/* Fourth */}
      <div className="grid grid-cols-1 xl:grid-cols-12 lg:grid-cols-12 gap-3 pt-3 py-0 xl:py-3 lg:py-3">
        <div className="col-span-0 xl:col-span-3 lg:col-span-3 my-auto">
          <div className="text-right xl:text-center lg:text-center">
            <Markup content={data.workshops[0]?.title} />
          </div>
        </div>
        <div className="col-span-0 xl:col-span-5 lg:col-span-5">
          <img src={AssetsUrl + data.workshops[1]?.image} className="w-auto xl:w-auto lg:w-auto md:w-full" />
        </div>
        <div className="col-span-0 xl:col-span-4 lg:col-span-4">
          <img src={AssetsUrl + data.workshops[2]?.image} className="w-auto xl:w-auto lg:w-auto md:w-full" />
        </div>
      </div>
    </div>
  );
};

export default AboutGallerysection;
