import { useEffect, useState } from "react";

import TopSection from "../../components/ourSolution/hubSpot/topSection";
import WhyHubSpot from "../../components/ourSolution/hubSpot/whyHubSpot";
import WhyAgifly from "./../../components/ourSolution/hubSpot/whyAgifly";
import Benefits from "../../components/ourSolution/hubSpot/benefits";
import Booking from "../../components/ourSolution/hubSpot/booking";
import Loader from "../../components/loader/loader";

// Services
import { getAllSplash } from "../../service/splash";
import { getAll } from "../../service/hubspot";

const HubSpot = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    hubspot: [],
    agifly: [],
    benefits: [],
    demo: [],
  });
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "5"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          hubspot: response?.data?.data?.filter(
            (row) => row.section === "hubspot"
          ),
          agifly: response?.data?.data?.filter(
            (row) => row.section === "agifly"
          ),
          benefits: response?.data?.data?.filter(
            (row) => row.section === "benefits"
          ),
          demo: response?.data?.data?.filter((row) => row.section === "demo"),
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TopSection data={state?.splashData} />
          <WhyHubSpot data={state?.hubspot} />
          <WhyAgifly data={state?.agifly} />
          <Benefits data={state?.benefits} />
          <Booking data={state?.demo} />
        </div>
      )}
    </>
  );
};
export default HubSpot;
