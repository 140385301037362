import { useEffect, useState } from "react";
import TopSection from "../../components/ourSolution/hosting/topSection";
import ManagedCloud from "../../components/ourSolution/hosting/managed";
import KubernatesCluster from "../../components/ourSolution/hosting/kubernetes";
import CmsHosting from "../../components/ourSolution/hosting/cmsHosting";
import Contact from "../../components/ourSolution/hosting/contact";
import Loader from "../../components/loader/loader";
// Services
import { getAll } from "../../service/hosting";
import { getAllSplash } from "../../service/splash";

const Hosting = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    managed: [],
    kubernetes: [],
    kubernetesTable: [],
    cms: [],
    cmsTable: [],
    touch: [],
    options: [],
  });
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "9"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          managed: response?.data?.data?.filter(
            (row) => row.section === "managed"
          ),
          kubernetes: response?.data?.data?.filter(
            (row) => row.section === "kubernetes"
          ),
          kubernetesTable: response?.data?.data?.filter(
            (row) => row.section === "kubernetes-table"
          ),
          cms: response?.data?.data?.filter((row) => row.section === "cms"),
          cmsTable: response?.data?.data?.filter(
            (row) => row.section === "cms-table"
          ),
          touch: response?.data?.data?.filter((row) => row.section === "touch"),
          options: response?.data?.data?.filter(
            (row) => row.section === "options"
          ),
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TopSection data={state?.splashData} />
          <ManagedCloud data={state?.managed} />
          <KubernatesCluster
            data={state?.kubernetes}
            kubernetesTable={state?.kubernetesTable}
          />
          <CmsHosting data={state?.cms} cmsTable={state?.cmsTable} />
          <Contact data={state?.touch} options={state?.options} />
        </div>
      )}
    </>
  );
};
export default Hosting;
