import React from "react";
import { Markup } from "interweave";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import QuotesImage from "../../images/about/round_darkblue.svg";


// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
const testimonialSlider = (props) => {
  const { data } = props;

  const slides = [];
  if (data.length > 0) {
    for (let i = 1; i < data.length; i++) {
      slides.push({
        key: i,
        title: data[i].title,
        sub_title: data[i].sub_title,
        description: data[i].description,
        image: data[i].image,
      });
    }
  }


  return (
    <div className="max-w-7xl mx-auto px-4 xl:px-12 lg:px-12 sm:px-4 py-20">
      <h1 class="text-2xl lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl font-brandonBlack xl:leading-homeSplashHeading lg:leading-homeSplashHeading text-center lg:text-left xl:text-left text-primary pb-10">
        <Markup content={data[0]?.title} />
      </h1>
      <Carousel
        // autoPlay={true}
        // interval={10000}
        // infiniteLoop={true}
        showArrows={false}
        autoFocus={true}
        // showThumbs={false}
        showStatus={false}
        useKeyboardArrows
        className=""
      >
        {slides?.map((item, index) => (
          <div className="grid grid-cols-12 mb-14 bg-lightBlue p-10 xs:p-5">
            <div className="col-span-1 self-start">
              <img src={QuotesImage} className="quotes" />
            </div>
            <div className="col-span-10">
              <div key={index}>
                <div className="text-center font-brandonBlack text-2xl xl:text-aboutUSHeading lg:text-aboutUSHeading md:text-3xl xs:text-xl leading-normal">
                  <h1 className="text-primary"><Markup content={item.title} /></h1>
                  <h1 className="text-orange font-brandonBold text-2xl xl:text-homeWorkWithUsIconHeading lg:homeWorkWithUsIconHeading md:text-3xl xs:text-xl">
                    <Markup content={item.sub_title} />
                  </h1>
                  <p className="font-brandonMedium text-orange text-2xl xl:text-homeWorkWithUsIconHeading lg:homeWorkWithUsIconHeading md:text-3xl xs:text-xl">

                  </p>
                </div>
                <div className="font-brandonLight text-smarBuildingParagraph p-0 xl:p-10 lg:p-10 md:p-10 text-primary">
                  <Markup content={item.description} />
                </div>
              </div>
              <img src={AssetsUrl + item.image} className="logo mx-auto" />
            </div>
            <div className="col-span-1 self-end">
              <img src={QuotesImage} className="quotes" />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default testimonialSlider;
