const Newyear = () => {
  return (
    <div className="grid-cols-12	 ">
        <div style={{height:"668px",position:"relative"}}>
          <iframe src="https://player.vimeo.com/video/660936742?h=95ca06cbf6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" style={{position:"absolute",top:0,left:0,width:"100%",minHeight:"668px",height:"100%"}} title="Jimvvsg"></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>

      </div>
  );
};
export default Newyear;