import React from "react";
import { Markup } from "interweave";
import "./privacyPolicy.scss";

const Statistics = (props) => {
  // Props
  const { data, tableData } = props;
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-4 ">
      <div className="grid grid-cols-1 gap-3 pt-40 text-heading">
        {data && data.map((single, index) => (
          <>
            <h1 className="text-left uppercase font-brandonBlack text-2xl xl:text-smallHeading lg:text-smallHeading md:text-3xl xs:text-xl leading-normal pt-5">
              {single.title}
            </h1>
            <p className="text-left font-brandonLight font-bold text-pargraph">
              <Markup content={single.description} />
            </p>
          </>
        ))}
        <div className="mx-0 xl:mx-52 lg:mx-52 bg-secondry text-white shadow-sm rounded mt-10 -mb-20">
          <table className="table-auto">
            <thead className="border-bottom-orange font-brandonBlack text-2xl xl:text-smallHeading lg:text-smallHeading md:text-3xl xs:text-xl leading-normal">
              <tr>
                <th className="p-4 w-1/3">NAME</th>
                <th className="p-4 w-1/3">STORAGE DURATION</th>
                <th className="p-4 w-1/3">USAGE</th>
              </tr>
            </thead>
            <tbody className="text-pargraph">
              {tableData.length > 0 && JSON.parse(tableData[0].table).map((single, index) => (
                <tr className="border-bottom-lighBlue" key={index}>
                  <td className="p-4">{single.name}</td>
                  <td className="p-4">{single.storage_duration}</td>
                  <td className="p-4">{single.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
