import React from "react";
import { Markup } from "interweave";
import "./style.scss";

// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
const boardMember = (props) => {
  const { data,dataDesc } = props;
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 main-team-section">
      <section className="py-16 px-4">
        <h1 className="text-left text-left text-xl fnt-brandon-black lg:text-4xl md:text-3xl sm:text-2xl   lg:text-left xl:text-left ">
          <Markup content={data[0]?.title} />
        </h1>
        <div className="flex flex-wrap -mx-4  mb-8 mt-4">
          {dataDesc && dataDesc[0]?.description}
        </div>
        <div className="flex flex-wrap -mx-4 -mb-8 mt-8">
          {data?.map((value, index) => index > 0 && (
            <div
              className="md:w-1/3 lg:w-1/4 xs:w-full sm:w-1/2 px-4 mb-8 "
              key={value?.id}
            >
              <div className="flex justify-center">
                <img
                  class="rounded shadow-md rounded-2xl"
                  src={AssetsUrl + value?.image}
                  alt=""
                />
              </div>
              <div className="mt-3 leading-tight">
                <h1 className="member-name  lg:text-2xl fnt-brandon-black">
                  <Markup content={value.sub_title} />
                </h1>
                <p className="text-orange lg:text-1xl font-semibold">
                  <Markup content={value.description} />
                </p>
              </div>
            </div>
          ))}
        </div>

      </section>
    </div>
  );
};

export default boardMember;
