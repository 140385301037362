import { useEffect, useState } from "react";
import AboutSplash from "../../components/aboutTopSection";
import AboutGallery from "../../components/aboutPhotoGallery";
import TeamMember from "../../components/team/team";
import BoardMember from "../../components/boardMember/boardMember";
import OurPurpose from "../../components/ourPurpose/ourPurpose";
import OurPartner from "../../components/aboutUs/ourPartner";
import Partners from "../../components/partners/partners";
import TestimonialSlider from "../../components/aboutUs/testimonialSlider";
import CTA from "../../components/cta/cta";
import Loader from "../../components/loader/loader";

// Services
import { getAllSplash } from "../../service/splash";
import { getAll } from "../../service/aboutUs";

const About = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    fun: [],
    drinks: [],
    lunch: [],
    payroll: [],
    flexible: [],
    workation: [],
    workshops: [],
    ourPurpose: [],
    belgium: [],
    pakistan: [],
    boardMember: [],
    partners: [],
    testimonial: [],
  });
  // UseEffect
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "7"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          // Gallery
          fun: response?.data?.data?.filter((row) => row.section == "fun"),
          drinks: response?.data?.data?.filter(
            (row) => row.section == "drinks"
          ),
          lunch: response?.data?.data?.filter((row) => row.section == "lunch"),
          payroll: response?.data?.data?.filter(
            (row) => row.section == "payroll"
          ),
          flexible: response?.data?.data?.filter(
            (row) => row.section == "flexible"
          ),
          workation: response?.data?.data?.filter(
            (row) => row.section == "workation"
          ),
          workshops: response?.data?.data?.filter(
            (row) => row.section == "workshops"
          ),
          // Our Purpose
          ourPurpose: response?.data?.data?.filter(
            (row) => row.section == "our-purpose"
          ),
          // Team
          belgium: response?.data?.data?.filter(
            (row) => row.section == "belgium"
          ),
          pakistan: response?.data?.data?.filter(
            (row) => row.section == "pakistan"
          ),
          // Board Member
          boardMember: response?.data?.data?.filter(
            (row) => row.section == "board"
          ),
          boardMemberDesc: response?.data?.data?.filter(
            (row) => row.section == "board-desc"
          ),
          
          // Partners
          partners: response?.data?.data?.filter(
            (row) => row.section == "partners"
          ),
          // Testimonials
          testimonial: response?.data?.data?.filter(
            (row) => row.section == "about-us"
          ),
        })
        );
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <AboutSplash data={state?.splashData} />
          <AboutGallery data={state} />
          <OurPurpose data={state?.ourPurpose} />
          <TeamMember
            teamMemberBelgium={state?.belgium}
            // teamMemberPakistan={state?.pakistan}
          />
          <BoardMember data={state?.boardMember} dataDesc={state?.boardMemberDesc} />

          <OurPartner data={state?.partners} />
          {/* <Partners /> */}
          <TestimonialSlider data={state?.testimonial} />
     
          <CTA />
        </div>
      )}
    </>
  );
};
export default About;
