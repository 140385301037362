/* eslint-disable import/no-anonymous-default-export */
import Check from "../../../images/hosting/check.png";
import "./style.scss";
import { scroller } from "react-scroll";

export default (props) => {
  const { data } = props;
  const scrollToSection = () => {
    scroller.scrollTo("ContactForm", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

  const InnerCard = (props) => {
    const { single } = props;
    return (
      <div className="text-center py-2">
        <div className="flex px-8 ">
          <div className=" ">
            {single?.check === true ? (
              <img className="h-4 w-4" src={Check} alt="" />
            ) : (
              <div className="h-4 w-4"></div>
            )}
          </div>
          <div className="pl-4 text-left">
            <h2 className="text-sm text-white">{single?.title}</h2>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={` bg-light font-brandonLight`}>
      <div className="px-6 text-center ">
        <h2 className="font-brandonBlack py-2 text-2xl xl:text-2xl lg:text-2xl md:text-2xl xs:text-base text-white border-b">
          {data?.title}
        </h2>
      </div>
      <h2 className="text-orange font-brandonBold text-xl xl:text-xl lg:text-xl md:text-xl xs:text-base text-center ">
        Kubernetes
      </h2>
      {data?.options?.map((single) => {
        if (single?.Sub_title === "Kubernetes") {
          return <InnerCard single={single} />;
        }
      })}
      <h2 className="text-orange font-brandonBold text-xl xl:text-xl lg:text-xl md:text-xl xs:text-base text-center ">
        Storage
      </h2>
      {data?.options?.map((single) => {
        if (single?.Sub_title === "Storage") {
          return <InnerCard single={single} />;
        }
      })}
      <h2 className="text-orange font-brandonBold text-xl xl:text-xl lg:text-xl md:text-xl xs:text-base text-center ">
        Database
      </h2>
      {data?.options?.map((single) => {
        if (single?.Sub_title === "Database") {
          return <InnerCard single={single} />;
        }
      })}
      <div className="p-6 text-center  border-t ">
        <h3
          className="font-brandonBlack uppercase text-l xl:text-l lg:text-l md:text-l xs:text-base text-white"
          onClick={scrollToSection}
        >
          {data?.footer}
        </h3>
      </div>
    </div>
  );
};
