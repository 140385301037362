import RenoCity from "../../images/ourProjects/Renocity.png";
import Gaelens from "../../images/ourProjects/Gaelens.png";
import Aderco from "../../images/ourProjects/Aderco.png";
import HUB from "../../images/ourProjects/HUB.png";
import KultXL from "../../images/ourProjects/KultXL.png";
import Lombaerdenveld from "../../images/ourProjects/Lombaerdenveld.png";
import Mierechemplein from "../../images/ourProjects/Mierechemplein.png";
import NWO from "../../images/ourProjects/NWO.png";
import Optiflux from "../../images/ourProjects/Optiflux.png";
import Quickscan from "../../images/ourProjects/Quickscan.png";
import Raywood from "../../images/ourProjects/Raywood.png";
import ResidentReinaart from "../../images/ourProjects/Residentie-reinaart.png";
import Docubuild from "../../images/ourProjects/Docubuild.png";
import WTW from "../../images/ourProjects/WTW.png";
import WijKopenVastgoed from "../../images/ourProjects/Wij-kopen-vastgoed.png";
import KBC from "../../images/ourProjects/KBC.png";
import Monhangar from "../../images/ourProjects/Monhangar.png";
import TopInterieur from "../../images/ourProjects/Top-interieur.png";

import videoAderco from "../../images/videos/Aderco.png";
import videoAGC from "../../images/videos/AGC.png";
import videoBEFUTURA from "../../images/videos/BEFUTURA.png";
import videoBIM from "../../images/videos/BIM.png";
import videoBIP from "../../images/videos/BIP.png";
import videoChallengeAirlines from "../../images/videos/ChallengeAirlines.png";
import videoCJH from "../../images/videos/CJH.png";
import videoGaelens from "../../images/videos/Gaelens.png";
import videoHUB from "../../images/videos/HUB.png";
import videoIxelles from "../../images/videos/Ixelles.png";
import videoKBC from "../../images/videos/KBC.png";
import videoLombardenveld from "../../images/videos/Lombardenveld.png";
import videoMieregemplein from "../../images/videos/Mieregemplein.png";
import videoNTT from "../../images/videos/NTT.png";
import videoNWO from "../../images/videos/NWO.png";
import videoOPTIFLUX from "../../images/videos/OPTIFLUX.png";
import videoQUICKSCAN from "../../images/videos/QUICKSCAN.png";
import videoRaywood from "../../images/videos/Raywood.png";
import videoResidentieReinart from "../../images/videos/ResidentieReinart.png";
import videoStadbuild from "../../images/videos/Stadbuild.png";
import videoWarmtewerk from "../../images/videos/Warmtewerk.png";
import videoWJIKOPEN from "../../images/videos/WUI-KOPEN.png";

import LaurinsGarden from "../../images/ourProjects/LAURINS.png";
import NTT from "../../images/ourProjects/NTT.png";
import ENGIE from "../../images/ourProjects/ENGIE.png";
import CheckJeHuis from "../../images/ourProjects/CJH.png";
import RadissonMobile from "../../images/ourProjects/Radissomobile.png";
import ChallengeAirline from "../../images/ourProjects/challengeAirline.png";

import agcLogo from "../../images/projects/logo/agc.svg";
import gaelensLogo from "../../images/projects/logo/gaelens.svg";
import befutura from "../../images/projects/logo/befutura.svg";
import adercoLogo from "../../images/projects/logo/aderco.svg";
import hubLogo from "../../images/projects/logo/hub_logo.png";
import mierechempleinLogo from "../../images/projects/logo/mierechemplein.svg";
import nwoLogo from "../../images/projects/logo/nwo.svg";
import optifluxLogo from "../../images/projects/logo/optiflux.svg";
import quickscanLogo from "../../images/projects/logo/quickscan_logo.svg";
import raywoodLogo from "../../images/projects/logo/raywood.svg";
import residentie_reinaartLogo from "../../images/projects/logo/residentie_reinaart.svg";
import docubuilLogo from "../../images/projects/logo/docubuild_logo.png";
import warmtewerktLogo from "../../images/projects/logo/warmtewerkt_logo.png";
import wij_kopen_vastgoedLogo from "../../images/projects/logo/wij_kopen_vastgoed.svg";
import kbcLogo from "../../images/projects/logo/kbc.svg";
import top_interieurLogo from "../../images/projects/logo/top_interieur.svg";
import ntt_logo from "../../images/projects/logo/ntt_logo.png";
import engie_logo from "../../images/projects/logo/engie_logo.png";
import checkjehuis_logo from "../../images/projects/logo/checkjehuis_logo.png";
import radisson_logo from "../../images/projects/logo/radisson_logo.svg";
import challengeairlines_logo from "../../images/projects/logo/challengeairlines_logo.png";
import ixelles_logo from "../../images/projects/logo/ixelles_logo.png";
export const buttonsConstant = [
  {
    id: 1,
    name: "ScaleUp",
    selected: false,
  },
  {
    id: 2,
    name: `SME`,
    selected: false,
  },
  {
    id: 3,
    name: "Corporate",
    selected: false,
  },
  {
    id: 4,
    name: "Government",
    selected: false,
  },
  {
    id: 5,
    name: "SASS",
    selected: false,
  },
  {
    id: 6,
    name: "Web Apps",
    selected: false,
  },
  {
    id: 7,
    name: "Mobile Apps",
    selected: false,
  },
  {
    id: 8,
    name: "Websites",
    selected: false,
  },
  {
    id: 9,
    name: "digitalSolutions",
    selected: false,
  },
  {
    id: 10,
    name: "StartUp",
    selected: false,
  },
  {
    id: 11,
    name: "Apps",
    selected: false,
  },
];
export const All_Projects = [
  {
    id: 1,
    title: "AGC - RENOCITY ",
    content:
      "Engage and delight homeowners by unburdening them of the practicalities of renovations",
    key: "3",
    slug: "agc-renocity",
    projectType: ["Web Apps"],
    type: "Corporate",
    Image: RenoCity,
    video:
      "https://player.vimeo.com/external/661211688.hd.mp4?s=a2eb86cbc71101da4d5a59e5e1499495537b1776&profile_id=169",
    videoPlaceholder: videoAGC,
    logo: agcLogo,
    desc: `AGC Glass Europe has launched a new 'Renocity' platform in collaboration with Energyville and 
    UGent as well various towns and municipalities in Belgium. The web app allows users to analyze the 
    energy loss of their home and have recommendations for retrofitting that can be carried out to improve
    the energy efficiency of a building (wall insulation, roof insulation, heating pump, solar panels...)`,
    company: `AGC Glass Europe is an international group, based in Louvain-la-Neuve, which produces and 
    processes flat glass for construction, automotive, solar applications, and certain specialized industries.
     It is the European branch of the world leader in flat glass of the Japanese group Asahi The quotation,
    as well as the connection with entrepreneurs, is done directly via the platform.`,
    result: `Renocity has become an essential platform for cities and towns to communicate on renovations of
    private homes. Each day, Renocity analyzes hundreds of homes and connects owners with contractors`,
    challenge: `The tool is data-driven to know with great precision what the energy performance of buildings 
    is. Understanding and connecting with the different APIs has been a challenge throughout the development.`,
    benefits: `A simple and intuitive tool that reduces CO² emissions and energy losses in homes.`,
  },
  {
    id: 2,
    title: "BIM",
    slug: "bim",
    content:
      "A state of the art document management system between home buyers and the real estate developer",
    key: "2",
    projectType: ["Web Apps"],
    type: "SME",
    Image: Gaelens,
    video:
      "https://player.vimeo.com/progressive_redirect/playback/661211820/rendition/1080p/1080p.mp4?loc=external&signature=1ea21baf960f42891effd0bc435acf7b4467d85aa78f430fa0a7408a19e20c30",
    videoPlaceholder: videoGaelens,
    logo: gaelensLogo,
    desc: `Gaelens' BIM (Building Information Management) project allows future buyers of acquired real
    estate that they bought or plan to buy, to benefit from all the documents necessary for the purchase. 
    The platform centralizes and coordinates the purchase and sale of goods for all matters concerning the
    approval of sales files, invoices, and official sales documents between the promoter and the purchaser.`,
    company: `Gaelens Bouwprojecten is a real estate developer located in Ghent and has been building high-end
    apartments for several years in different regions of Belgium.`,
    result: `The SaaS was in development for 6 months before being tested and put into production for the go-live.`,
    challenge: `Before using the platform, everything was done manually through email. It was difficult to keep 
    track of the various choices of their customers and the potential for making mistakes became bigger, as they
    were developing more and more apartment buildings. `,
    benefits: `The SaaS is being widely used by their real estate customers and follow up has never been easier with lesser mistakes and administrative burden`,
  },
  {
    id: 3,
    title: "ADERCO MARINE",
    slug: "aderco-marine",
    content:
      "Maritime mobile app to solve engine problems and access to fuels data",
    key: "3",
    projectType: ["Mobile Apps"],
    Image: Aderco,
    type: "Corporate",
    video:
      "https://player.vimeo.com/progressive_redirect/playback/670114940/rendition/1080p/1080p.mp4?loc=external&signature=875995ad9a9bec4ac395b2afe799a27e4d718d2db194c4eee8e8f65de8b915fc",
    videoPlaceholder: "",
    logo: adercoLogo,
    desc: `For Aderco, we’ve built a maritime application The app will be destined to two different types of users. 
    On the one hand, seafarers (chief engineers) as a tool to assist them in solving technical issues. The other 
    target group is the operational managers working ashore.`,
    company: `The Aderco concept is the result of 30 years of experience in treating fuel-related problems in close 
    partnership with their clients.`,
    result: `A mobile app accessible online and offline which help to resolve boat engine problems and provide acces to VPS fuel data `,
    challenge: `Create an easy support tool, accessible even onboard without internet connexion`,
    benefits: `An easy-to-use mobile app, accessible everywhere that help to fix engine and fuel problem without
    calling the customer service of Aderco. `,
  },
  {
    id: 4,
    title: "HUB BRUSSELS",
    slug: "hub-brussels",
    content: "UX/UI of a diagnostic platform for Brussels' businesses",
    key: "4",
    projectType: ["Web Apps"],
    Image: HUB,
    type: "Government",
    video:
      "https://player.vimeo.com/external/661212022.hd.mp4?s=0408b39d0c9d9c34ee5c74c7ad16cb18ba699eda&profile_id=175",
    videoPlaceholder: videoHUB,
    logo: hubLogo,
    desc: `In collaboration with hub.Brussels, we have studied and set up an online self-diagnostic platform for
    commerce that is based in the Brussels region.The project started in pre-development with a UX 
    (User Experience) study, with an analysis with the target audience, and then UI (User Interface) for the 
    creation of the screens and wireframes to be developed so that the project culminates in technical analysis.`,
    company: `hub.Brussels is the Brussels agency for business support. It helps Brussels businesses and shops to 
    develop, expand abroad, and attract foreign investment in Brussels.`,
    result: `After our UX/UI researches and design, we have helped hub.brussels with the writing of a tender for
    development purposes. `,
    challenge: `Starting from nothing in terms of solution, the challenge was to find how to present an auto-diagnostic
    that fits with a totally different persona.`,
    benefits: `The researches and the UI created for hub is 100% adapted and tested on the user`,
  },
  {
    id: 5,
    title: "KULTXL",
    slug: "kultxl",
    content: "The cultural platform for the city of Ixelles",
    key: "4",
    projectType: ["Web Apps"],
    Image: KultXL,
    type: "Government",
    video:
      "https://player.vimeo.com/external/661212106.hd.mp4?s=6b0cf3ae470d885f0b216735c3132511e56da2d9&profile_id=169",
    videoPlaceholder: videoIxelles,
    logo: ixelles_logo,
    desc: `The city of Ixelles issued a tender for the development of its cultural website.
    The objective of this public service contract is the creation, realization, and maintenance of a communication platform to support the cultural operators active in Ixelles and is destined to the citizens of Ixelles.`,
    company: `Ixelles is one of the 19 municipalities of the Brussels-Capital Region, Belgium.`,
    result: `A website that allows the user to find a cultural event by venue, category, or name of the event. Moreover, artists and other cultural professionals can find all information that concerns them on the platform.`,
    challenge: `Propose the best way for the user to find what they are looking for, knowing the content of the website is wide (culture is really wide). `,
    benefits: `Cultural events in Ixelles easily accessible to everyone`,
  },
  {
    id: 6,
    title: "LOMBARDENVELD",
    slug: "lombardenveld",
    content: "A high-end real estate project in the center of Merchtem",
    key: "2",
    projectType: ["Websites"],
    type: "SME",
    video:
      "https://player.vimeo.com/external/661212178.hd.mp4?s=17c68aee89315e101f17227fa6fe4cbcce610643&profile_id=175",
    videoPlaceholder: videoLombardenveld,
    Image: Lombaerdenveld,
    desc: `Lombardenveld is a real estate project developed in the city of Merchtem near Brussels. The website allows potential buyers to visualize the apartments that are for sale. The website provides information about both the project as well as the various apartments that are for sale, such as floorplan information, pricing... But also provides information about the surrounding neighborhood.`,
    company: `Gaelens Bouwprojecten is a real estate developer located in Ghent and has been building high-end apartments for several years in different regions of Belgium`,
    result: `The Lombardenveld website helped the real estate buyers to visualize  and understand the project better as construction works hadn't begun yet.`,
    challenge: `There were no great challenges encountered`,
    benefits: `The real estate promotor created much more visibility and as a result, was able to sell more than 75% of the plan before construction works had begun.`,
  },
  {
    id: 7,
    title: "MIEREGEMPLEIN",
    slug: "mieregemplein",
    content: "A high-end real estate project in the center of Merchtem",
    key: "2",
    projectType: ["Websites"],
    type: "SME",
    Image: Mierechemplein,
    video:
      "https://player.vimeo.com/external/661212232.hd.mp4?s=4060957405cf9d513f95446bb790d223ce5cbe0d&profile_id=169",
    videoPlaceholder: videoMieregemplein,
    logo: mierechempleinLogo,
    desc: `Mieregemplein is a real estate project developed in the city of Merchtem near Brussels. The website 
    allows potential buyers to visualize the apartments that are for sale. The website provides information about
    both the project as well as the various apartments that are for sale, such as floorplan information, 
    pricing... But also provides information about the surrounding neighborhood.`,
    company: `Gaelens Bouwprojecten is a real estate developer located in Ghent and has been building high-end 
    apartments for several years in different regions of Belgium`,
    result: `The Mieregemplein website helped the real estate buyers to visualize  and understand the project 
    better as construction works hadn't begun yet. `,
    challenge: `There were no great challenges encountered`,
    benefits: `The real estate promotor created much more visibility and as a result, was able to sell more than
    75% of the plan before construction works had begun. `,
  },
  {
    id: 8,
    title: "NWO",
    slug: "nwo",
    content:
      "A passionate wine trader offering a wide range of high-end wines from all over the world",
    key: "1",
    projectType: ["Websites"],
    type: "StartUp",
    Image: NWO,
    video:
      "https://player.vimeo.com/external/661212383.hd.mp4?s=2d5bd6e8f77da908e20ee256d94a489bed25a211&profile_id=175",
    videoPlaceholder: videoNWO,
    logo: nwoLogo,
    desc: `NWO offers a wide range of high-end wines from all over the world. Their wines are stored in the best conditions and client satisfaction is one of our main concerns.
    Their focus is not only on selling wine but also on giving the best advice possible.`,
    company: `NWO is a passionate wine trader in the city of Ghent`,
    result: `The website needed to be up and running fairly quickly for a limited budget. In less than 2 weeks the job was finalized. `,
    challenge: `There were no great challenges encountered`,
    benefits: `NWO has more visibility nowadays with a running website`,
  },
  {
    id: 9,
    title: "OPTIFLUX",
    slug: "optiflux",
    content:
      "Optiflux is an interface that monitors the data about the storage conditions of the fruit that is being stored in cooling cells.  ",
    key: "1",
    projectType: ["Web Apps"],
    type: "ScaleUp",
    Image: Optiflux,
    video:
      "https://player.vimeo.com/external/661212428.hd.mp4?s=69da6db8ef4919c6320d8a345640982cbae250a7&profile_id=169",
    videoPlaceholder: videoOPTIFLUX,
    logo: optifluxLogo,
    desc: `Optiflux closely monitors the storage conditions of the fruit that is being stored into cooling cells for a couple of months before being sold onto the market. Various measurements; O2, CO2, ethylene, Room temperature, Fruit temperature, Relative humidity, Pressure, Air quality… are being analyzed through the interface to follow up on the condition of the fruit. When values are out of range, the alert system notifies the administrator that can intervene rapidly to bring the measurements rapidly back to normal.`,
    company: `Optiflux is a scale-up that sells the interface on a license basis to their customers`,
    result: `The SaaS was in development for of 5 months for the V1 of the app. `,
    challenge: `We had to phase the development to go live with one of the licenses halfway through the development so that the Optiflux customers could start using the interface.`,
    benefits: `The Optiflux interface was put to use in the first season that Optiflux raised its capital to bring the company to the next level.`,
  },
  {
    id: 10,
    title: "QUICKSCAN",
    slug: "quickscan",
    content:
      "A web app that helps SPW to create awareness about energy-efficient renovations",
    key: "4",
    projectType: ["Web Apps"],
    Image: Quickscan,
    video:
      "https://player.vimeo.com/external/661212472.hd.mp4?s=e62decdd0daaea5369751eaee779c45fbe14eb75&profile_id=175",
    videoPlaceholder: videoQUICKSCAN,
    type: "Government",
    logo: quickscanLogo,
    desc: `The Public Service of the Walloon region, SPW (Service Public Wallonie) submitted a request for the development of a web app allowing homeowners in the Walloon region (the French-speaking part of Belgium) to assess the energy efficiency of their houses without having to pay an inspector to provide the assessment.
    The objective for SPW is to obtain an average A-level energy efficiency across Wallonia by 2050. Through the web app homeowners will receive recommendations in line with energy savings for their homes. The recommendations are linked to subsidies provided by the French government to stimulate energy savings across Wallonia.`,
    company: `The Public Service of Wallonia (SPW) brings its expertise to the Walloon Government and implements its policies by carrying out the missions of general interest that it entrusts to it.`,
    result: `Monquickscan is widely used in Wallonia by homeowners. The app has known great success and will continue to evolve with new features and functionalities over the years to come. `,
    challenge: `There were no great challenges encountered`,
    benefits: `The tool helps to create awareness and for cities and municipalities to reach the 2050 climate objectives that have been set by Europe.`,
  },
  {
    id: 11,
    title: "RAYWOOD",
    slug: "raywood",
    content:
      "We developed  a website to showcase the knowhow and references of Raywood, a wooden frame builder",
    key: "1",
    projectType: ["Websites"],
    Image: Raywood,
    type: "StartUp",
    video:
      "https://player.vimeo.com/external/661212612.hd.mp4?s=96cbb4ba330991b61146e2900a9b2a53b3188208&profile_id=175",
    videoPlaceholder: videoRaywood,
    logo: raywoodLogo,
    desc: `For Raywood, we have worked on the creation of a logo and of a showcase website. 
    The purpose was to highlight the activity of Raywood, facilitate contact with (potential) clients and improve the brand guideline of the company.`,
    company: `Raywood is an SME that carries out your projects in framework wood, the installation of insulation for your house, or the extension of your dwelling, carport, pool house, garden shed, and terrace.`,
    result: `In a one page website, the user is able to have a look at the activity of Raywood and to contact easily the company`,
    challenge: `Creation of a logo and highlighting of Raywood's activity and creations`,
    benefits: `We improved the online visibility of Raywood`,
  },
  {
    id: 12,
    title: "RESIDENTIE REINAART",
    slug: "residentie-reinaart",
    content: "A high-end real estate project near the city of Ghent",
    key: "2",
    projectType: ["Websites"],
    Image: ResidentReinaart,
    type: "SME",
    video:
      "https://player.vimeo.com/external/661212683.hd.mp4?s=3d83e2eb92d2cd0bc4ab435481c586deb69be6bf&profile_id=169",
    videoPlaceholder: videoResidentieReinart,
    logo: residentie_reinaartLogo,
    desc: `Residentie Reinaart is a real estate project developed in the city of Destelbergen near Ghent. The website allows potential buyers to visualize the apartments that are for sale. The website provides information about both the project as well as the various apartments that are for sale, such as floorplan information, pricing... But also provides information about the surrounding neighborhood.`,
    company: `Gaelens Bouwprojecten is a real estate developer located in Ghent and has been building high-end apartments for several years in different regions of Belgium`,
    result: `The Residentie Reinaart website helped the real estate buyers to visualize  and understand the project better as construction works hadn't begun yet. `,
    challenge: `There were no great challenges encountered`,
    benefits: `The real estate promotor created much more visibility and as a result, was able to sell more than 75% of the plan before construction works had begun. `,
  },
  {
    id: 13,
    title: "DOCUBUILD",
    slug: "docubuild",
    content:
      "A state of the art document management system between architects, inspection firms, real estate promoters, and agencies...",
    key: "1",
    projectType: ["digitalSolutions"],
    Image: Docubuild,
    video:
      "https://player.vimeo.com/external/661211855.hd.mp4?s=1beda3e37b43155b6d8a7cfbbae7d1f40fc7fb37&profile_id=175",
    videoPlaceholder: videoBIP,
    type: "SME",
    logo: docubuilLogo,
    desc: `DocuBuild is a saas platform that matches supply and demand of service requests from; architects, promoters, contractors, real estate agents, private individuals, or any other company that needs (inspection, stability, safety coordination...) services within the construction sector to request a service or an offer for a service. The platform centralizes and coordinates the service requests that are being made by customers and prospects. Every step and document is centralized in the platform. `,
    company: `Mathebo, Keuringsfirma, Stabuild combine their expertise to support a great variety of building services.`,
    result: `Supply (service companies) and  demand (service requests) are easily being followed-up  upon. `,
    challenge: `Documenting the process before the design phase was key to designing and building a successful platform `,
    benefits: `Matching service needs with service demands has never been easier (tinder)`,
  },
  // {
  //   id: 14,
  //   title: "WARMTEWERKT",
  //   content:
  //     "A web app that provides municipalities with a starting point to create their heat zoning plans",
  //   key: "4",
  //   projectType: ["Web Apps"],
  //   type: "Government",
  //   Image: WTW,
  //   video:
  //     "https://player.vimeo.com/external/661212867.hd.mp4?s=c7a5a0e18bd3bd550ae9aeee141e2e7824d027fe&profile_id=169",
  //   videoPlaceholder: videoWarmtewerk,
  //   logo: warmtewerktLogo,
  //   desc: `We are developing a stand-alone web tool for all cities and municipalities of Flanders which will
  //   give them a first glimpse of the potential of collective heating in their jurisdiction, based on the heat demand.`,
  //   company: `VVSG is the Flemish association for cities and municipalities.`,
  //   result: `We are currently finalising the web tool`,
  //   challenge: `There were no great challenges encountered`,
  //   benefits: `This open-source web tool will allow smaller cities and municipalities to get a first indication of
  //   whether they could look into a collective heat solution for certain areas of their region`,
  // },
  {
    id: 15,
    title: "WIJ KOPEN VASTGOED",
    slug: "wij-kopen-vastgoed",
    content: "A real estate broker looking to buy and sell real estate",
    key: "1",
    projectType: ["Websites"],
    type: "StartUp",
    Image: WijKopenVastgoed,
    video:
      "https://player.vimeo.com/external/661212966.hd.mp4?s=98f6456773e5854a80001e38636cd56a6539d662&profile_id=175",
    videoPlaceholder: videoWJIKOPEN,
    logo: wij_kopen_vastgoedLogo,
    desc: `We developed a website for a real estate agent in Antwerp that is looking to purchase real estate of homeowners that are looking to rapidly onsell their homes! Wijkopenvastgoed offers homeowners a direct offer upon their first visit to the real estate. `,
    company: `'Wij kopen vastgoed' is a real estate broker located in the city of Antwerp `,
    result: `Their new service offering rapidly became a success with many leads & transactions as a result`,
    challenge: `There were no great challenges encountered`,
    benefits: `Happy homeowners were able to sell their homes rapidly`,
  },
  {
    id: 16,
    title: "KBC",
    slug: "kbc",
    content:
      "Build an MVP that helps KBC stimulate energy efficient renovations",
    key: "3",
    projectType: ["Web Apps"],
    type: "Corporate",
    Image: KBC,
    video:
      "https://player.vimeo.com/external/661212079.hd.mp4?s=1829ea3a05f085857666bef2ef491d78e3607b4d&profile_id=169",
    videoPlaceholder: videoKBC,
    logo: kbcLogo,
    desc: `Beterrenoveren is a tool KBC wants to use to accelerate the conversion of homeowners with an interest in renovating into those who decide to renovate.
    The tool provides information on the renovation works (budget, subsidy, CO2
    saving) that need to be carried out to improve their comfort, reduce their energy
    consumption and ecological footprint. This helps people thus actively participate in
    the achievement of environmental goals`,
    company: `KBC Group N.V. is a Belgian universal multi-channel bank-insurer, focusing on private clients and small and medium-sized enterprises in Belgium, Ireland, Central Europe, and South-East Asia`,
    result: `A tool that uses digital twin technology to accelerate the customer journey of homeowners that are interested in renovating. `,
    challenge: `Develop a functional minimum viable product that allows  KBC to test the market for engaging and delighting house owners in their renovation journey. `,
    benefits: `Increase the conversions of homeowners that are interested/ engaged to renovators. `,
  },
  // {
  //   id: 17,
  //   title: "TOP INTERIEUR",
  //   content: "The brandnew Top Interieur website ",
  //   key: "2",
  //   type: "SME",
  //   projectType: ["Websites"],
  //   Image: TopInterieur,
  //   video: "",
  //   videoPlaceholder: "",
  //   logo: top_interieurLogo,
  //   desc: `We developed a brand new website for TopInterieur, adding many features compared to the existing website, such as a click & collect option, detailed product pages, admin cms with the possibility to change a lot of content themselves onto the website.`,
  //   company: `'Top interieur' has 3 big high-end furniture stores located in Belgium where they expose a wide range of furniture for your interior in a variety of styles; rural to design. You will find a big collection of furniture for your living- and sleeping room but also for your garden and patio area. The store in Massenhoven also showcases a wide range of kitchens. `,
  //   result: `The website is still under construction at the moment `,
  //   challenge: `There were no great challenges encountered`,
  //   benefits: `The new website allows users to find much more detail about the products being sold in the Top Interieur stores`,
  // },
  {
    id: 18,
    title: "LAURINS GARDEN",
    slug: "laurins-garden",
    content: "A high-end real estate project near the city of Bruges",
    key: "2",
    type: "SME",
    projectType: ["Websites"],
    Image: LaurinsGarden,
    video:
      "https://vod-progressive.akamaized.net/exp=1643276476~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F3890%2F26%2F669453699%2F3081991660.mp4~hmac=61a583ade721b05654c0ba76be9d5ed0a8a6206ba3362ec13756fcc4484864aa/vimeo-prod-skyfire-std-us/01/3890/26/669453699/3081991660.mp4?filename=1080p.mp4",
    videoPlaceholder: "",
    logo: gaelensLogo,
    desc: `Laurins Garden is a real estate project developed in the city of Bruges. The website allows potential buyers to visualize the apartments that are for sale. The website provides information about both the project as well as the various apartments that are for sale, such as floorplan information, pricing... But also provides information about the surrounding neighborhood.`,
    company: `Gaelens Bouwprojecten is a real estate developer located in Ghent and has been building high-end apartments for several years in different regions of Belgium`,
    result: `The Laurins Garden website helped the real estate buyers to visualize and understand the project better as construction works hadn't begun yet. `,
    challenge: `There were no great challenges encountered`,
    benefits: `The real estate promotor created much more visibility and as a result, was able to sell more than 75% of the plan before construction works had begun. `,
  },
  {
    id: 19,
    title: "NTT",
    slug: "nit",
    content: "The European virtual innovation center ",
    key: "3",
    type: "Corporate",
    projectType: ["Web Apps"],
    Image: NTT,
    video:
      "https://player.vimeo.com/external/661212302.hd.mp4?s=c2e0f53d9ab3fdcfe3469547ef21d81aefc7d80d&profile_id=175",
    videoPlaceholder: videoNTT,
    logo: ntt_logo,
    desc: `For our client NTT, we have developed a virtual copy of their European innovation center. The innovation center at the European level aims to show their know-how at R&D achievements to their customers. The Client Innovation Center is consulted by the largest companies around the world. 

    Today, thanks to this achievement, NTT customers no longer have to physically come to the site, but can explore the innovation center online. This also considerably reduces the carbon footprint.`,
    company: `NTT helps to accelerate your business transformation through our industry-leading digital backbone, a secure multi-cloud platform, and a broad portfolio of global services. We deliver global platforms and services to 11,000 clients across 200 countries. `,
    result: `People can have a taste of the virtual CIC without having to go there physically `,
    challenge: `There were no great challenges encountered`,
    benefits: `The CMS manager can automatically generate web links with an expiry date and the content that he wants to show on the web app. `,
  },
  {
    id: 20,
    title: "ENGIE/VINCOTTE",
    slug: "engie-vincotte",
    content:
      "The virtual tour that made the tender process a whole lot easier ",
    key: "3",
    type: "Corporate",
    projectType: ["Web Apps"],
    Image: ENGIE,
    video: "",
    videoPlaceholder: "",
    logo: engie_logo,
    desc: `In collaboration with our partner Vinçotte, we have developed virtual tours for 3 ENGIE sites.
    Today, the virtual tour is used by all contractors who respond to the various calls for tenders from ENGIE to do works on site. Thanks to this online platform, contractors no longer have to come to the site and can use 360 ​​° views to analyze the needs, to submit a concrete offer to ENGIE.
    
    We made the first virtual tours in July 2020 and the main advantage is that many trips from different suppliers to visit the sites have been replaced by virtual visits, which has a positive impact on the carbon footprint of Engie and the suppliers. Also in terms of transparency, every supplier now has access to the same information. `,
    company: `Vinçotte offers objective and advisory services in inspection, certification, conformity assessment, and training.`,
    result: `All offers were received in due time thanks to the level of detail available in the virtual tours`,
    challenge: `Organize site visits for tendering process without the need for physical visits`,
    benefits: `Simplified/digitalized visits of power plants for suppliers through a web application that decrease cost for both parties and makes sure everybody gets the same information. `,
  },
  {
    id: 21,
    title: "CHECK JE HUIS",
    slug: "check-je-huis",
    content:
      "A web app that helps the city of Ghent to create awareness about energy-efficient renovations",
    key: "4",
    type: "Government",
    projectType: ["Web Apps"],
    Image: CheckJeHuis,
    video:
      "https://player.vimeo.com/external/661211960.hd.mp4?s=582d29e35f588677f8550c4d2a597d5972058144&profile_id=175",
    videoPlaceholder: videoCJH,
    logo: checkjehuis_logo,
    desc: `The city of Ghent, through Disctrict09 issued a tender for the Checkjehuis (CJH) project in July 2020. 
    We won, the bid in early September and started development in October 2020.
    https://checkjehuis.stad.gent/ is a user-friendly “awareness” tool that helps users to assess their homes energetically. 
    It provides information about the renovation work that must be carried out to improve their comfort, reduce their consumption and thus actively participate in achieving environmental objectives.`,
    company: `District09 is the city of Ghent's ICT partner. They build and maintain a stable, trustworthy, and secure ICT environment, on which the town, its citizens and its visitors, its commuters, its students, and its employees can count. `,
    result: `We developed a V2 for CJH and included much more questions, calculations, and connections to various API's to get more accurate responses`,
    challenge: `There were no great challenges encountered`,
    benefits: `The open-source tool helps to create awareness and for cities and municipalities to reach the 2050 climate objectives that have been set by Europe. `,
  },
  // {
  //   id: 22,
  //   title: "RADISON GROUP",
  //   content:
  //     "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  //   key: "3",
  //   type: "Corporate",
  //   projectType: ["Web Apps", "Mobile Apps"],
  //   Image: RadissonMobile,
  //   video: "",
  //   videoPlaceholder: "",
  //   logo: radisson_logo,
  //   desc: `For our client NTT, we have developed a virtual copy of their European innovation center. The innovation
  //   center at the European level aims to show their know-how at R&D achievements to their customers. The Client
  //   Innovation Center is consulted by the largest companies around the world. Today, thanks to this achievement,
  //   NTT customers no longer have to physically come to the site, but can explore the innovation center online.
  //   This also considerably reduces the carbon footprint.`,
  //   company: `NTT helps to accelerate your business transformation through our industry-leading digital backbone,
  //   a secure multi-cloud platform, and a broad portfolio of global services. We deliver global platforms and
  //   services to 11,000 clients across 200 countries. `,
  //   result: `People can have a taste of the virtual CIC without having to go there physically `,
  //   challenge: `There were no great challenges encountered`,
  //   benefits: `The CMS manager can automatically generate web links with an expiry date and the content that he wants
  //   to show on the web app.`,
  // },
  {
    id: 23,
    title: "CHALLENGE AIRLINES",
    slug: "challenge-airlines",
    content: "From Leica to point cloud to detailed plan!",
    key: "2",
    type: "SME",
    projectType: ["digitalSolutions"],
    Image: ChallengeAirline,
    video:
      "https://player.vimeo.com/external/661210722.hd.mp4?s=9554e71d8247a44345900f1e917da2904847e07c&profile_id=175",
    videoPlaceholder: videoChallengeAirlines,
    logo: challengeairlines_logo,
    desc: `From Leica to point cloud to detailed plan! 
    Challenge Airlines needed to optimize and plan the loading capacity of their #Boeing747. How? 
    1. Leica scan on 24 anchor points (upper & lower deck) in high-resolution at 360.000 points/second
    2. Import & processing of the point cloud
    3. Conversion of the “point cloud” into highly accurate CAD plans .DWG format
    4. Simplified pdf plan with an indication of measures of the loading areas Challenge Airlines (BE) S.A.
     wanted to optimize and plan the loading capacity of their #Boeing747. How? `,
    company: `Challenge airlines specialize in ACMI services Our capacity is managed via Challenge Air Cargo, our commercial entity, which has offices in France, the Netherlands and Belgium, and Germany.`,
    result: `The cargo and operations team can plan & schedule the cargo loads with great accuracy `,
    challenge: `There were no great challenges encountered`,
    benefits: `Challenge airlines can fully optimize their loading capacity with the available data at hand. `,
  },
  {
    id: 24,
    title: "BEFUTURA",
    slug: "BEFUTURA",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    key: "2",
    type: "SME",
    projectType: ["digitalSolutions"],
    Image: ChallengeAirline,
    video:
      "https://player.vimeo.com/external/661210722.hd.mp4?s=9554e71d8247a44345900f1e917da2904847e07c&profile_id=175",
    videoPlaceholder: videoChallengeAirlines,
    logo: befutura,
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    company:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    result:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.  ",
    challenge:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    benefits:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
];

export const projectRelations = [
  {
    id: 5,
    name: "SASS",
    selected: false,
    category: [],
    project: {},
  },
  {
    id: 6,
    name: "Web Apps",
    selected: false,
    category: ["Government", "Corporate", "SME", "ScaleUp"],
    project: {
      Government: [
        {
          id: 21,
          title: "CHECK JE HUIS",
          content:
            "A web app that helps the city of Ghent to create awareness about energy-efficient renovations",
          key: "4",
          type: "Government",
          projectType: "Web Apps",
          Image: CheckJeHuis,
          video: "example.mp4",
          logo: top_interieurLogo,
        },
        {
          id: 4,
          title: "HUB",
          content: "UX/UI of a diagnostic platform for Brussels' businesses",
          key: "4",
          projectType: "Web Apps",
          type: "Government",
          video: "example.mp4",
          Image: HUB,
        },
        {
          id: 5,
          title: "KULTXL",
          content: "The cultural platform for the city of Ixelles",
          key: "4",
          projectType: "Web Apps",
          type: "Government",
          Image: KultXL,
        },
        {
          id: 10,
          title: "QUICKSCAN",
          content:
            "A web app that helps SPW to create awareness about energy-efficient renovations",
          key: "4",
          projectType: "Web Apps",
          type: "Government",
          Image: Quickscan,
        },
        {
          id: 14,
          title: "WARMTEWERKT",
          content:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          key: "4",
          projectType: "Web Apps",
          type: "Government",
          video: "example.mp4",
          Image: WTW,
        },
      ],
      Corporate: [
        {
          id: 19,
          title: "NTT",
          content: "The European virtual innovation center ",
          key: "3",
          type: "Corporate",
          projectType: "Web Apps",
          Image: NTT,
          video: "example.mp4",
          logo: top_interieurLogo,
        },
        {
          id: 20,
          title: "ENGIE/VINCOTTE",
          content:
            "The virtual tour that made the tender process a whole lot easier",
          key: "3",
          type: "Corporate",
          projectType: "Web Apps",
          Image: ENGIE,
          video: "example.mp4",
          logo: top_interieurLogo,
        },
        {
          id: 22,
          title: "RADISON GROUP",
          content:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          key: "3",
          type: "Corporate",
          projectType: "Web Apps",
          Image: RadissonMobile,
          video: "example.mp4",
          logo: top_interieurLogo,
        },
        {
          id: 1,
          title: "AGC",
          content:
            "Engage and delight homeowners by unburdening them of the practicalities of renovations",
          key: "3",
          projectType: "Web Apps",
          type: "Corporate",
          Image: RenoCity,
          video: "example.mp4",
        },
        {
          id: 16,
          title: "KBC",
          content:
            "Build an MVP that helps KBC stimulate energy efficient renovations",
          key: "3",
          projectType: "Web Apps",
          type: "Corporate",
          Image: KBC,
          video: "example.mp4",
        },
      ],
      SME: [],
      ScaleUp: [
        {
          id: 9,
          title: "OPTIFLUX",
          content:
            "Optiflux is an interface that monitors the data about the storage conditions of the fruit that is being stored in cooling cells.",
          key: "1",
          projectType: "Web Apps",
          type: "ScaleUp",
          video: "example.mp4",
          Image: Optiflux,
        },
      ],
    },
  },
  {
    id: 7,
    name: "Mobile Apps",
    selected: false,
    category: ["Corporate"],
    project: {
      Corporate: [
        {
          id: 3,
          title: "ADERCO",
          content:
            "Maritime mobile app to solve engine problems and access to fuels data",
          key: "3",
          projectType: "Mobile Apps",
          type: "Corporate",
          video: "example.mp4",
          Image: Aderco,
        },
      ],
    },
  },
  {
    id: 8,
    name: "Websites",
    category: ["Corporate", "SME", "ScaleUp"],
    selected: false,
    project: {
      Corporate: [],
      SME: [
        {
          id: 18,
          title: "LAURINS GARDEN",
          content: "A high-end real estate project near the city of Bruges",
          key: "2",
          type: "SME",
          projectType: "Websites",
          Image: LaurinsGarden,
          video: "https://vimeo.com/manage/videos/668634008",
          logo: top_interieurLogo,
        },
        {
          id: 6,
          title: "LOMBARDENVELD",
          content: "A high-end real estate project in the center of Merchtem",
          key: "2",
          projectType: "Websites",
          type: "SME",
          Image: Lombaerdenveld,
        },
        {
          id: 7,
          title: "MIERECHEMPLEIN",
          content:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
          key: "2",
          projectType: "Websites",
          type: "SME",
          video: "example.mp4",
          Image: Mierechemplein,
        },
        {
          id: 12,
          title: "RESIDENTIE REINAART",
          content: "A high-end real estate project near the city of Ghent",
          key: "2",
          projectType: "Websites",
          type: "SME",
          video: "example.mp4",
          Image: ResidentReinaart,
        },
        {
          id: 17,
          title: "TOP INTERIEUR",
          content: "The brandnew Top Interieur website ",
          key: "2",
          projectType: "Websites",
          type: "SME",
          video: "example.mp4",
          Image: TopInterieur,
        },
      ],
      ScaleUp: [
        {
          id: 8,
          title: "NWO",
          content:
            "A passionate wine trader offering a wide range of high-end wines from all over the world",
          key: "1",
          projectType: "Websites",
          type: "StartUp",
          video: "example.mp4",
          Image: NWO,
        },
        {
          id: 11,
          title: "RAYWOOD",
          content:
            "We developed  a website to showcase the knowhow and references of Raywood, a wooden frame builder",
          key: "1",
          projectType: "Websites",
          type: "StartUp",
          Image: Raywood,
          video: "example.mp4",
        },
        {
          id: 15,
          title: "WIJ KOPEN VASTGOED",
          content: "A real estate broker looking to buy and sell real estate",
          key: "1",
          projectType: "Websites",
          type: "StartUp",
          Image: WijKopenVastgoed,
          video: "example.mp4",
        },
      ],
    },
  },
  {
    id: 9,
    name: "digitalSolutions",
    selected: false,
    category: ["SME"],
    project: {
      SME: [
        {
          id: 23,
          title: "CHALLENGE AIRLINES",
          content: "From Leica to point cloud to detailed plan!",
          key: "2",
          type: "SME",
          projectType: "digitalSolutions",
          Image: ChallengeAirline,
          video: "example.mp4",
          logo: top_interieurLogo,
        },
      ],
    },
  },
  {
    id: 10,
    name: "SME",
    selected: false,
    category: ["ScaleUp"],
    project: {
      ScaleUp: [
        {
          id: 13,
          title: "DOCUBUILD",
          content:
            "A state of the art document management system between architects, inspection firms, real estate promoters, and agencies...",
          key: "1",
          projectType: "digitalSolutions",
          type: "SME",
          Image: Docubuild,
        },
      ],
    },
  },
  {
    id: 11,
    name: "Apps",
    selected: false,
    category: [],
    project: {},
  },
];
