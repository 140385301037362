import React from "react";
import { Markup } from "interweave";

// Assets Base
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
const TopSection = (props) => {
  // Props
  const { data } = props;
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-4">
      <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1 items-center">
        {data && data.map((single, index) => (
          <>
            <div className="py-10 xl:py-20 lg:py-20 col-span-7 xl:col-span-7 lg:col-span-7 md:col-span-6 xs:col-span-6" >
              <h1 className="text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl font-brandonBlack leading-homeSplashHeadin text-center lg:text-left xl:text-left text-primary">
                {single.title}
                <p className="text-tiny xl:text-pargraph lg:text-pargraph text-center xl:text-left lg:text-left md:text-center font-brandonLight font-bold">
                  <Markup content={single.description} />
                </p>
              </h1>
            </div>
            <div className="col-span-5 xl:col-span-5 lg:col-span-5 md:col-span-6 xs:col-span-6 order-last xs:order-first md:order-first lg:order-last xl:order-last mx-0 xl:mx-0 lg:mx-0 xs:mx-auto">
              <img
                src={AssetsUrl + single.image}
                className="mx-auto mb-0 xl:-mb-40 lg:-mb-40"
              />
            </div>
          </>
        ))
        }

      </div >
    </div >
  );
};

export default TopSection;
