import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Linkedin_icon from "../../images/Linkedin Icon.svg";
import Triangle_copy from "../../images/triangle copy 3.svg";
import logo_footer from "../../images/Logo-f.png";

// Services
import { getAll } from "../../service/menu";

const Footer = () => {
  // states
  const [menus, setMenus] = useState([]);

  // UseEffect
  useEffect(() => {
    getAll()
      .then((response) => {
        setMenus(
          response?.data?.data?.filter((row) => row.type == "footer-menu")
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <div>
      <div class="grid xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 bg-primary py-8">
        <div class="2xl:col-start-2 2xl:col-span-2 xl:col-start-2 xl:col-span-2 lg:col-start-2 lg:col-span-2 md:col-start-2 md:col-span-4 xs:col-start-2 xs:col-span-4 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 xs:ml-4">
          <img
            className="2xl:h-auto max-w-full xl:h-auto max-w-full lg:h-auto max-w-full md:h-auto max-w-full xs:h-auto"
            src={logo_footer}
            alt=""
          ></img>
        </div>
        <div class="mt-4 self-start 2xl:col-start-5 2xl:col-span-1 xl:col-start-5 xl:col-span-1 lg:col-start-4 lg:col-span-2 md:col-start-7 md:col-span-2 xs:col-start-7 xs:col-span-3 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 xs:ml-4">
          <div className="float-left">
            {menus.length > 0 &&
              menus.map(
                (single, index) =>
                  single.position == "center" &&
                  index <= 3 && (
                    <div>
                      {single.name == "BLOG" ? (
                        <Link
                          to="/blog"
                          className={`${
                            index > 0 ? "mt-3" : ""
                          } float-left font-brandonLight text-white 2xl:text-sm xl:text-sm lg:text-sm md:text-sm xs:text-xsm`}
                        >
                          {single.name}
                        </Link>
                      ) : (
                        <Link
                          to={single.link}
                          className={`${
                            index > 0 ? "mt-3" : ""
                          } float-left font-brandonLight text-white 2xl:text-sm xl:text-sm lg:text-sm md:text-sm xs:text-xsm`}
                        >
                          {single.name}
                        </Link>
                      )}
                    </div>
                  )
              )}
          </div>
        </div>
        <div class="mt-4 self-start 2xl:col-start-6 2xl:col-span-1 xl:col-start-6 xl:col-span-1 lg:col-start-6 lg:col-span-2 md:col-start-10 md:col-span-2 xs:col-start-10 xs:col-span-2  2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 xs:ml-4">
          <div className="float-left">
            {menus.length > 0 &&
              menus.map(
                (single, index) =>
                  single.position == "center" &&
                  index > 3 && (
                    <div>
                      <Link
                        to={single.link}
                        className={`${
                          index > 4 ? "mt-3" : ""
                        } float-left font-brandonLight text-left text-white 2xl:text-sm xl:text-sm lg:text-sm md:text-sm xs:text-xsm`}
                      >
                        {single.name}
                      </Link>
                    </div>
                  )
              )}
          </div>
        </div>
        {menus.length > 0 &&
          menus.map(
            (single, index) =>
              single.position == "right" && (
                <>
                  {index === 6 ? (
                    <div className="flex flex-row self-center 2xl:col-start-8 2xl:col-span-2 xl:col-start-8 xl:col-span-2 lg:col-start-8 lg:col-span-2 md:col-start-2 md:col-span-3 xs:col-start-2 xs:col-span-3 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 xs:ml-4">
                      <img
                        src={Triangle_copy}
                        alt=""
                        className="mt-4 h-5 w-5"
                      />
                      <Link
                        to={single.link}
                        className="font-brandonBold text-white mt-4 ml-4  2xl:text-sm xl:text-sm lg:text-sm md:text-sm xs:text-xsm float-left"
                      >
                        {single.name}
                      </Link>
                    </div>
                  ) : (
                    <div className="flex flex-row self-center 2xl:col-start-10 2xl:col-span-1 xl:col-start-10 xl:col-span-1 lg:col-start-10 lg:col-span-1 md:col-start-6 md:col-span-3 xs:col-start-7 xs:col-span-3 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 xs:ml-4">
                      <img
                        src={Triangle_copy}
                        alt=""
                        className="mt-4 h-5 w-5"
                      />
                      <Link
                        to={single.link}
                        className="font-brandonBold text-white 2xl:mt-4 xl:mt-4 lg:mt-4 md:mt-4 xs:mt-4 2xl:ml-4 xl:ml-4 lg:ml-2 md:ml-2 xs:ml-2 2xl:text-sm xl:text-sm lg:text-sm md:text-sm xs:text-xsm"
                      >
                        {single.name}
                      </Link>
                    </div>
                  )}
                </>
              )
          )}
        <div class=" flex flex-row self-center 2xl:col-start-11 2xl:col-span-1 xl:col-start-11 xl:col-span-1 lg:col-start-11 lg:col-span-1 md:col-start-9 md:col-span-2 xs:col-start-10 xs:col-span-1">
          <a href="https://www.linkedin.com/company/agifly" target="_blank">
            <img src={Linkedin_icon} alt="" className="mt-4 h-5 w-5 ml-20" />
          </a>
        </div>
      </div>
      <div class="grid xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 bg-primary py-4">
        <div className="2xl:col-start-2 2xl:col-span-10  xl:col-start-2 xl:col-span-10  lg:col-start-2 lg:col-span-10  md:col-start-2 md:col-span-10  h-px bg-gray-100"></div>
        <div className="2xl:col-start-2 2xl:col-span-10  xl:col-start-2 xl:col-span-10  lg:col-start-2 lg:col-span-10  md:col-start-2 md:col-span-10 2xl:ml-0 xl:ml-0 lg:ml-0 md:ml-0 xs:m-4 my-4">
          {menus.length > 0 &&
            menus.map(
              (single, index) =>
                (single.position == "bottom-left" ||
                  single.position == "bottom-right") && (
                  <>
                    {single.position == "bottom-left" ? (
                      <div className="float-left">
                        <h1 className="font-brandonLight text-white">
                          &copy; 2021 Agifly .{" "}
                          <Link to={single.link}>{single.name}</Link>
                        </h1>
                      </div>
                    ) : (
                      <div className="float-right ">
                        <Link
                          to={single.link}
                          className="font-brandonLight text-white"
                        >
                          {single.name}
                        </Link>
                      </div>
                    )}
                  </>
                )
            )}
        </div>
      </div>
    </div>
  );
};
export default Footer;
