import ProductOwnerHeader from "../../components/productOwnerHeader/productOwnerheader";
import ProductOwner from "../../components/productOwner/productOwner";
const ProductOwnerPage = () => {
  return (
    <div>
      <ProductOwnerHeader />
      <ProductOwner />
    </div>
  );
};
export default ProductOwnerPage;
