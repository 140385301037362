import { useMedium } from "react-use-medium";
import "./style.scss";

export default function BlogList() {
  const { data, isFetched } = useMedium("@Agifly");
  // getting substring from decreption and removing html tags
  const handleDesc = (value) => {
    return value?.substring(1, 120).replace(/<\/?[^>]+(>|$)/g, "");
  };
  return (
    <div className="blog-list text-left">
      <section className="max-w-7xl mx-auto">
        <div class="m-4 lg:flex lg:flex-wrap">
          {data?.items?.map((single) => (
            <div class="p-4 md:w-1/3">
              <div class="shadow-md border-gray-200 rounded-lg main-blog h-full">
                <img
                  class="object-cover object-center w-full lg:h-48 md:h-36 p-2"
                  src={single?.thumbnail}
                  alt="blog"
                />
                <div class="p-6">
                  <h1 class="mb-2 text-lg font-medium text-gray-900">
                    {single?.title}
                  </h1>
                  <p class="mb-2 text-sm tracking-wide text-gray-700">
                    {handleDesc(single?.description)}
                  </p>
                  <div class="flex items-center ">
                    <a
                      class="inline-flex items-center text-indigo-500 cursor-pointer md:mb-2 lg:mb-0 text-orange"
                      href={single?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5 mt-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
