import { Slide } from "react-slideshow-image";
import { getAll } from "../../service/slider";
import "./partners.scss";
import { useEffect, useState } from "react";


const windowWidth = window.innerWidth;
const properties = {
  duration: 2000,
  transitionDuration: 1000,
  slidesToShow: windowWidth < 768 ? 3 : 6,
  defaultIndex: 0,
  infinite: true,
  autoplay: true,
  arrows: false,
};

const Partners = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getAll()
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div className="text-center mx-auto my-0 lg:w-4/5 xs:w-11/12 lg:mt-6 lg:mb-3 xs:mt-2 xs:mb-2 partners-container">
      {data?.length > 0 && (
        <Slide {...properties} className="items-center">
          {data?.map((item) => {
            return (
              <div
                className="text-center lg:px-10 xs:p-3 w-52 lg:h-44 xs:h-24 items-center"
                key={item.id}
              >
                <a
                  href={item?.url}
                  className="logo-anchor cursor-pointer"
                  target="_blank"
                >
                  <img
                    src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${item?.image}`}
                    alt={item.id}
                  />
                  <img
                    src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${item?.image}`}
                    alt={item.id}
                  />
                  <img
                    src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${item?.hover_image}`}
                    alt={item.id}
                  />
                </a>
              </div>
            );
          })}
        </Slide>
      )}
    </div>
  );
};
export default Partners;
