import React from "react";
import { Markup } from "interweave";
import "./privacyPolicy.scss";

const AboutCookies = (props) => {
  // Props
  const { data, tableData } = props;
  return (
    <div className="bg-lightBlue">
      <div className="max-w-7xl mx-auto px-4 sm:px-4 ">
        <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1 gap-3 pt-10 text-heading">
          {data && data.map((single, index) => (
            <>
              <h1 className={`col-span-12 text-left uppercase font-brandonBlack text-2xl  md:text-3xl xs:text-xl leading-normal pt-5 ${index === 2
                ? "xl:text-smallHeading lg:text-smallHeading"
                : "xl:text-photoGallery lg:text-photoGallery"
                }`}>
                {single.title}
              </h1>
              <p className="col-span-12 xl:col-span-7 lg:col-span-7 text-left font-brandonLight font-bold text-pargraph">
                <Markup content={single.description} />
              </p>
            </>
          ))}
          <div className="col-span-12 xl:col-start-3 lg:col-start-3 xl:col-span-8 lg:col-span-8 bg-primary text-white shadow-sm rounded mt-10 -mb-20">
            <table className="table-auto">
              <thead className="border-bottom-orange font-brandonBlack text-2xl xl:text-smallHeading lg:text-smallHeading md:text-3xl xs:text-xl leading-normal">
                <tr>
                  <th className="p-4 w-1/3">NAME</th>
                  <th className="p-4 w-1/3">STORAGE DURATION</th>
                  <th className="p-4 w-1/3">USAGE</th>
                </tr>
              </thead>
              <tbody className="text-pargraph">
                {tableData.length > 0 && JSON.parse(tableData[0].table).map((single, index) => (
                  <tr className="border-bottom-lighBlue" key={index}>
                    <td className="p-3">{single.name}</td>
                    <td className="p-3">{single.storage_duration}</td>
                    <td className="p-3">
                      {single.usage}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCookies;
