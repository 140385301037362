import { useEffect, useState } from "react";
import ServicesSplash from "../../components/serviceSplashscreenSection/serviceSplashSection";
import ServicesScreen from "../../components/services/services";
import Partners from "../../components/partners/partners";
import Loader from "../../components/loader/loader";
// Services
import { getAll } from "../../service/services";
import { getAllSplash } from "../../service/splash";

const Services = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    dedicated: [],
    collaborative: [],
    stack: [],
    delivery: [],
  });
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "2"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          dedicated: response?.data?.data?.filter(
            (row) => row.section === "dedicated"
          ),
          collaborative: response?.data?.data?.filter(
            (row) => row.section === "collaborative"
          ),
          stack: response?.data?.data?.filter((row) => row.section === "stack"),
          delivery: response?.data?.data?.filter(
            (row) => row.section === "delivery"
          ),
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <ServicesSplash data={state.splashData} />
          <ServicesScreen data={state} />
          <Partners />
        </div>
      )}
    </>
  );
};
export default Services;
