import React from "react";
// import Schema from "../../../images/sustainability/shema.png";
import "./sustainability.scss";
import parse from "html-react-parser";

const Shema = (props) => {
  const { data } = props;
  return (
    <div class="grid auto-cols-auto">
      <div class="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
        <div class="mx-4 grid 2xl:col-start-4 2xl:col-span-6 xl:col-start-2 xl:col-span-10 lg:col-start-2 lg:col-span-10 md:col-start-2 md:col-span-10 xs:col-start-2 xs:col-span-10">
          <p className="2xl:mt-24 xl:mt-24 lg:mt-24 md:mt-24 xs:mt-8  text-left font-brandonLight font-bold text-heading text-sustainabilityParagraph">
            {parse(`<p>${data && data[0] && data[0]?.description}</p>`)}
          </p>
          <img
            className="2xl:mt-24 xl:mt-24 lg:mt-24 md:mt-24 xs:mt-8"
            src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
              data && data[1] && data[1].image
            }`}
            alt=""
          />
          {/* <p className="2xl:mt-4 xl:mt-4 lg:mt-4 md:mt-12 xs:mt-4 text-right text-heading font-brandonLight font-bold 2xl:text-sustainabilityParagraph xl:text-sustainabilityParagraph lg:text-sustainabilityParagraph md:text-sustainabilityParagraph xs:text-xsm float-right">
            {parse(`<p>${data && data[2] && data[2]?.description}</p>`)}
          </p> */}
          <p className="2xl:mb-4 xl:mb-4 lg:mb-4 md:mb-4 xs:mb-8 text-right text-heading float-right font-brandonLight font-bold 2xl:text-sustainabilityParagraph xl:text-sustainabilityParagraph lg:text-sustainabilityParagraph md:text-sustainabilityParagraph xs:text-xsm">
            {parse(`<p>${data && data[2] && data[2]?.description}</p>`)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Shema;
