import React from "react";
import axios from "axios";
import BitMapImage from "../../../images/hubspot/Bitmap.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "./style.scss";
import parse from "html-react-parser";

// Include Env File
require("dotenv").config();

const Contact = (props) => {
  const { data, options } = props;
  // Schema for yup
  const validationSchema = Yup.object().shape({
    issue: Yup.string().required("* Choose one option"),
    name: Yup.string().required("* Name is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    message: Yup.string().required("* Message is required"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
        issue: "",
        page: "hosting",
        subject: "Hosting request",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        axios
          .post(process.env.REACT_APP_BACKEND_URL + "/email/send", values)
          .then((response) => {
            if (response.status === 200) {
              // Notification
              toast.success("Your message has been sent successfully!", {
                position: "top-right",
              });
              // Reset Form
              resetForm();
            }
          })
          .catch((error) => { });
      }}
      // Hooks up our validationSchema to Formik
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        } = props;
        return (
          <div className="bg-lightBlue booking-form-hubspot" name="ContactForm">
            <div className="max-w-7xl mx-auto px-4 sm:px-4 text-heading">
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 xs:grid-cols-1 gap-3 xl:gap-20 lg:gap-20 py-20 xs:py-10">
                <div className="">
                  <h1 className="uppercase text-left font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-heading">
                    {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
                  </h1>
                  <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-10">
                    {parse(`<p>${data && data[1] && data[1]?.description}</p>`)}
                  </p>
                  <img
                    src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${data && data[2] && data[2].image
                      }`}
                    alt=""
                    className="mx-auto pt-20 hidden xl:block lg:block"
                  />
                </div>
                <div className="mx-0 xl:mx-20 lg:mx-20 pt-10 xl:pt-0 lg:pt-0">
                  <form
                    action=""
                    className="form_div font-brandonMedium"
                    onSubmit={handleSubmit}
                  >
                    {/* Radio Options */}
                    {options?.map((single) => (
                      <div className="grid grid-cols-12 text-left gap-5 my-auto py-2 text-xl">
                        <input
                          type="radio"
                          name="issue"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value="manged multi cloud infra"
                          className="col-span-1"
                        />
                        <p className="col-span-11 text-primary">
                          {parse(`<h1>${single?.title}</h1>`)}
                        </p>
                      </div>
                    ))}

                    <div className="grid grid-cols-12 text-left gap-5 my-auto py-2 text-xl">
                      <div className="text-orange col-span-12">
                        {errors.issue && errors.issue}
                      </div>
                    </div>
                    {/* your_name_div */}
                    <div className="text-left pt-5">
                      <lable className="m-0 text-primary py-2 text-left">
                        Your name
                      </lable>
                      <input
                        placeholder="Enter Your Name "
                        className="w-full py-1.5 my-2 px-3.5 focus:outline-none resize-none font-brandonLight text-bold"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <div className="text-orange">
                        {errors.name && errors.name}
                      </div>
                    </div>
                    {/* your_email_div */}
                    <div className="py-1 text-left">
                      <lable className="m-0 text-primary py-2 text-left">
                        Your email
                      </lable>
                      <input
                        placeholder="Enter a valid email "
                        className="w-full py-1.5 my-2 px-3.5 focus:outline-none resize-none font-brandonLight text-bold"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <div className="text-orange">
                        {errors.email && errors.email}
                      </div>
                    </div>
                    {/* your_message_div */}
                    <div className="py-1 text-left">
                      <label className="m-0 text-primary text-left">
                        Your message
                      </label>
                      <textarea
                        placeholder="Enter your message "
                        className="w-full py-1.5 my-2 px-3.5 focus:outline-none resize-none font-brandonLight text-bold"
                        rows={6}
                        name="message"
                        id="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                      ></textarea>
                      <div className="text-orange">
                        {errors.message && errors.message}
                      </div>
                    </div>
                    {/* button_div */}
                    <div className="flex justify-center py-4">
                      <button
                        type="submit"
                        className="bg-orange text-white text-base font-brandonMedium font-semibold px-12 py-2 submit-btn-booking"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>

                  <img
                    src={BitMapImage}
                    alt=""
                    className="mx-auto py-10 w-1/2 block xl:hidden lg:hidden pt-0 xs:pt-10"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Contact;
