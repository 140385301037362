import React from "react";
import "./style.scss";

const Loader = () => {
  return (
      <div className="loader-main flex items-center justify-center">
    <div class=" space-x-2  animate-pulse flex">
      <div class="w-8 h-8 bg-color-loader rounded-full"></div>
      <div class="w-8 h-8 bg-color-loader rounded-full"></div>
      <div class="w-8 h-8 bg-color-loader rounded-full"></div>
    </div>
    </div>
  );
};

export default Loader;
