import React, { useEffect, useState } from "react";
import TopSection from "../../components/ourSolution/sustainability/topSection";
import Buildings from "../../components/ourSolution/sustainability/buildings";
import Shema from "../../components/ourSolution/sustainability/shema";
import Areas from "../../components/ourSolution/sustainability/areas";
import Loader from "../../components/loader/loader";

// Services
import { getAllSplash } from "../../service/splash";
import { getAll } from "../../service/sustainability";

const Sustainability = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    sustainability: [],
    digital: [],
    areas: [],
  });
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "4"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          sustainability: response?.data?.data?.filter(
            (row) => row.section === "sustainability"
          ),
          digital: response?.data?.data?.filter(
            (row) => row.section === "digital"
          ),
          areas: response?.data?.data?.filter((row) => row.section === "areas"),
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TopSection data={state?.splashData} />
          <Shema data={state?.sustainability} />
          <Buildings data={state?.digital} />
          <Areas data={state?.areas} />
        </div>
      )}
    </>
  );
};
export default Sustainability;
