import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Logo from "../../images/Logo_header.png";

// Services
import { getAll } from "../../service/menu"

export default function Header() {
  // states
  const [menus, setMenus] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [subLink, setSubLink] = useState("");
  const [isToggle, setIsToggle] = useState(false);
  // Get Active Location
  const location = useLocation();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // UseEffect
  useEffect(() => {
    getAll().then((response) => {
      setMenus(response?.data?.data)
    })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <Popover className="relative bg-primary opacity-100 font-brandonLight sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-0 sm:px-4 xs:px-4">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="">
              <span className="sr-only">Workflow</span>
              <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-orange">
              <span className="sr-only">Open menu</span>
              <MenuIcon
                className="h-6 w-6"
                aria-hidden="true"
                onClick={() => setIsToggle(!isToggle)}
              />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-14">
            {menus.length > 0 && menus.map((item) => item.type == "header-menu" && (
              item.link === null ? (
                <Menu
                  as="div"
                  className="group relative inline-block text-left"
                  onMouseEnter={() => setIsOpen(!isOpen)}
                  onMouseLeave={() => setIsOpen(false)}
                >
                  <div>
                    <Menu.Button
                      className={`${location.pathname == subLink
                        ? "text-menu font-bold text-orange"
                        : "text-menu font-medium text-white hover:text-orange"
                        }`}
                    >
                      {item.name}
                    </Menu.Button>
                  </div>

                  <Transition
                    show={isOpen}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >

                    <Menu.Items className="origin-right absolute -left-4 mt-2 w-56 rounded-md shadow-lg bg-primary ring-1 ring-black ring-opacity-5 focus:outline-none group-hover:block">
                      <div className="py-1">
                        {menus.length > 0 && menus.map((item) => item.type == "header-sub-menu" && (
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={item.link}
                                className={classNames(
                                  active || location.pathname === item.link
                                    ? "font-bold text-orange"
                                    : "font-medium text-white",
                                  "block px-4 py-2 text-menu"
                                )}
                                onClick={() => setSubLink(item.link)}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <Link
                  to={item.link}
                  className={`${item.link === location.pathname
                    ? "text-menu font-bold text-orange"
                    : "text-menu font-medium text-white hover:text-orange"
                    }`}
                >
                  {item.name}
                </Link>
              )
            )
            )}
          </Popover.Group>
        </div>
      </div>

      <Transition
        show={isToggle}
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-primary">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <Link to="">
                  <img className="h-8 w-auto" src={Logo} alt="Workflow" />
                </Link>
                <div className="-mr-2">
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-orange">
                    <span className="sr-only">Close menu</span>
                    <XIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      onClick={() => setIsToggle(!isToggle)}
                    />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                {menus.length > 0 && menus.map((item) => item.type == "header-menu" && (
                  item.link === null ? (
                    <Menu as="div" className="relative inline-block text-left">
                      <div className="text-center">
                        <Menu.Button className={`${location.pathname == subLink
                          ? "text-menu font-bold text-orange"
                          : "text-menu font-medium text-white hover:text-orange"
                          }`}>
                          {item.name}
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-primary ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {menus.length > 0 && menus.map((item) => item.type == "header-sub-menu" && (
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={item.link}
                                    className={classNames(
                                      active || location.pathname === item.link
                                        ? "font-bold text-orange"
                                        : "font-medium text-white",
                                      "block px-4 py-2 text-menu"
                                    )}
                                    onClick={() => {
                                      setSubLink(item.link);
                                      setIsToggle(!isToggle);
                                    }}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <Link
                      to={item.link}
                      className={`${item.link == location.pathname
                        ? "text-menu font-bold text-orange"
                        : "text-menu font-medium text-white hover:text-orange"
                        }`}
                      onClick={() => setIsToggle(!isToggle)}
                    >
                      {item.name}
                    </Link>
                  )
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
