import React, { useState } from "react";
// import Lottie from "react-lottie";
import EmptyImage from "../../../images/smart-building/Empty.png";
import BuildImage from "../../../images/smart-building/buildhover.png";
import PlanningImage from "../../../images/smart-building/planninghover.png";
import OperateImage from "../../../images/smart-building/operatehover.png";
import DesignImage from "../../../images/smart-building/designhover.png";
import "./smartBuilding.scss";

const SecondSection = (props) => {
  const { data } = props;
  const [type, setType] = useState(false);

  // check mouse position
  const showCoords = (event) => {
    let x = event.clientX;
    let y = event.clientY;
  
    console.log("x", x, "y", y);

    if (x >= "480" && x <= "1018" && y >= "150" && y <= "382") {
      setType("planning");
    } else if (x >= "480" && x <= "1018" && y >= "400" && y <= "557") {
      setType("build");
    } else if (y >= "227" && y <= "577" && x >= "350" && x <= "744") {
      setType("operate");
    } else if (y >= "244" && y <= "520" && x >= "630" && x <= "1116") {
      setType("design");
    } else {
      setType(false);
    }
  };
  return (
    <div className="bg-white py-10 xl:py-20 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-4 divHeight">
        {type === false && (
          <img
            src={EmptyImage}
            className="mx-auto w-2/4"
            onMouseMove={(e) => showCoords(e)}
            alt=""
          />
        )}
        {/* Planning */}
        {type == "planning" && (
          <img
            src={PlanningImage}
            alt=""
            className="mx-auto w-2/4"
            onMouseMove={(e) => showCoords(e)}
          />
        )}
        {/* Operate */}
        {type == "operate" && (
          <img
            src={OperateImage}
            className="mx-auto w-2/4"
            onMouseMove={(e) => showCoords(e)}
          />
        )}
        {/* Design */}
        {type == "design" && (
          <img
            src={DesignImage}
            className="mx-auto w-2/4"
            onMouseMove={(e) => showCoords(e)}
          />
        )}
        {/* Build */}
        {type == "build" && (
          <img
            src={BuildImage}
            className="mx-auto w-2/4"
            onMouseMove={(e) => showCoords(e)}
          />
        )}
      </div>
    </div>
  );
};

export default SecondSection;
