import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

const Card = (props) => {
  let { allProjects, projects } = props;
  console.log("projects all", projects);
  let navigate = useNavigate();
  const windowWidth = window.innerWidth;

  // method for card sequence
  const sequenceHandler = (index) => {
    let sequence = [];
    for (let i = 1; i < allProjects?.length; i++) {
      sequence?.push({ id: i * 3 - 1 });
    }
    return sequence?.find((single) => single?.id === index)
      ? "mx-3 pt-16"
      : "mx-3 pt-8";
  };

  return (
    <div className="flex flex-wrap justify-center allProjects-container py-12">
      {/* card_main_div */}
      {projects?.map((single, index) => (
        <div
          className={`lg:w-1/4 w-full ${sequenceHandler(parseInt(index) + 1)}`}
        >
          <div
            className={`w-full rounded common-box font-brandonLight `}
            style={{
              background: (index + 1) % 2 === 0 ? "#194d8b" : "#2a70c4",
            }}
            key={index}
          >
            {/* card_content_div */}
            {single.title === "ADERCO MARINE" ||
            single.title === "RADISON GROUP" ? (
              <div
                className="text-center aderco_img_container py-2 px-5 flex cursor-pointer"
                onClick={() => {
                  if (windowWidth > 1025) {
                    navigate("/project-detail/" + single?.id);
                  }
                }}
              >
                {/* image_heading_div */}
                <div className="w-1/2">
                  {/* image_div */}
                  <div className="flex justify-center pt-2">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${single?.image}`}
                      alt=""
                      loading="lazy"
                    />
                  </div>
                </div>
                {/* heading_text_button_div */}
                <div className="w-1/2 pt-2 px-2  flex flex-col justify-between">
                  <div>
                    {/* heading_div */}
                    <div>
                      <h1 className="m-0 font-extrabold text-xl text-white  font-brandonBlack">
                        {single.title}
                      </h1>
                    </div>
                    {/* text_div */}
                    <div className="pt-4 pb-3">
                      {parse(
                        `<p className="text-white">${single?.description}</p>`
                      )}
                    </div>
                  </div>
                  {/* button_div */}
                  <div className="py-4">
                    <button
                      className="border border-solid border-white rounded-sm text-white py-2 px-4"
                      onClick={() => {
                        navigate("/project-detail/" + single?.id);
                      }}
                    >
                      TAKE A LOOK
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="text-center py-2 px-5 cursor-pointer"
                onClick={() => {
                  if (windowWidth > 1025) {
                    navigate("/project-detail/" + single?.id);
                  }
                }}
              >
                {/* image_heading_div */}
                <div>
                  {/* image_div */}
                  <div className="flex justify-center image-container">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${single?.image}`}
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  {/* heading_div */}
                  <div>
                    <h1 className="m-0 font-extrabold text-xl text-white  font-brandonBlack">
                      {single.title}
                    </h1>
                  </div>
                </div>
                {/* text_div */}
                <div className="pt-4 pb-3">
                  {parse(
                    `<p className="text-white">${single?.description}</p>`
                  )}
                </div>
                {/* button_div */}
                <div className="py-4">
                  <button
                    className="border border-solid border-white rounded-sm text-white py-2 px-4"
                    onClick={() => {
                      navigate("/project-detail/" + single?.id);
                    }}
                  >
                    TAKE A LOOK
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Card;
