import React from "react";
import HouseImage from "../../../images/sustainability/house.png";
import "./sustainability.scss";
import parse from "html-react-parser";

const Buildings = (props) => {
  const { data } = props;
  return (
    <div className="bg-lightBlue">
      <div className="max-w-7xl mx-auto px-4 sm:px-4 text-heading">
        <div className="grid xl:grid-cols-1 lg:grid-cols-1 xs:grid-cols-1 py-10 xl:pt-10 lg:pt-10">
          <h1 className="text-left font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-tight pb-5">
            {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
          </h1>
          <p className="text-left font-brandonLight font-bold text-sustainabilityParagraph">
            {parse(`<p>${data && data[1] && data[1]?.description}</p>`)}
          </p>
          {/* <p className="text-left font-brandonLight font-bold text-sustainabilityParagraph">
            {data &&
              data[1] &&
              data[1]?.description?.split(/;?\r\n|;?\n|;?\r/)[2]}
          </p> */}
        </div>
        <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1 text-left font-brandonLight font-bold text-sustainabilityParagraph pb-0 xl:pb-10 lg:pb-10">
          <div className="col-span-4">
            <div className="pb-10">
              <p className="pb-2 font-brandonBold">
                {parse(`<p>${data && data[3] && data[3]?.sub_title}</p>`)}
              </p>
              <p>
                {parse(`<p>${data && data[4] && data[4]?.description}</p>`)}
              </p>
            </div>
            <div className="pb-10">
              <p className="pb-2 font-brandonBold">
                {parse(`<p>${data && data[5] && data[5]?.sub_title}</p>`)}
              </p>
              <p>
                {parse(`<p>${data && data[6] && data[6]?.description}</p>`)}
              </p>
            </div>
            <div className="">
              <p className="pb-2 font-brandonBold">
                {parse(`<p>${data && data[7] && data[7]?.sub_title}</p>`)}
              </p>
              <p>
                {parse(`<p>${data && data[8] && data[8]?.description}</p>`)}
              </p>
            </div>
          </div>
          <div className="col-span-8">
            <img
              src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                data && data[2] && data[2].image
              }`}
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buildings;
