import React from "react";
import { Markup } from "interweave";
import "./privacyPolicy.scss";

const Contact = (props) => {
  // Props
  const { data } = props;
  return (
    <div className="bg-lightBlue">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1 gap-3 pt-40 pb-10 text-heading">
          {data && data.map((single, index) => (
            <>
              <h1 className="col-span-12 text-left uppercase font-brandonBlack text-2xl xl:text-photoGallery lg:text-photoGallery md:text-3xl xs:text-xl leading-normal">
                {single.title}
              </h1>
              <p className="col-span-12 xl:col-span-7 lg:col-span-7 text-left font-brandonLight font-bold text-pargraph">
                <Markup content={single.description} />
              </p>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contact;
