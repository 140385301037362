import "./homeServices.scss";
import { Markup } from "interweave";

// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
const HomeServices = (props) => {
  const { data } = props;
  return (
    <div>
      <h1 className="mt-16 font-brandonBlack text-heading text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl text-center">
        <Markup content={data[0]?.title_text} />
      </h1>
      <div className="mt-16 mb-16 2xl:h-96 xl:h-96 lg:h-96 md:h-4/5 xs:h-4/5 grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
        {data.map(
          (item, index) => index > 0 && (
            <div className={`${index === 1
              ? "-ml-16 3xl:-ml-16 2xl:ml-0 xl:-ml-4 lg:ml-0 md:ml-0 xs:ml-0 container poligon self-end grid 2xl:col-start-5 2xl:col-span-1 xl:col-start-4 xl:col-span-2 lg:col-start-2 lg:col-span-3 md:col-start-5"
              : index === 2
                ? "ml-0 2xl:ml-4 poligon grid self-start 2xl:col-start-6 2xl:col-span-1 xl:col-start-6 xl:col-span-2 lg:col-start-5 lg:col-span-3 md:col-start-5" : "3xl:ml-24 2xl:ml-8 xl:ml-4 lg:ml-0 md:ml-0 xs:ml-0 poligon grid self-end  3xl:col-start-7 2xl:col-span-2 2xl:col-start-7  2xl:col-span-2 xl:col-start-8 xl:col-span-2 lg:col-start-8 lg:col-span-5 md:col-start-5"
              } md:col-span-7 xs:col-start-7 xs:col-span-7`}>
              <a href="/how-we-do-it">
                <img className="mt-6 mx-auto h-24" src={AssetsUrl + item.image_url}></img>
              </a>
              <a href="/how-we-do-it">
                <h1 className="text-heading font-brandonBold pt-4">
                  {item.image_title}
                </h1>
              </a>
              <a href="/how-we-do-it">
                <p className="px-12 text-menu font-bold font-brandonLight text-center text-tiny text-heading">
                  {item.image_text}
                </p>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};
export default HomeServices;
