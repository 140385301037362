import React from "react";
import { Markup } from "interweave";
import "./style.scss";

// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
const team = (props) => {
  const { teamMemberBelgium, teamMemberPakistan } = props;
  console.log("teamMemberBelgium",teamMemberBelgium)
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 main-team-section" >
      <section className="py-16 px-4">
        <h1 className="text-left text-left text-xl fnt-brandon-black lg:text-4xl md:text-3xl sm:text-2xl   lg:text-left xl:text-left ">
          <Markup content={teamMemberBelgium[0]?.title} />
        </h1>
        {/* <h5 className="text-left mb-4  mt-4 member-name fnt-brandon-black">
          <Markup content={teamMemberBelgium[1]?.title} />
        </h5> */}

        <div className="flex flex-wrap -mx-4 -mb-8">
          {teamMemberBelgium.length > 0 && teamMemberBelgium?.map((value, index) => index > 0 && (
            <div
              className="md:w-1/3 lg:w-1/3 xs:w-full sm:w-1/2 px-4 mb-4 "
              key={value?.id}
            >
              <div className="flex justify-center">
                <img
                  class="rounded shadow-md rounded-2xl"
                  src={AssetsUrl + value?.image}
                  alt=""
                />
              </div>
              <div className="mt-3 leading-tight">
                <h1 className="member-name  lg:text-2xl fnt-brandon-black">
                  <Markup content={value.sub_title} />
                </h1>
                <p className="text-orange lg:text-1xl member-title">
                  <Markup content={value.description} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <section className=" px-4">
        <h5 className="text-left mb-4 member-name fnt-brandon-black">
          AGIFLY PAKISTAN
        </h5>
        <div className="flex flex-wrap -mx-4 -mb-8">
          {teamMemberPakistan.length > 0 && teamMemberPakistan.map((value, index) => index > 0 && (
            <div
              className="md:w-1/3 lg:w-1/4 xs:w-full sm:w-1/2 px-4 mb-8 "
              key={value?.id}
            >
              <div className="flex justify-center">
                <img
                  class="rounded shadow-md rounded-2xl"
                  src={AssetsUrl + value?.image}
                  alt=""
                />
              </div>
              <div className="mt-3 leading-tight">
                <h1 className="member-name  lg:text-2xl fnt-brandon-black">
                  <Markup content={value.sub_title} />
                </h1>
                <p className="uppercase text-orange lg:text-1xl member-title">
                  <Markup content={value.description} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </section> */}
    </div>
  );
};

export default team;
