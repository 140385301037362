import React from "react";
import Lottie from "react-lottie";
import { Markup } from "interweave";
import AboutAnimation from "../../lottiesAnimation/contact.json";
import "./contactTopSection.scss";

const ContactSplashSection = (props) => {
  const { data } = props;
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: AboutAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="bg-primary pb-10 xl:pb-0 lg:pb-0">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 xs:grid-cols-1">
          {data.length > 0 && data.map((single, index) => (
            <>
              <div className="xl:bg-forms-homepage-tri lg:bg-forms-homepage-tri pt-0 xl:pt-24 lg:pt-24">
                <div className="text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl font-brandonBlack leading-homeSplashHeadin text-center lg:text-left xl:text-left text-white">
                  <Markup content={single.title} />
                </div>
              </div>
              <div className="lottieAnimationContact order-last xs:order-first md:order-first lg:order-last xl:order-last">
                <Lottie options={defaultOptions} />
              </div>
            </>))}

        </div>
      </div>
    </div>
  );
};

export default ContactSplashSection;
