/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../../images/close.png";
import "./style.scss";
import CrossIcon from "../../images/close-icon.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

// Include Env File
require("dotenv").config();

export default function Example(props) {
  // Schema for yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("* Name is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    message: Yup.string().required("* Message is required"),
    cv: Yup.string().required("* Cv is required"),
  });

  const { open, setOpen } = props;

  // Refrences
  const hiddenFileCv = useRef(null);
  const hiddenFileCoverletter = useRef(null);
  const cancelButtonRef = useRef(null);

  // when the Button component is clicked
  const handleClickCv = (event) => {
    hiddenFileCv.current.click();
  };
  // when the Button component is clicked
  const handleClickCl = (event) => {
    hiddenFileCoverletter.current.click();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
        cv: "",
        coverletter: "",
        subject: "I want to be part of your team",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("message", values.message);
        formData.append("subject", values.subject);
        formData.append("cv", values.cv);
        formData.append("coverletter", values.coverletter);

        axios
          .post(process.env.REACT_APP_BACKEND_URL + "/email/send", formData)
          .then((response) => {
            if (response.status === 200) {
              // Notification
              toast.success("Your message has been sent successfully!", {
                position: "top-right",
              });
              // Reset Form
              resetForm();
            }
          })
          .catch((error) => {});
      }}
      // Hooks up our validationSchema to Formik
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
        } = props;
        return (
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={cancelButtonRef}
              onClose={handleClose}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <form
                      action=""
                      className="join-us-form"
                      id="contactForm"
                      onSubmit={handleSubmit}
                    >
                      <div class="w-full max-w-lg inner-form">
                        <div className="close-icon-lets-talk">
                          <img
                            src={CrossIcon}
                            alt="..."
                            onClick={() => {
                              handleClose();
                            }}
                          />
                        </div>
                        <div class="  -mx-3 mb-3 ">
                          <h1 className="text-center text-2xl heading-lets-talk font-brandonBlack">
                            APPLY
                          </h1>
                        </div>
                        <div class="flex flex-wrap -mx-3 fnt-brandon-light ">
                          <div class="w-full md:w-1/2 px-3   md:mb-0">
                            <input
                              class="border rounded py-2 px-4 mb-3  "
                              id="grid-first-name"
                              type="text"
                              name="name"
                              placeholder="Name*"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            <div className="text-white">
                              {errors.name && errors.name}
                            </div>
                          </div>
                          <div class="w-full md:w-1/2 px-3">
                            <input
                              class="border rounded py-2 px-4 mb-3  "
                              id="grid-first-name"
                              type="email"
                              name="email"
                              placeholder="Email*"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <div className="text-white">
                              {errors.email && errors.email}
                            </div>
                          </div>
                        </div>

                        <div class="flex flex-wrap -mx-3 fnt-brandon-light ">
                          <div class="w-full px-3 mb-6 md:mb-0">
                            <textarea
                              class="border rounded py-3 px-4 mb-3  w-full resize-none"
                              rows="6"
                              id="grid-first-name"
                              type="text"
                              name="message"
                              placeholder="Your message*"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                            />
                            <div className="text-white">
                              {errors.message && errors.message}
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 fnt-brandon-light ">
                          <div class="w-full md:w-1/2 px-3   md:mb-0">
                            <div>
                              <button
                                className="btn-uploads bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                                onClick={handleClickCv}
                                type="button"
                              >
                                Upload CV
                              </button>
                              <input
                                type="file"
                                className="hidden"
                                ref={hiddenFileCv}
                                onChange={(event) => {
                                  setFieldValue(
                                    "cv",
                                    event.currentTarget.files[0]
                                  );
                                }}
                              />
                              {values?.cv?.name ? (
                                <div className="flex items-center">
                                  <p className="clr-upload-file pt-1 fnt-brandon-light ">
                                    {values?.cv?.name}
                                  </p>
                                  <div>
                                    <img
                                      className="h-4 cursor-pointer ml-5"
                                      src={CloseIcon}
                                      alt="..."
                                      onClick={(event) => {
                                        setFieldValue("cv", "");
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : errors.cv && errors.cv ? (
                                <p class="text-red-600 text-xs fnt-brandon-light ">
                                  Please, add your CV
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div class="w-full md:w-1/2 px-3">
                            <div className="text-right">
                              <button
                                className="btn-uploads bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                                onClick={handleClickCl}
                                type="button"
                              >
                                Upload Cover Letter
                              </button>
                              <input
                                type="file"
                                className="hidden"
                                ref={hiddenFileCoverletter}
                                onChange={(event) => {
                                  setFieldValue(
                                    "coverletter",
                                    event.currentTarget.files[0]
                                  );
                                }}
                              />
                              {values?.coverletter?.name ? (
                                <div className="flex justify-end items-center">
                                  <p className="clr-upload-file pt-1 fnt-brandon-light mr-5">
                                    {values?.coverletter?.name}
                                  </p>
                                  <div>
                                    <img
                                      className="h-4 cursor-pointer"
                                      src={CloseIcon}
                                      alt="..."
                                      onClick={(event) => {
                                        setFieldValue("coverletter", "");
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="text-center mt-4 -mx-3 font-brandonBlack ">
                          <button
                            className="btn-lets-talk bg-blue-500 hover:bg-blue-700 text-white py-2 px-8 "
                            type="submit"
                          >
                            SEND
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        );
      }}
    </Formik>
  );
}
