import { useEffect, useState } from "react";
import ContactUsForm from "../../components/contactUsForm/contactUsForm";
import TopSection from "../../components/contactTopSection";
import Loader from "../../components/loader/loader";

// Services
import { getAllSplash } from "../../service/splash";
import { getAll } from "../../service/contactUs";
const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    contact: [],
  });
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "10"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          contact: response?.data?.data,
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TopSection data={state?.splashData} />
          <ContactUsForm data={state?.contact} />
        </div>
      )}
    </>
  );
};
export default Contact;
