import React from "react";
// import userImage from "../../../images/hubspot/Alain.jpg";
// import userImageOne from "../../../images/hubspot/JIM2.jpg";
// import userImageTwo from "../../../images/hubspot/Jim.jpg";
// import rectangleImageOne from "../../../images/hubspot/Rectangle.png";
// import rectangleImageFour from "../../../images/hubspot/Rectangle4.png";
import parse from "html-react-parser";

const WhyAgifly = (props) => {
  const { data } = props;
  return (
    <div className="bg-lightBlue">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1 gap-3 xl:gap-20 lg:gap-20 text-heading">
          <div className="col-span-5 py-0 xl:py-20 lg:py-20 xs:py-10">
            <h1 className="text-left font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal">
              {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
            </h1>
            <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-10">
              {parse(`<p>${data && data[1] && data[1]?.description}</p>`)}
            </p>
            {/* <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-5">
              {data &&
                data[1] &&
                data[1]?.description?.split(/;?\r\n|;?\n|;?\r/)[2]}{" "}
            </p> */}
          </div>
          <div className="col-span-7">
            <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1 gap-4">
              <div className="col-span-5 bg-secondry hidden xl:block lg:block"></div>
              <div className="col-span-12 xl:col-span-7 lg:col-span-7">
                <img
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[2] && data[2].image
                  }`}
                  className="w-full"
                  alt=""
                />
              </div>
              <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1 gap-4">
                  <div className="col-span-10">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                        data && data[3] && data[3].image
                      }`}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="col-span-2 bg-mediumBlue hidden xl:block lg:block"></div>
                  <div className="col-span-12 bg-orange h-14 hidden xl:block lg:block"></div>
                </div>
              </div>
              <div className="col-span-12 xl:col-span-6 lg:col-span-6">
                <div class="grid grid-rows-1 xl:grid-rows-2 lg:grid-rows-2 grid-flow-col gap-4">
                  <div class="row-start-1 row-end-2">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                        data && data[4] && data[4].image
                      }`}
                      className="w-full"
                      alt=""
                    />
                  </div>
                  <div class="row-start-2 row-end-3 bg-secondry hidden xl:block lg:block"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyAgifly;
