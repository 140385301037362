import React from 'react'
import {  useNavigate } from 'react-router-dom'
import LinePoint from '../../images/home/Ligne+point.svg'
import './workTogether.scss'
export default function WorkTogether() {
    const navigate = useNavigate();
    return (
        <div className='work-container'>
            <div className="col-span-5 xl:col-span-2 lg:col-span-2 md:col-span-2 self-center text-xl xl:text-homeWorkWithUsHeadingText lg:text-homeWorkWithUsHeadingText md:text-4xl font-brandonBlack xl:leading-homeWorkWithUsHeadingText lg:leading-homeWorkWithUsHeadingText text-orange">
                <h1>HOW DO WE WORK TOGETHER?</h1>
            </div>
                <div className='work-image'>
                    <div className='work-content-container grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 pt-9' > 
                        <div className=''>
                            <span className='work-heading-1 font-bold text-homeWorkTogetherDiagramHeadings'> 
                                BUILD
                            </span>
                            <p className='work-heading-1-text font-brandonLight text-homeWorkTogetherDiagramText'>
                                <span className='font-brandonLight font-bold'>We set-up a core team </span> 
                                that work in a dedicated office space with its' own legal entity.
                            </p>
                        </div>
                        <div className=''>
                            <span className='work-heading-2      font-bold text-homeWorkTogetherDiagramHeadings'>
                                OPERATE
                            </span>
                            <p className='work-heading-2-text font-brandonLight text-homeWorkTogetherDiagramText'>
                                <span className='font-bold'>We manage the local operations </span>
                                of your center so you can focus on your product roadmap but you
                                decide on all costs/processes.
                            </p>
                        </div>
                        <div className=''>
                            <span className='work-heading-3 font-bold text-homeWorkTogetherDiagramHeadings'>
                                OPERATE
                            </span>
                            <p className='work-heading-3-text font-brandonLight text-homeWorkTogetherDiagramText'> 
                                We scale your center by recruiting and onboarding talents who meet your expectations.
                            </p>
                            <span className='work-heading-4 font-bold text-homeWorkTogetherDiagramHeadings'>
                                TRANSFER
                            </span>
                            <p className='work-heading-4-text font-brandonLight text-homeWorkTogetherDiagramText'>
                            At the end of the contract period you acquire 100% ownership of a fully operational 
                            and autonomous development center
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <button 
                    className='work-button'
                    onClick={()=>navigate('/work-with-us')}
                    >
                        KNOW MORE
                    </button>
                </div>
        </div>
    )
}
