import React from "react";
import ImageOne from "../../images/projectdetail/1.svg";
import ImageTwo from "../../images/projectdetail/2.svg";
import ImageThree from "../../images/projectdetail/3.svg";
import ImageFour from "../../images/projectdetail/4.svg";
import "./details.scss";
import IconWithContent from "./inner-component/iconWithContent";
import parse from "html-react-parser";

const Details = (props) => {
  const { data } = props;
  return (
    <div className="w-full bg-lightBlue mt-160px lg:pt-52 xs:pt-32 pb-20 fnt-brandon-light">
      <section className="max-w-7xl sm:w-full project_videos_section mx-auto px-6 py-12 company-description ">
        <div className="container">
          <aricle>
            <h2 className="heading font-bold fnt-brandon-black mb-3">
              DESCRIPTION
            </h2>
            {parse(
              `<p className="description text-justify">${data?.description}</p>`
            )}

            <br />
          </aricle>

          <aricle className="cards-wrapper">
            <IconWithContent
              heading="COMPANY"
              description={data?.company_text}
              icon={ImageTwo}
            />
            <IconWithContent
              heading="RESULT"
              description={data?.result_text}
              icon={ImageFour}
            />
            <IconWithContent
              heading="CHALLENGE"
              description={data?.challange_text}
              icon={ImageThree}
            />
            <IconWithContent
              heading="BENEFITS"
              description={data?.benefits_text}
              icon={ImageOne}
            />
          </aricle>
        </div>
      </section>
    </div>
  );
};
export default Details;
