import React from "react";
import parse from "html-react-parser";
import "./iconWithContent.scss";

const IconWithContent = (props) => {
  const { icon, heading, description } = props;
  return (
    <div className="icon-with-content gap-y-2 icon-with-content">
      <div className="flex justify-center">
        <img className="h-24" src={icon} alt="" />
      </div>
      <h3 className="font-bold fnt-brandon-black pt-4">{heading}</h3>
      {parse(`<p className="pt-4">${description}</p>`)}
    </div>
  );
};

export default IconWithContent;
