import React from "react";
import IconTriangle from "../../images/home/Triangle.svg";
import "./homeWorkWithUs.scss";
import { Markup } from "interweave";

const HomeWorkWithUs = (props) => {
  const { data } = props;
  const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
  return (
    <div className="bg-lightBlue font-brandonBold text-heading">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <img src={IconTriangle} className="w-1/4 h-1/4 m-auto" />
        <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-6 gap-1 xl:gap-2 lg:gap-2 md:gap-2">
          <h1 className="text-7xl xl:text-homeWorkWithUsNumberHeading lg:text-homeWorkWithUsNumberHeading md:text-homeWorkWithUsNumberHeading text-left xl:text-right lg:text-right md:text-right self-start">
            <Markup content={data[0]?.title_number} />
          </h1>
          <div className="col-span-5 xl:col-span-2 lg:col-span-2 md:col-span-2 self-center text-xl xl:text-homeWorkWithUsHeadingText lg:text-homeWorkWithUsHeadingText md:text-4xl text-left font-brandonBlack xl:leading-homeWorkWithUsHeadingText lg:leading-homeWorkWithUsHeadingText">
            <Markup content={data[0]?.title_text} />
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 gap-5 xl:gap-4 lg:gap-4">
          {data.map(
            (item, index) =>
              ((index > 0) && (index <= 4)) && (
                <>
                  {/* For Large Screens */}
                  <div className="block xl:block lg:block md:block xs:hidden">
                    <img src={AssetsUrl + item.image_url} className="w-1/4 h-1/3 mx-auto" />
                    <h1 className="text-homeWorkWithUsIconHeading font-bold my-3">
                      {item.image_title}
                    </h1>
                    <p className="text-menu font-bold font-brandonLight">
                      {item.image_text}
                    </p>
                  </div>
                  {/* For Mobile Screens */}
                  <div className="xl:hidden lg:hidden md:hidden sm:hidden grid grid-cols-12 py-5 gap-5">
                    <div
                      className={`${index % 2 == 0
                        ? "col-span-4 my-auto order-last lg:order-first xl:order-first md:order-first"
                        : "col-span-4 my-auto"
                        }`}
                    >
                      <img src={AssetsUrl + item.image_url} className="mx-auto" />
                    </div>
                    <div
                      className={`${index % 2 == 0
                        ? "col-span-8 my-auto text-right py-5 my-auto text-left border-bottom-white"
                        : "col-span-8 my-auto text-left py-5 my-auto text-left border-bottom-white"
                        }`}
                    >
                      <h1 className="text-homeWorkWithUsIconHeading font-bold my-3">
                        {item.image_title}
                      </h1>
                      <p className="text-menu font-bold font-brandonLight">
                        {item.image_text}
                      </p>
                    </div>
                  </div>
                </>
              )
          )}
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 my-5 xl:my-0 lg:my-0 md:my-10 mx-0 xl:mx-32 lg:mx-32 gap-5 xl:gap-4 lg:gap-4">
          {data.map(
            (item, index) =>
              index > 4 && (
                <>
                  {/* For Large Screens */}
                  <div className="block xl:block lg:block md:block xs:hidden">
                    <img src={AssetsUrl + item.image_url} className="w-1/4 h-1/3 mx-auto" />
                    <h1 className="text-homeWorkWithUsIconHeading font-bold my-3">
                      {item.image_title}
                    </h1>
                    <p className="text-menu font-bold font-brandonLight">
                      {item.image_text}
                    </p>
                  </div>
                  {/* For Mobile Screens */}
                  <div className="xl:hidden lg:hidden md:hidden sm:hidden grid grid-cols-12 py-5 gap-5">
                    <div
                      className={`${index % 2 == 0
                        ? "col-span-4 my-auto order-last"
                        : "col-span-4 my-auto"
                        }`}
                    >
                      <img src={AssetsUrl + item.image_url} className="mx-auto" />
                    </div>
                    <div
                      className={`${index % 2 == 0
                        ? "col-span-8 py-5 my-auto text-right border-bottom-white"
                        : "col-span-8 py-5 my-auto text-left border-bottom-white"
                        }`}
                    >
                      <h1 className="text-homeWorkWithUsIconHeading font-bold my-3">
                        {item.image_title}
                      </h1>
                      <p className="text-menu font-bold font-brandonLight">
                        {item.image_text}
                      </p>
                    </div>
                  </div>
                </>
              )
          )}
        </div>
      </div>
      {/* <WorkTogether /> */}
      <div className="homeWork-bottom-triangle">
        <img src={IconTriangle} className="w-1/4 h-1/4 m-auto" />
      </div>
    </div>
  );
};

export default HomeWorkWithUs;
