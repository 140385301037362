import { Markup } from "interweave";

const ProjectVideo = (props) => {
  const { data } = props;
  const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
  return (
    <div className="container mx-auto pb-12">
      <div className="flex flex-col lg:flex-row lg:flex-center items-center lg:justify-center ">
        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 xs:grid-cols-2 flex justify-between items-center">
          {
            data.map(
              (item, index) => (
                <div key={index} className="px-4 lg:px-12">
                  <div className="flex justify-center">
                    <img src={AssetsUrl + item.image_url} alt="" />
                  </div>
                  <div className="text-center py-6 brandonLight">
                    <h4 className="text_primary_light fs-14">
                      <Markup content={item.image_title} />
                    </h4>
                  </div>
                </div>
              ))
          }
        </div>
      </div>
    </div >
  );
};
export default ProjectVideo;
