import React from "react";
import { Markup } from "interweave";
import "./aboutUs.scss";

// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
const OurPartner = (props) => {
  const { data } = props;
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <section className="py-10 px-4">
        <h1 className="text-left text-left text-xl fnt-brandon-black lg:text-4xl md:text-3xl sm:text-2xl lg:text-left xl:text-left ">
          <Markup content={data[0]?.title} />
        </h1>
        <div className="grid grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 xs:grid-cols-1 mt-8 gap-10 xl:gap-20 lg:gap-10 md:gap-10">
          {data?.map((value, index) => index > 0 && (
            <div className="my-auto" key={index}>
              <a href={value?.url} target="_blank" className="mx-auto">
                <img
                  className={`${index === 1 ? "w-1/2 m-auto" : "m-auto"}`}
                  src={AssetsUrl + value?.image}
                  alt=""
                />
              </a>
              {/* <div className="mt-3 leading-tight">
                <h1 className="member-name  lg:text-2xl fnt-brandon-black">
                  {value?.title}
                </h1>
              </div> */}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default OurPartner;
