import React, { useState } from "react";
import axios from "axios";
import "./style.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Markup } from "interweave";

function contactUsForm(props) {
  const { data } = props;
  // Schema for yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("* Name is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    message: Yup.string().required("* Message is required"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
        subject: "New message from the website",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        axios
          .post(process.env.REACT_APP_BACKEND_URL + "/email/send", values)
          .then((response) => {
            if (response.status === 200) {
              // Notification
              toast.success("Your message has been sent successfully!", {
                position: "top-right",
              });
              // Reset Form
              resetForm();
            }
          })
          .catch((error) => {});
      }}
      // Hooks up our validationSchema to Formik
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        } = props;
        return (
          <div>
            {/* HomePage_main_div */}
            <div className="container mx-auto contact_us_container py-10">
              {/* left_side_div */}
              <div className="left_side_div bg-blue-100 py-5 px-9 rounded-sm shadow-sm">
                {/* content_div */}
                <div>
                  {/* heading_div */}
                  <div>
                    <h1 className="m-0 left-right-title text-2xl xl:text-2xl lg:text-2xl md:text-2xl xs:text-xl  font-brandonBlack ">
                      {data && data[0]?.title}
                    </h1>
                  </div>
                  {/* Address_div */}
                  <div className=" py-2.5">
                    {/* office_heading */}
                    <div className="py-1 text-left">
                      <h3 className="m-0 text-lg font-bold text_primary_dark_2 font-brandonMedium">
                        Office
                      </h3>
                      <h6 className="m-0 text-xs font-medium text_primary_dark_2 font-brandonLight">
                      <Markup content={data && data[0]?.office} />
                      </h6>
                    </div>
                    {/* Legal_Entity_heading */}
                    <div className="py-1 text-left">
                      <h3 className="m-0 text-lg font-bold text_primary_dark_2 font-brandonMedium">
                      Legal Entity
                      </h3>
                      <p className="m-0 text-sm font-light py-1 font-brandonLight text_primary_light_2 common-text-color">
                      <Markup content={data && data[0]?.legal_entity} />
                      </p>
                    </div>
                    {/* Call_us_heading */}
                    <div className="py-1 text-left">
                      <h3 className="m-0 text-lg font-bold font-brandonMedium text_primary_dark_2">
                        Call us
                      </h3>
                      <p className="m-0 text-sm font-light font-brandonLight text_primary_light_2 common-text-color">
                      {data && data[0]?.call_us}
                      </p>
                    </div>
                    {/* Email_us_heading */}
                    <div className="py-1 text-left">
                      <h3 className="m-0 text-lg font-bold font-brandonMedium text_primary_dark_2">
                        Email us
                      </h3>
                      <p className="m-0 text-sm font-light font-brandonLight text_primary_light_2 common-text-color">
                      {data && data[0]?.email_us}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Middle_div*/}
              <div className="Middle_div bg_primary_light py-5 lg:px-20 xs:px-6 rounded-sm shadow-sm">
                {/* heading_div */}
                <div>
                  <h1 className="m-0 form_title text-2xl xl:text-2xl lg:text-2xl md:text-2xl xs:text-xl text-center  font-brandonBlack ">
                    SEND US A MESSAGE !
                  </h1>
                </div>
                {/* form_div */}
                <form
                  action=""
                  className="form_div"
                  id="contactForm"
                  onSubmit={handleSubmit}
                >
                  {/* your_name_div */}
                  <div className=" your_name_div py-1 text-left">
                    <lable className="m-0 text-white py-2 text-left font-brandonMedium">
                      Your name
                    </lable>
                    <input
                      placeholder="Enter Your Name "
                      className="w-full py-1.5 my-2 px-3.5 font-extralight fnt-brandon-light"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <div className="text-orange">
                      {errors.name && errors.name}
                    </div>
                  </div>
                  {/* your_email_div */}
                  <div className="py-1 text-left">
                    <lable className="m-0 text-white py-2 text-left font-brandonMedium">
                      Your email
                    </lable>
                    <input
                      placeholder="Enter a valid email "
                      className="w-full py-1.5 my-2 px-3.5 font-extralight fnt-brandon-light"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <div className="text-orange">
                      {errors.email && errors.email}
                    </div>
                  </div>
                  {/* your_message_div */}
                  <div className="py-1 text-left">
                    <label className="m-0 text-white text-left font-brandonMedium">
                      Your message
                    </label>
                    <textarea
                      placeholder="Enter your messege "
                      className="w-full py-1.5 my-2 px-3.5 font-extralight fnt-brandon-light"
                      rows={5}
                      name="message"
                      id="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                    <div className="text-orange">
                      {errors.message && errors.message}
                    </div>
                  </div>
                  {/* button_div */}
                  <div className="flex justify-center py-4">
                    <button
                      type="submit"
                      className="text-white text-base font-brandonMedium font-semibold px-12 py-2 "
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
              {/*right_side_div */
              /* <div className="right_side_div bg-blue-100 py-5 px-9 rounded-sm shadow-sm">
                <div>
                  <div>
                    <h1 className="m-0 left-right-title text-2xl xl:text-2xl lg:text-2xl md:text-2xl xs:text-xl font-brandonBlack ">
                    {data && data[1]?.title}
                    </h1>
                  </div>
                  <div className="py-2.5">
                    <div className="py-1 text-left">
                      <h3 className="m-0 text-lg font-bold text_primary_dark_2 font-brandonMedium">
                        Office
                      </h3>
                      <p className="m-0 text-sm font-light text_primary_light_2 common-text-color font-brandonLight">
                      <Markup content={data && data[1]?.office} />
                      </p>
                    </div>
                    <div className="pt-1">
                      <div className="py-1 text-left">
                        <h3 className="m-0 text-lg font-bold text_primary_dark_2 font-brandonMedium">
                          Call us
                        </h3>
                        <p className="m-0 text-sm font-light text_primary_light_2 common-text-color font-brandonLight">
                        {data && data[1]?.call_us}
                        </p>
                      </div>
                      <div className="py-1 text-left">
                        <h3 className="m-0 text-lg font-bold text_primary_dark_2 font-brandonMedium">
                          Email us
                        </h3>
                        <p className="m-0 text-sm font-light text_primary_light_2 common-text-color font-brandonLight">
                        {data && data[1]?.email_us}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
export default contactUsForm;
