import React from "react";
import TopSection from "./workWithUsTopSection";
import WorkTogether from "../workTogether/workTogether";
import "./style.scss";

function workWithUs() {
  return (
    <>
      <TopSection />
      <div className="max-w-7xl mx-auto p-6 sm:px-6 work-with-us-main">
        <div class="grid gap-4 grid-cols-2">
          <div className="div-top py-4">
            <h1 class="m-0  text-2xl font-bold color-white font-brandonBlack ">
              DEVELOP YOUR TEAM INTERNALLY
            </h1>
          </div>
          <div className="vs-div ">
            <h1 class="m-0  text-3xl font-bold color-white font-brandonBlack ">
              VS
            </h1>
          </div>
          <div className="div-top py-4">
            <h1 class="m-0  text-2xl font-bold color-white font-brandonBlack ">
              LET’S DO IT WITH AGIFLY !
            </h1>
          </div>

        <div className="p-4 text-left fnt-brandon-medium bg-blue-100 text-base ">
          <p className="pb-3">
            You spend time, resources and energy to find the best profiles for
            each position.
          </p>
          <p className="pb-3">
            Single profiles, no matter how good, sometimes take time to work as
            a team.
          </p>
          <p className="pb-3">
            One weak link can disrupt the entire operation and schedule, but
            you'll probably find that out too late.
          </p>
          <p className="pb-3">
            The cost of hiring permanent staff will be high
          </p>
        </div>
        <div className="p-4 text-left fnt-brandon-medium bg-blue-100 text-base ">
          <p className="pb-3">
            Our teams are already tested and approved on many projects. We will
            be able to provide you with the best team for your project, because
            we already know them well!
          </p>
          <p className="pb-3">
            You pay a monthly fee for a complete service, we manage the
            contracts and salaries of the teams.
          </p>
          <p className="pb-3">
            When your project is launched, you have the choice: you continue to
            work with your Agifly team, or you have the possibility to take this
            team with you!
          </p>
          {/* <p className="pb-3">
            Trouver les meilleurs profils, créer la meilleure équipe, leur
            laisser
          </p> */}
          </div>
        </div>
      </div>
      <div>
      <WorkTogether />
      </div>
    </>
  );
}

export default workWithUs;
