import React, { useState, useEffect } from "react";
import "./filterProjects.scss";
export default function ButtonsCard(props) {
  let { buttonList, setButtonList, handleButtons } = props;

  return (
    <div className="mb-5 buttons-container">
      <div className="shadow buttons-div">
        <div>
          {buttonList?.slice(0, 4)?.map((obj, index) => (
            <CustomButton
              index={index}
              obj={obj}
              handleButtons={handleButtons}
            />
          ))}
        </div>
        {buttonList?.slice(4, 9)?.map((obj, index) => (
          <CustomButton
            index={index + 4}
            obj={obj}
            handleButtons={handleButtons}
          />
        ))}
      </div>
    </div>
  );
}

const buttonNameHandler = (name) => {
  switch (name) {
    case "ScaleUp":
      return "Scale-Up";
    case `SME`:
      return "SME";
    case "Corporate":
      return "Corporate";
    case "Government":
      return "Government";
    case "Apps":
      return "Apps";
    case "Web Apps":
      return "Web Apps";
    case "Mobile Apps":
      return "Mobile Apps";
    case "Websites":
      return "Websites";
    case "digitalSolutions":
      return "Digital Solutions";
    case "StartUp":
      return "Start-Up";
    case "SASS":
      return "SAAS";
  }
};

const CustomButton = (props) => {
  return (
    <button
      id={props.index}
      name={props.obj.name}
      className={props.obj.selected ? "buttons-selected" : "buttons-unselected"}
      onClick={props.handleButtons}
    >
      {buttonNameHandler(props.obj.name)}
    </button>
  );
};
