/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import Image from "../../images/lets.png";
import CrossIcon from "../../images/close-icon.png";
import "./style.scss";
import { toast } from "react-toastify";

export default () => {
  // States
  const [show, setShow] = useState(false);

  // Schema for yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("* Name is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    message: Yup.string().required("* Message is required"),
  });
  return (
    <Formik
      initialValues={{
        name: "",
        company: "",
        phone: "",
        email: "",
        message: "",
        page: "letstalk",
        subject: "New message from the website",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        axios
          .post(process.env.REACT_APP_BACKEND_URL + "/email/send", values)
          .then((response) => {
            console.log("response", response);
            if (response.status === 200) {
              // Notification
              toast.success("Your message has been sent successfully!", {
                position: "top-right",
              });
              // Reset Form
              resetForm();
            }
          })
          .catch((error) => {});
      }}
      // Hooks up our validationSchema to Formik
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        } = props;
        return (
          <div className="xs:hidden sm:hidden lg:block md:hidden">
            <div class="grid grid-cols-6 gap-4 main-let-talk-button">
              <div class="col-start-2 col-span-4 ...  inner-col  ">
                {!show && (
                  <img
                    src={Image}
                    className="image-lets-talk"
                    alt=""
                    onClick={() => {
                      setShow(show ? false : true);
                    }}
                  />
                )}
                {show && (
                  <div className="lets-talk-form">
                    <form
                      action=""
                      className="w-full max-w-lg"
                      id="contactForm"
                      onSubmit={handleSubmit}
                    >
                      <div className="close-icon-lets-talk">
                        <img
                          src={CrossIcon}
                          alt="..."
                          onClick={() => {
                            setShow(false);
                          }}
                        />
                      </div>
                      <div class="  -mx-3 mb-3 ">
                        <h1 className="heading-lets-talk text-2xl font-brandonBlack">
                          LET'S TALK
                        </h1>
                      </div>

                      <div class="flex flex-wrap -mx-3 fnt-brandon-light ">
                        <div class="w-full md:w-1/2 px-3   md:mb-0">
                          <input
                            class="border rounded py-2 px-4 mb-3  "
                            id="grid-first-name"
                            type="text"
                            placeholder="Name*"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          <div className="text-white text-left">
                            {errors.name && errors.name}
                          </div>
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <input
                            class="border rounded py-2 px-4 mb-3  "
                            id="grid-first-name"
                            type="text"
                            placeholder="Company"
                            name="company"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company}
                          />
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 fnt-brandon-light">
                        <div class="w-full md:w-1/2 px-3 md:mb-0">
                          <input
                            class="border rounded py-2 px-4 mb-3  "
                            id="grid-first-name"
                            type="email"
                            placeholder="Email address*"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <div className="text-white text-left">
                            {errors.email && errors.email}
                          </div>
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <input
                            class="border rounded py-2 px-4 mb-3  "
                            id="grid-first-name"
                            type="text"
                            placeholder="Phone number"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                          />
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 fnt-brandon-light ">
                        <div class="w-full px-3 mb-6 md:mb-0">
                          <textarea
                            class="border rounded py-3 px-4 mb-3  w-full resize-none"
                            rows="6"
                            id="grid-first-name"
                            type="text"
                            placeholder="Your message*"
                            name="message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                          />
                          <div className="text-white text-left">
                            {errors.message && errors.message}
                          </div>
                        </div>
                      </div>
                      <div class="  -mx-3 font-brandonBlack ">
                        <button
                          type="submit"
                          class="btn-lets-talk bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 "
                        >
                          SEND
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
