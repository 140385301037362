import React from "react";
import { Markup } from "interweave";
import "./style.scss";

const OurPurpose = (props) => {
  const { data } = props;
  return (
    <div className="w-full main-purpose-section">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
        <section className="py-16 px-4">
          <div className="flex flex-wrap -mx-4 -mb-8 justify-between">
            {data.length > 0 && data.map((single, index) => (
              <div key={index} className={`${index === 0 ? "md:w-1/4 lg:w-1/4" : "md:w-1/3 lg:w-1/3"}xs:w-full sm:w-1/2 px-4 mb-8`}>
                <div className="flex justify-center">
                  <h1 className="member-name  lg:text-2xl fnt-brandon-black mb-2">
                    <Markup content={single.title} />
                  </h1>
                </div>
                <p className="fnt-brandon-light clr-for-text">
                  <Markup content={single.description} />
                </p>
              </div>
            ))}
          </div>
        </section>
      </div >
    </div >
  );
};

export default OurPurpose;
