/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import "./joinUs.scss";
import TopSection from "../joinUs/joinUsTopSection";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../loader/loader";

// Services
import { getAllSplash } from "../../service/splash";
import { getAll } from "../../service/joinUs";
export default () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    jobs: [],
    benefits: [],
  });
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "8"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          benefits: response?.data?.data?.filter(
            (row) => row.section === "benefits"
          ),
          jobs: response?.data?.data?.filter((row) => row.section === "job"),
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TopSection data={state?.splashData} />
          <div className="container mx-auto flex justify-center flex-wrap py-10 join-us-main">
            <div className="lg:w-1/5 py-5  rounded-sm">
              <div>
                <div className="py-4 pb-6 px-3 left-div">
                  <div className="py-1 text-left text-base left-div-p">
                    {state?.benefits?.map((single, index) => (
                      <div className="flex pb-2 items-center" key={single?.id}>
                        {single?.benefits_img ? (
                          <>
                            <img
                              src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${single?.benefits_img}`}
                              className="h-4 w-4"
                              // alt="..."
                            />{" "}
                            <span
                              className={
                                index === state?.benefits.length - 1
                                  ? "fnt-brandon-bold mt-2 ml-2 text-lg"
                                  : "fnt-brandon-light ml-2 ml-2"
                              }
                            >
                              {single?.benefits_title}
                            </span>{" "}
                          </>
                        ) : (
                          <div className="fnt-brandon-bold mt-2 text-lg ml-7">
                            {single?.benefits_title}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className=" lg:w-1/2  py-5 pl-4 rounded-sm">
              {state?.jobs?.map(
                (single) =>
                  single.is_active == "active" && (
                    <div
                      className="bg-blue-100 mb-4 p-4 text-left"
                      key={single?.id}
                    >
                      <div>
                        <h1 className="uppercase m-0 text-blue-800 text-2xl xl:text-2xl lg:text-2xl xs:text-xl font-bold color-blue font-brandonBlack ">
                          {single?.job_title}
                        </h1>
                        <p className="fnt-brandon-light color-para text-base">
                          {parse(`<p>${single?.job_description}</p>`)}
                        </p>
                        <div className="apply-btn lg:text-right xs:text-center mt-4">
                          <button
                            type="button"
                            className=" font-brandonBlack "
                            onClick={() => {
                              navigate("/product-owner", {
                                state: { data: single?.jobdetails },
                              });
                            }}
                          >
                            <h2 className="font-normal xl:font-semibold lg:font-semibold text-base   text-white">
                              KNOW MORE
                            </h2>
                          </button>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
