import React, { useState, useRef, useEffect } from "react";

import "./usecase2-alt.css";

// asset imports here
import axios from "axios";
import { toast } from "react-toastify";
import HeaderLogo from "../../images/usecase-alt/navigation/logo_color.svg";
import IconLogo from "../../images/usecase-alt/navigation/icon_logo.svg";
import MessageIcon from "../../images/usecase-alt/navigation/bx-message-square-detail.svg";
import Background from "../../images/usecase2-alt/section1/section1_background.png";
import Laptop from "../../images/usecase2-alt/section1/section1_screens.png";
import MultiViews from "../../images/usecase2-alt/section3/section3_image.png";
import BackgroundSecondary from "../../images/usecase2-alt/section3/section345_background.svg";
import Check from "../../images/usecase2-alt/section3/icon_check.svg";
import Light from "../../images/usecase2-alt/section4/icon_light.svg";
import Pencil from "../../images/usecase2-alt/section4/icon_pencil.svg";
import Screen from "../../images/usecase2-alt/section4/icon_screens.svg";
import BackgroundTeritary from "../../images/usecase2-alt/section7/section78_background.svg";
import OptifluxDesign from "../../images/usecase2-alt/section7/section7_image.svg";
import LaptopWBackground from "../../images/usecase2-alt/section8/section8_image.png";
import FooterLogo from "../../images/usecase2-alt/footer/logo_footer.svg";
import FooterLinkedin from "../../images/usecase2-alt/footer/Icon awesome-linkedin-in.svg";

// carousel parts
import Mobile1 from "../../images/usecase2-alt/section5/mobile1.png";
import Mobile2 from "../../images/usecase2-alt/section5/mobile2.png";
import Mobile3 from "../../images/usecase2-alt/section5/mobile3.png";
import Mobile4 from "../../images/usecase2-alt/section5/mobile4.png";
import Mobile5 from "../../images/usecase2-alt/section5/mobile5.png";
import Mobile6 from "../../images/usecase2-alt/section5/mobile6.png";
import Mobile7 from "../../images/usecase2-alt/section5/mobile7.png";
import Mobile8 from "../../images/usecase2-alt/section5/mobile8.png";

// react icons here
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

const UsecaseAlt2 = () => {
    const [ carouselIndex, setCarouselIndex ] = useState(1);
    const [ firstname, setFirstname ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ message, setMessage ] = useState("");
    const [ renderPhase, setRenderPhase ] = useState(0);

    const targetRef = useRef(null);
    const pageScrollRef = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", handlePageScroll);
        setRenderPhase(1);

        return () => {
            window.removeEventListener("scroll", handlePageScroll);
        }
    }, [])

    const handleCarouselLeft = () => {
        if(carouselIndex === 0) {
            setCarouselIndex(0);
        } else {
            setCarouselIndex(carouselIndex - 1);
        }
    }

    const handleCarouselRight = () => {
        if(carouselIndex === 7) {
            setCarouselIndex(7);
        } else {
            setCarouselIndex(carouselIndex + 1);
        }
    }

    const scrollToBottom = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToHome = () => {
        window.location.href = 'https://www.agifly.be/';
    }

    const handleFirstnameChange = (event) => {
        setFirstname(event.target.value)
    }
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const handlePageScroll = (event) => {
        console.log(window.scrollY)
        if(window.scrollY >= 950) {
            setRenderPhase(3);
        }
        else if(window.scrollY >= 500) {
            setRenderPhase(2);
        }
        else if(window.scrollY >= 300) {
            setRenderPhase(1)
        }
    }

    const onFormSubmit = () => {
        // send email here
        axios
        .post(process.env.REACT_APP_BACKEND_URL + "/email/send", {
            "email": email,
            "name": firstname,
            "message": message
        })
        .then((response) => {
          if (response.status === 200) {
            // Notification
            window.lintrk('track', { conversion_id: 13977202 })
            toast.success("Your message has been sent successfully!", {
                position: "top-right",
            });
          }
        })
        .catch((error) => {});
    }

    return(
        <>
            {window.screen.width > 600 ? (
                <div className = "w-full flex flex-col overflow-y-hidden" style = {{backgroundColor: "#FAFAFA"}} ref = {pageScrollRef} onScroll = {handlePageScroll}>
                    <div className = "w-full flex bg-white items-center justify-between box-border relative z-10" style = {{paddingLeft: "72px", paddingRight: "72px", height: "72px"}}>
                        <div className = "flex">
                            <a href="https://www.agifly.be/">
                                <img className = "object-contain" style = {{height: "47px"}} src = {HeaderLogo} alt = "header logo" />
                            </a>
                        </div>
                        <div className = "flex items-center">
                            <button type = "button" className = "usecase-alt-header-button-one font-sofia" style = {{marginRight: "18px"}} onClick = {scrollToBottom}>
                                <img className = "object-contain" style = {{height: "17px", marginRight: "14px"}} src = {MessageIcon} alt = "message icon" />
                                <p>CONTACT US</p>
                            </button>
                            <button type = "button" className = "usecase-alt-header-button-two font-sofia" onClick = {goToHome}>
                                <img className = "object-contain" style = {{height: "15px", marginRight: "10px"}} src = {IconLogo} alt = "icon logo" />
                                <p>OUR WEBSITE</p>
                            </button>
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex items-center relative" style = {{paddingLeft: "72px", paddingRight: "72px", height: "calc(100vh - 72px)"}}>
                        <img className = "w-full object-cover absolute left-0" style = {{top: "-10%"}} src = {Background} alt = "background" />
                        <div className = "w-full h-full absolute left-0 bg-white duration-700 ease-in-out" style = {{transitionDelay: "0.3s", top: renderPhase >= 1 ? "-100%" : "0%"}} />
                        <div className = "flex flex-1 shrink-0 flex-col items-start justify-start relative" style = {{height: "461px"}}>
                            <p className = "font-sofia font-black text-white usecase-alt-heading duration-500" style = {{transitionDelay: "1s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateY(0px)" : "translateY(15px)"}}>Aderco</p>
                            <p className = "font-sofia font-regular text-white usecase-alt-header-subtext text-left duration-500 text-lg" style = {{transitionDelay: "1.1s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateY(0px)" : "translateY(15px)"}}>Aderco Maritime App - User Friendly<br></br>Digital Support for Aderco’s Technical<br></br>Team</p>
                        </div>
                        <div className = "flex shrink-0 relative" style = {{flex: 2}}>
                            <img className = "object-fit duration-700" style = {{height: "461px", transitionDelay: "1.2s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateX(0px)" : "translateX(15px)"}} src = {Laptop} alt = "laptop" />
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex flex-col items-center justify-center" style = {{marginTop: "128px"}}>
                        <p className = "font-sofia font-black text-black duration-500 ease-in-out" style = {{fontSize: "38px", opacity: renderPhase >= 2 ? 1 : 0, transform: renderPhase >= 2 ? "translateY(0px)" : "translateY(-15px)"}}>How did we get started?</p>
                        <p className = "font-sofia font-regular text-black duration-500 ease-in-out" style = {{fontSize: "16px", maxWidth: "800px", marginTop: "32px", opacity: renderPhase >= 2 ? 1 : 0, transform: renderPhase >= 2 ? "translateY(0px)" : "translateY(15px)", transitionDelay: "0s"}}>With the goal of staying ahead of the competition and being innovative, Aderco chose to digitize. Together with Agifly, they have developed a state-of-the-art mobile app that provides user-friendly support to the technical team and operational managers</p>
                    </div>
                    <div className = "w-full flex flex-col box-border relative" style = {{marginTop: "70px"}}>
                        {/* background layer */}
                        <div className = "flex flex-col w-full absolute top-0 left-0">
                            <img className = "w-full object-contain relative" alt = "background secondary" src = {BackgroundSecondary} />
                            <img className = "w-full object-contain relative" style = {{marginTop: "400px"}} alt = "background territary" src = {BackgroundTeritary} />
                        </div>
                        <div className = "flex w-full box-border relative" style = {{paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                <img className = "w-full object-contain duration-1000" alt = "multiviews" src = {MultiViews} style = {{opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateX(0px)" : "translateX(-25px)",}} />
                            </div>
                            <div className = "flex" style = {{flex: 1.5, marginTop: "60px"}}>
                                <div className = "flex flex-1" />
                                <div className = "flex flex-col duration-1000" style = {{flex: 5, opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateY(0px)" : "translateY(-25px)", transitionDelay: "100ms"}}>
                                    <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>What have we done?</p>
                                    <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px"}}>A mobile application that provides integrated technical customer<br></br>support, which fits neatly in your pocket, as well as access to Aderco’s<br></br>extensive knowledge and expertise when time is of the essence.</p>
                                    <div className = "flex flex-col" style = {{marginTop: "32px"}}>
                                        <div className = "flex items-center duration-1000 ease-in-out" style = {{opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateX(0px)" : "translateX(25px)", transitionDelay: "200ms"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px",}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>User friendly platform</p>
                                        </div>
                                        <div className = "flex items-center duration-1000 ease-in-out" style = {{marginTop: "18px", opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateX(0px)" : "translateX(25px)", transitionDelay: "300ms"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>Flexible development partner</p>
                                        </div>
                                        <div className = "flex items-center duration-1000 ease-in-out" style = {{marginTop: "18px", opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateX(0px)" : "translateX(25px)", transitionDelay: "400ms"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>CO2 calculator</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center justify-between relative" style = {{marginTop: "96px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "42px"}} src = {Light} alt = "light" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Aderco already had a vague idea of what they needed, but it was thanks to Agifly’s design methodology that they were challenged to think further and explore new ideas.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "36px"}} src = {Pencil} alt = "pencil" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Using an incremental process, Agifly developed the Aderco Maritime App, using an iterative approach allowing for continuous feedback from Aderco</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{width: "58px"}} src = {Screen} alt = "screen" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Designed for both shore-based and sea-going staff alike, Aderco’s Mobile App delivers over 40 years of experience in environmentally-friendly, sustainable and 100% vegetal fuel-treatment technology.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "158px"}}>
                            <div className = "w-full flex justify-center items-center relative overflow-hidden" style = {{height: "487px"}}>
                                <div className = "usecase-alt-carousel-button absolute left-0 z-10 duration-300" onClick = {() => handleCarouselLeft()} style = {{opacity: carouselIndex === 0 ? 0 : 1, marginLeft: "72px"}}>
                                    <BiLeftArrowAlt size = "32px" color = "white" />
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(0 - carouselIndex) * 20}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Mobile1} alt = "Mobile 1" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(1 - carouselIndex) * 20}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Mobile2} alt = "Mobile 2" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(2 - carouselIndex) * 20}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Mobile3} alt = "Mobile 3" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(3 - carouselIndex) * 20}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Mobile4} alt = "Mobile 4" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(4 - carouselIndex) * 20}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Mobile5} alt = "Mobile 5" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(5 - carouselIndex) * 20}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Mobile6} alt = "Mobile 6" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(6 - carouselIndex) * 20}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Mobile7} alt = "Mobile 7" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(7 - carouselIndex) * 20}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Mobile8} alt = "Mobile 8" />  
                                </div>
                                <div className = "usecase-alt-carousel-button absolute right-0 z-10 duration-300" onClick = {() => handleCarouselRight()} style = {{opacity: carouselIndex === 7 ? 0 : 6, marginRight: "72px"}}>
                                    <BiRightArrowAlt size = "32px" color = "white" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style = {{marginTop: "48px"}}>
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 0 ? 1 : 0.3}} onClick={() => setCarouselIndex(0)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 1 ? 1 : 0.3}} onClick={() => setCarouselIndex(1)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 2 ? 1 : 0.3}} onClick={() => setCarouselIndex(2)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 3 ? 1 : 0.3}} onClick={() => setCarouselIndex(3)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 4 ? 1 : 0.3}} onClick={() => setCarouselIndex(4)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 5 ? 1 : 0.3}} onClick={() => setCarouselIndex(5)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 6 ? 1 : 0.3}} onClick={() => setCarouselIndex(6)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 7 ? 1 : 0.3}} onClick={() => setCarouselIndex(7)}/>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center justify-between relative" style = {{marginTop: "96px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>User friendly platform</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>The Aderco Maritime App provides the technical team and operational managers with a user-friendly platform to solve technical issues. It enables them to make quick and efficient decisions and find the necessary documentation.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                            <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Flexible development partner</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Thanks to the incremental development process, improvements can be easily made. This ensures that the app is always up to date and meets the changing needs of Aderco.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Digital transformation</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>The Aderco Maritime App has had a significant impact on Aderco’s internal processes. Through digital transformation, paperwork has been eliminated, human errors reduced, and operational efficiency and customer satisfaction improved.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center relative" style = {{marginTop: "82px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-1 justify-center items-center">
                                <img className = "object-contain" style = {{height: "317px"}} src = {OptifluxDesign} alt = "optiflux design" />
                            </div>
                            <div className = "flex flex-col justify-center" style = {{flex: 2}}>
                                <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "24px"}}>Agifly's design methodology challenged us. It was a<br></br>creative and inspiring process that resulted in an app that<br></br>exceeded our expectations.</p>
                                <p className = "font-sofia font-bold text-black text-left" style = {{fontSize: "16px", marginTop: "16px"}}>Gerald Baiwir,<br></br>App Development Manager at Aderco.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center relative" style = {{marginTop: "50px"}} ref={targetRef}>
                            <div className = "flex w-full items-center">
                                <div className = "flex flex-1">
                                    <img className = "object-contain" style = {{height: "803px"}} src = {LaptopWBackground} alt = "laptop with background" />
                                </div>
                                <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                    <div className = "flex flex-col" style = {{width: "396px", marginLeft: "111px"}}>
                                        <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>Ask us anything</p>
                                        <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px"}}>Opportunities for digitization search, an idea validate or<br></br>develop a digital solution based on your requirements?<br></br>Contact us.</p>
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "First name" value = {firstname} onChange = {handleFirstnameChange} style = {{marginTop: "32px"}} />
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Email" value = {email} onChange = {handleEmailChange} style = {{marginTop: "16px"}} />
                                        <textarea className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Message" value = {message} onChange = {handleMessageChange} style = {{marginTop: "16px", height: "166px", paddingTop: "16px"}} />
                                        <button type = "button" className = "usecase-alt-button font-sofia" style = {{marginTop: "21px"}} onClick = {onFormSubmit}>CONTACT US</button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full flex justify-between items-center box-border relative" style = {{marginTop: "0px", paddingLeft: "72px", paddingRight: "72px", height: "85px", backgroundColor: "#072D5A"}}>
                            <div className = "flex items-center">
                                <img className = "object-contain" style = {{height: "36px"}} src = {FooterLogo} alt = "footer logo" />
                                <p className = "font-sofia font-regular text-white" style ={{fontSize: "12px", marginLeft: "10px"}}>© 2023 Agifly . Privacy | Terms and Conditions</p>
                            </div>
                            <div className = "flex">
                                <img className = "object-contain" style = {{height: "24px"}} src = {FooterLinkedin} alt = "footer linkedin" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className = "w-full flex flex-col" style = {{backgroundColor: "#FAFAFA"}}>
                    <div className = "w-full flex bg-white items-center justify-between box-border relative z-10" style = {{paddingLeft: "15px", paddingRight: "15px", height: "72px"}}>
                        <div className = "flex">
                            <a href="https://www.agifly.be/">
                                <img className = "object-contain" style = {{height: "47px"}} src = {HeaderLogo} alt = "header logo" />
                            </a>
                        </div>
                        <div className = "flex items-center">
                            <button type = "button" className = "usecase-alt-header-button-one font-sofia" style = {{marginRight: "18px"}} onClick = {scrollToBottom}>
                                <img className = "object-contain" style = {{height: "17px"}} src = {MessageIcon} alt = "message icon" />
                                {/* <p>NOUS CONTACTER</p> */}
                            </button>
                            <button type = "button" className = "usecase-alt-header-button-two font-sofia" onClick = {goToHome}>
                                <img className = "object-contain" style = {{height: "15px"}} src = {IconLogo} alt = "icon logo" />
                                {/* <p>NOTRE SITE</p> */}
                            </button>
                        </div>
                    </div>
                    <div className = "w-full box-border flex flex-col items-center relative" style = {{paddingLeft: "15px", paddingRight: "15px"}}>
                        <img className = "w-full object-cover absolute left-0 top-0" src = {Background} alt = "background" />
                        <div className = "w-full flex shrink-0 flex-col items-center justify-center relative">
                            <p className = "font-sofia font-black text-white text-center" style = {{fontSize: "34px"}}>Aderco</p>
                            <p className = "font-sofia font-regular text-white usecase-alt-header-subtext text-left">Aderco Maritime App - User Friendly<br></br> Digital Support for Aderco’s Technical<br></br>Team</p>
                        </div>
                        <div className = "w-full flex shrink-0 relative">
                            <img className = "object-fit w-full" style = {{marginTop: '24px'}} src = {Laptop} alt = "laptop" />
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex flex-col items-center justify-center" style = {{marginTop: "15px", paddingLeft: "15px", paddingRight: "15px"}}>
                        <p className = "font-sofia font-black text-black" style = {{fontSize: "38px"}}>How did we get started?</p>
                        <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", maxWidth: "800px", marginTop: "16px"}}>With the goal of staying ahead of the competition and being innovative, Aderco chose to digitize. Together with Agifly, they have developed a state-of-the-art mobile app that provides user-friendly support to the technical team and operational managers</p>
                    </div>
                    <div className = "w-full flex flex-col box-border relative" style = {{marginTop: "25px"}}>
                        {/* background layer */}
                        <div className = "flex flex-col w-full absolute top-0 left-0">
                            <img className = "w-full object-contain relative" alt = "background secondary" src = {BackgroundSecondary} />
                            <img className = "w-full object-contain relative" style = {{marginTop: "400px"}} alt = "background territary" src = {BackgroundTeritary} />
                        </div>
                        <div className = "flex flex-col w-full box-border relative" style = {{paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                <img className = "w-full object-contain" alt = "multiviews"  src = {MultiViews} />
                            </div>
                            <div className = "flex" style = {{flex: 1.5, marginTop: "20px"}}>
                                <div className = "flex flex-col" style = {{flex: 5}}>
                                    <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>What have we done?</p>
                                    <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px"}}>A mobile application that provides integrated technical customer<br></br> support, which fits neatly in your pocket, as well as access to Aderco’s<br></br>extensive knowledge and expertise when time is of the essence.</p>
                                    <div className = "flex flex-col" style = {{marginTop: "32px"}}>
                                        <div className = "flex items-center">
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>User friendly platform</p>
                                        </div>
                                        <div className = "flex items-center" style = {{marginTop: "18px"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>Flexible development partner</p>
                                        </div>
                                        <div className = "flex items-center" style = {{marginTop: "18px"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>CO2 calculator</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center justify-between relative" style = {{marginTop: "25px", paddingLeft: "۲۵px", paddingRight: "۲۵px"}}>
                            <div className = "flex w-full flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "42px"}} src = {Light} alt = "light" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Aderco already had a vague idea of what they needed, but it was thanks to Agifly’s design methodology that they were challenged to think further and explore new ideas.</p>
                            </div>
                            <div className = "flex w-full flex-col items-center usecase-alt-card" style = {{marginTop: "25px"}}>
                                <img className = "object-contain" style = {{height: "36px"}} src = {Pencil} alt = "pencil" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Using an incremental process, Agifly developed the Aderco Maritime App, using an iterative approach allowing for continuous feedback from Aderco</p>
                            </div>
                            <div className = "flex flex-col items-center w-full usecase-alt-card" style = {{marginTop: "25px"}}>
                                <img className = "object-contain" style = {{width: "58px"}} src = {Screen} alt = "screen" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Designed for both shore-based and sea-going staff alike, Aderco’s Mobile App delivers over 40 years of experience in environmentally-friendly, sustainable and 100% vegetal fuel-treatment technology.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px"}}>
                            <div className = "w-full flex justify-center items-center relative overflow-hidden" style = {{height: "250px"}}>
                                <div className = "usecase-alt-carousel-button absolute left-0 z-10 duration-300" onClick = {() => handleCarouselLeft()} style = {{opacity: carouselIndex === 0 ? 0 : 1, marginLeft: "15px"}}>
                                    <BiLeftArrowAlt size = "32px" color = "white" />
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(0 - carouselIndex) * 40}%`}}>
                                    <img className = "object-contain disable-highlight w-full" style = {{height: "250px"}} src = {Mobile1} alt = "mobile 1" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(1 - carouselIndex) * 40}%`}}>
                                    <img className = "object-contain disable-highlight w-full" style = {{height: "250px"}} src = {Mobile2} alt = "mobile 2" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(2 - carouselIndex) * 40}%`}}>
                                    <img className = "object-contain disable-highlight w-full" style = {{height: "250px"}} src = {Mobile3} alt = "mobile 3" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(3 - carouselIndex) * 40}%`}}>
                                    <img className = "object-contain disable-highlight w-full" style = {{height: "250px"}} src = {Mobile4} alt = "mobile 4" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(4 - carouselIndex) * 40}%`}}>
                                    <img className = "object-contain disable-highlight w-full" style = {{height: "250px"}} src = {Mobile5} alt = "mobile 5" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(5 - carouselIndex) * 40}%`}}>
                                    <img className = "object-contain disable-highlight w-full" style = {{height: "250px"}} src = {Mobile6} alt = "mobile 6" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(6 - carouselIndex) * 40}%`}}>
                                    <img className = "object-contain disable-highlight w-full" style = {{height: "250px"}} src = {Mobile7} alt = "mobile 7" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(7 - carouselIndex) * 40}%`}}>
                                    <img className = "object-contain disable-highlight w-full" style = {{height: "250px"}} src = {Mobile8} alt = "mobile 8" />  
                                </div>
                                <div className = "usecase-alt-carousel-button absolute right-0 z-10 duration-300" onClick = {() => handleCarouselRight()} style = {{opacity: carouselIndex === 7 ? 0 : 1, marginRight: "15px"}}>
                                    <BiRightArrowAlt size = "32px" color = "white" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style = {{marginTop: "25px"}}>
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 0 ? 1 : 0.3}} onClick={() => setCarouselIndex(0)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 1 ? 1 : 0.3}} onClick={() => setCarouselIndex(1)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 2 ? 1 : 0.3}} onClick={() => setCarouselIndex(2)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 3 ? 1 : 0.3}} onClick={() => setCarouselIndex(3)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 4 ? 1 : 0.3}} onClick={() => setCarouselIndex(4)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 5 ? 1 : 0.3}} onClick={() => setCarouselIndex(5)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 6 ? 1 : 0.3}} onClick={() => setCarouselIndex(6)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 7 ? 1 : 0.3}} onClick={() => setCarouselIndex(7)}/>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center justify-between relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>User friendly platform</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>The Aderco Maritime App provides the technical team and operational managers with a user-friendly platform to solve technical issues. It enables them to make quick and efficient decisions and find the necessary documentation.</p>
                            </div>
                            <div className = "flex flex-col items-center usecase-alt-card" style = {{marginTop: "25px"}}>
                            <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Flexible development partner</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Thanks to the incremental development process, improvements can be easily made. This ensures that the app is always up to date and meets the changing needs of Aderco.</p>
                            </div>
                            <div className = "flex flex-col items-center usecase-alt-card" style = {{marginTop: "25px"}}>
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Digital transformation</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>The Aderco Maritime App has had a significant impact on Aderco’s internal processes. Through digital transformation, paperwork has been eliminated, human errors reduced, and operational efficiency and customer satisfaction improved.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex w-full justify-center items-center">
                                <img className = "object-contain" style = {{height: "317px"}} src = {OptifluxDesign} alt = "optiflux design" />
                            </div>
                            <div className = "flex flex-col w-full justify-center items-center" style = {{marginTop: "25px"}}>
                                <p className = "font-sofia font-regular text-black text-center" style = {{fontSize: "24px"}}>Agifly's design methodology challenged us. It was a<br></br>creative and inspiring process that resulted in an app that<br></br>exceeded our expectations</p>
                                <p className = "font-sofia font-bold text-black text-center" style = {{fontSize: "16px", marginTop: "16px"}}>Gerald Baiwir,<br></br>App Development Manager at Aderco.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px"}} ref={targetRef}>
                            <div className = "flex flex-col w-full items-center">
                                <div className = "flex w-full">
                                    <img className = "object-contain" src = {LaptopWBackground} alt = "laptop with background" />
                                </div>
                                <div className = "flex w-full" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                                    <div className = "flex flex-col w-full">
                                        <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>Ask us anything</p>
                                        <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "16px"}}>Opportunities for digitization search, an idea validate or<br></br>develop a digital solution based on your requirements?<br></br>Contact us.</p>
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "First name" value = {firstname} onChange = {handleFirstnameChange} style = {{marginTop: "32px"}} />
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Email" value = {email} onChange = {handleEmailChange} style = {{marginTop: "16px"}} />
                                        <textarea className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Message" value = {message} onChange = {handleMessageChange} style = {{marginTop: "16px", height: "166px", paddingTop: "16px"}} />
                                        <button type = "button" className = "usecase-alt-button font-sofia" style = {{marginTop: "21px"}} onClick = {onFormSubmit}>CONTACT US</button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full flex flex-col box-border items-center justify-center relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px", height: "100px", backgroundColor: "#072D5A"}}>
                            <div className = "w-full flex items-center justify-between">
                                <div className = "flex items-center">
                                    <img className = "object-contain" style = {{height: "36px"}} src = {FooterLogo} alt = "footer logo" />
                                </div>
                                <div className = "flex">
                                    <img className = "object-contain" style = {{height: "24px"}} src = {FooterLinkedin} alt = "footer linkedin" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style ={{marginTop: "15px"}}>
                                <p className = "font-sofia font-regular text-white" style ={{fontSize: "12px"}}>© 2023 Agifly . Privacy | Terms and Conditions</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>  
    )
}

export default UsecaseAlt2;