import React, {useEffect} from "react";
import "./styles/index.css";
import "./App.css";
import CustomRoutes from "./pages/routes/routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-slideshow-image/dist/styles.css";
import ReactGA from 'react-ga4';

function App() {

  useEffect(() => {
    ReactGA.initialize("UA-161872766-1");
    // To Report Page View 
    // ReactGA.pageview("/");
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, [])

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        closeButton={false}
        hideProgressBar
        style={{ textAlign: "left" }}
      />
      <CustomRoutes />
    </div>
  );
}

export default App;
