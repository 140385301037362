import React, { useCallback, useEffect, useState } from "react";
import Axios from "axios";
import { Markup } from "interweave";
import Lottie from "react-lottie";
import ServicesAnimation from "../../lottiesAnimation/ourServices.json";
import "./serviceSplashSection.scss";

// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
const ServiceSplashSection = (props) => {
  const { data } = props;
  const options = {
    loop: false,
    autoplay: true,
    animationData: ServicesAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <div className="bg-primary opacity-100 font-brandonRegular react-slideshow-wrapper">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 xs:grid-cols-1 pb-10">
          {data.length > 0 && data.map((single, index) => (
            <>
              <div className="xl:bg-forms-homepage-tri lg:bg-forms-homepage-tri pt-0 xl:pt-24 lg:pt-24">
                <h1 className="text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl font-brandonBlack xl:leading-homeSplashHeading lg:leading-homeSplashHeading text-center lg:text-left xl:text-left text-white">
                  <Markup content={single.title} />
                </h1>
                <p className="text-tiny xl:text-homeSplashDetail lg:text-homeSplashDetail text-center xl:text-left lg:text-left md:text-left text-white font-brandonLight">
                  <Markup content={single.description} />
                </p>
              </div>
              <div className="lottieAnimationServices order-last xs:order-first md:order-first lg:order-last xl:order-last">
                <Lottie options={options} />
              </div>
            </>
          ))}

        </div>
      </div>
    </div>
  );
};

export default ServiceSplashSection;
