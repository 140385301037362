import { useEffect, useState } from "react";
import FilterProjects from "../../components/filterProjects/buttonsCard";
import AllProjects from "../../components/allProjects/allProjects";
import Partners from "../../components/partners/partners";
import TopSection from "../../components/projectTopSection";
import { buttonsConstant, All_Projects } from "./constant";
import Loader from "../../components/loader/loader";

// Services
import { getAllSplash } from "../../service/splash";
import { getAll, filterProject } from "../../service/projects";

const Projects = () => {
  const [loading, setLoading] = useState(false);
  const [splashData, setSplashData] = useState([]);
  const [projects, setProjects] = useState();
  const [buttonList, setButtonList] = useState();
  const [allProjects, setAllProjects] = useState([]);
  const [filter, setFilter] = useState([]);
  let arr = [];

  useEffect(() => {
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setSplashData(
          response?.data?.data?.filter((row) => row.pagesId === "6")
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
    setButtonList(buttonsConstant);
  }, []);

  useEffect(() => {
    // Get Page
    if (filter?.length === 0) {
      setLoading(true);
      setAllProjects(All_Projects);
      getAll()
        .then((response) => {
          setProjects(response?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [filter]);

  const filterRecordsHandler = (value) => {
    filterProject(value)
      .then((response) => {
        setProjects(response?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleButtons = (e) => {
    let index = e.target.id;
    let items = [...buttonList];
    let prevValue = items[index].selected;
    items[index].selected = !prevValue;
    setButtonList([...items]);
    if (filter.find((item) => item === e.target.name)) {
      let selectedButtons = filter.filter((item) => item !== e.target.name);
      setFilter([...selectedButtons]);
      filterRecordsHandler(selectedButtons);
    } else {
      arr.push(e.target.name);
      setFilter([...filter, ...arr]);
      filterRecordsHandler([...filter, ...arr]);
    }
    // to show selected records
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TopSection data={splashData} />
          <FilterProjects
            buttonList={buttonList}
            setButtonList={setButtonList}
            handleButtons={handleButtons}
          />
          <AllProjects allProjects={allProjects} projects={projects} />
          <Partners />
        </div>
      )}
    </>
  );
};
export default Projects;
