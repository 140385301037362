import React, { useState } from "react";
import { Markup } from "interweave";
import { motion, AnimatePresence } from "framer-motion";
import "./homeSpashSection.scss";
const Names = [
  {
    id: 1,
    text: "DIGITAL SOLUTIONS",
  },
  {
    id: 2,
    text: "WEBSITES",
  },
  {
    id: 3,
    text: "MOBILE APPS",
  },
  {
    id: 4,
    text: "WEB APPS",
  },
  {
    id: 5,
    text: "SAAS",
  },
];
// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;
const HomeSplashSection = (props) => {
  // Props
  const { data } = props;
  // Counter
  const [count, setCount] = useState(0);

  // Timeout For Animation
  setTimeout(() => {
    if (count == 4) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
  }, 4000);

  return (
    <div className="bg-soustraction-img">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 xs:grid-cols-1">
          {data.length > 0 && data.map((single, index) => (
            <>
              <div className="xl:bg-forms-homepage-tri lg:bg-forms-homepage-tri font-brandonBlack xl:pt-32 lg:pt-32 md:pt:32 xs:order-last md:order-last lg:order-first xl:order-first">
                <AnimatePresence exitBeforeEnter>
                  <h1 className="text-2xl xl:text-homeSplashHeading lg:text-4xl xl:leading-homeSplashHeading lg:leading-homeSplashHeading md:text-3xl xs:text-xl text-white flex gap-1 xl:justify-start lg:justify-start xs:justify-center">
                    WE BUILD
                    <motion.div
                      key={Names[count] ? Names[count].id : ""}
                      animate={{ opacity: 1, y: 0 }}
                      initial={{ opacity: 0, y: 3 }}
                      exit={{ opacity: 0, y: -3 }}
                      transition={{ duration: 1 }}
                      className="pl-1 text-orange"
                    >
                      {Names[count] ? Names[count].text : ""}
                    </motion.div>
                  </h1>
                  <h1 className="text-xl xl:text-homeSplashHeading lg:text-4xl md:text-3xl sm:text-2xl xl:leading-homeSplashHeading lg:leading-homeSplashHeading text-center lg:text-left xl:text-left text-white">
                    THAT WORK FOR YOU
                  </h1>
                </AnimatePresence>
                <h5 className="text-tiny xl:text-homeSplashDetail lg:text-homeSplashDetail text-center xl:text-left lg:text-left md:text-center text-white font-brandonLight mt-5">
                  <Markup content={single.description} />
                </h5>
                {/* <h5 className="text-tiny xl-text-homeSplashDetail lg:text-homeSplashDetail text-center xl:text-left lg:text-left md:text-center text-white font-brandonLight">
                  AND THE PUBLIC SECTOR
                </h5> */}
              </div>
              <div className="order-last xs:order-first md:order-first lg:order-last xl:order-last">
                <img src={AssetsUrl + single.image} />
              </div>
            </>
          ))}

        </div>
      </div>
    </div>
  );
};

export default HomeSplashSection;
