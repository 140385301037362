import { useEffect, useState } from "react";
import HomeSplashSection from "../../components/homeSplashSection/homeSplashSection";
import Partners from "../../components/partners/partners";
import QuestionsSection from "../../components/questionsSection/questionsSections";
import Animation from "../../components/animation/animation";
import HomeWorkWithUs from "../../components/homeWorkWithUs/homeWorkWithUs";
import HomeProjects from "../../components/homeProjects/homeProjects";
import CTA from "../../components/cta/cta";
import Homeservices from "../../components/homeServices/homeServices";
import Cookies from "../../components/cookies/cookies";
// Services
import { getAll } from "../../service/home";
import { getAllSplash } from "../../service/splash";
import Loader from "../../components/loader/loader";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    animationData: [],
    projectsData: [],
    workWithUsData: [],
    coreCapabilitiesData: [],
    questionsData: [],
  });
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "1"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          animationData: response?.data?.data?.filter(
            (row) => row.section === "animation"
          ),
          projectsData: response?.data?.data?.filter(
            (row) => row.section === "projects"
          ),
          workWithUsData: response?.data?.data?.filter(
            (row) => row.section === "work-with-us"
          ),
          coreCapabilitiesData: response?.data?.data?.filter(
            (row) => row.section === "core-capabilities"
          ),
          questionsData: response?.data?.data?.filter(
            (row) => row.section === "questions"
          ),
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <HomeSplashSection data={state.splashData} />
          <Animation data={state.animationData} />
          <HomeProjects data={state.projectsData} />
          <HomeWorkWithUs data={state.workWithUsData} />
          <Homeservices data={state.coreCapabilitiesData} />
          <QuestionsSection data={state.questionsData} />
          <CTA />
          <Partners />
          <Cookies />
        </div>
      )}
    </>
  );
};
export default Home;
