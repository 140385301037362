import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;
export const getAll = () => {
  return Axios.get(API_URL + "projects");
};

export const filterProject = (values) => {
  let data = {
    type: values,
  };
  console.log("data", data);
  return Axios.post(API_URL + "projects/search", data);
};
