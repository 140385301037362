import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProjectVideo from "../../components/projectVideos/ProjectVideo";
import Details from "../../components/details/details";
import Partners from "../../components/partners/partners";
import Gallery from "../../components/gallery/gallery";
import { getAll } from "../../service/projects";
import { All_Projects } from "../listsOfProjects/constant";

const Projects = () => {
  const [projectData, setProjectData] = useState(null);
  const [projectVideo, setProjectVideo] = useState();
  const { slug } = useParams();
  useEffect(() => {
    if (slug) {
      getAll()
        .then((response) => {
          console.log("all", response?.data?.data);
          // console.log("All_Projects", All_Projects);
          let singleProject = response?.data?.data?.find(
            (project) => project.id === slug
          );
          // if (singleProject) {
          // let video = All_Projects?.find(
          //   (project) => project.title === singleProject?.title
          let video = All_Projects?.find(
            (project) => project.id === parseInt(slug)
          );
          console.log("selected video");
          setProjectVideo(video);
          // }
          setProjectData(singleProject);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [slug]);
  console.log("projectData", projectData);
  // console.log("projectVideo", projectVideo);
  return (
    <div>
      <ProjectVideo
        data={
          projectData?.projectdetails
          // projectVideo
        }
      />
      <Details data={projectData?.projectdetails} />
      <Partners />
      {/* <Gallery topMargin={30} bottomMargin={30} images={[]} /> */}
    </div>
  );
};
export default Projects;
