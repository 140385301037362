import React from "react";
import circleImage from "../../../images/hubspot/Hubspot.png";
import "../../ourSolution/hubSpot/style.scss";
import parse from "html-react-parser";

const Benefits = (props) => {
  const { data } = props;
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-4">
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 xs:grid-cols-1 gap-3 xl:gap-20 lg:gap-20 py-0 xl:py-20 lg:py-20 xs:py-10 text-heading">
        <div className="">
          <h1 className="text-left font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal text-orange">
            {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
          </h1>

          <div className="block xl:hidden lg:hidden pt-0 xs:pt-10 mx-auto">
            <div className="2xl:-ml-80 -mb-24 xl:2xl:-ml-80 -mb-24 lg:-ml-80 -mb-24 md:ml-4 mb-4 xs:ml-4 mb-4">
              <pre className="font-brandonBlack font-bold text-smarBuildingParagraph 2xl:ml-6 xl:ml-6 lg:ml-6 md:mr-72 xs:mr-72">
                <span className="textcolor">Content</span>
                <br></br> to speak to <br></br> customer{" "}
              </pre>
            </div>
            <pre className="md:-mb-0 -mr-0 xs:pl-48  font-bold font-brandonBlack text-smarBuildingParagraph ">
              <span className="textcolor">Messaging</span> <br></br> to engage
              with them
            </pre>
            <img
              src={circleImage}
              className="2xl:mx-0 xl:mx-0 lg:mx-0 md:mx-auto sx:mx-auto m-auto"
            />
            <div className="2xl:-ml-96 -mt-20 xl:-ml-96 -mt-20 lg:-ml-96 -mt-20 md:-ml-96 xs:-ml-96 xs:-ml-96 xs:-ml-72">
              <pre className="font-bold font-brandonBlack text-smarBuildingParagraph">
                <span className="textMessage"> Data</span>
                <br></br>
                to track the end-to-end <br></br>
                <p> experience</p>
              </pre>
            </div>
            <div className="text-right -mr-28 -mt-20 md:mr-40 xs:-mr-4 ">
              <pre className="font-bold font-brandonBlack text-smarBuildingParagraph">
                <span className="textcolor">Automation</span> <br></br>to
                streamline<br></br>touchpoint{" "}
              </pre>
            </div>
            <div className="mt-12">
              <pre className="font-bold font-brandonBlack text-smarBuildingParagraph ml-0 sx:ml-16">
                <span className="textreport">Reporting</span>
                <br></br>to set strategy{" "}
              </pre>
            </div>
          </div>

          <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-10">
            {parse(`<p>${data && data[1] && data[1]?.description}</p>`)}
          </p>
          {/* <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph">
            We bring the five elements of customer experience into one unified
            code base, with the full customer experience at the center of it
            all.
          </p>
          <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph py-5">
            This means you can align content, messaging, data, automation and
            reporting.
          </p> */}
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 xs:grid-cols-1 gap-3 pt-10">
            <div className="bg-secondry rounded-full w-32 h-32 mx-auto">
              <h3 className="mt-12 text-white">
                {parse(`<p>${data && data[2] && data[2]?.title}</p>`)}
              </h3>
            </div>
            <div className="bg-secondry rounded-full w-32 h-32 mx-auto">
              <h3 className="mt-12 text-white">
                {parse(`<p>${data && data[3] && data[3]?.title}</p>`)}
              </h3>
            </div>
            <div className="bg-secondry rounded-full w-32 h-32 mx-auto">
              <h3 className="mt-12 text-white">
                {parse(`<p>${data && data[4] && data[4]?.title}</p>`)}
              </h3>
            </div>
          </div>
        </div>
        <div className="m-auto w-auto hidden xl:block lg:block">
          <div className="2xl:-ml-80 -mb-24 xl:2xl:-ml-80 -mb-24 lg:-ml-80 -mb-24 md:-ml-0 -mb-0">
            <pre className="font-brandonBlack font-bold text-smarBuildingParagraph ml-6">
              <span className="textcolor">Content</span>
              <br></br> to speak to <br></br> customer{" "}
            </pre>
          </div>
          <div className="3xl:-mb-4 -mr-28 2xl:-mb-4 -mr-28 xl:-mb-4 -mr-28 lg:-mb-4 -mr-28">
            <pre className="font-bold font-brandonBlack text-smarBuildingParagraph ml-40">
              <span className="textcolor">Messaging</span> <br></br> to engage
              with them
            </pre>
          </div>
          <img src={circleImage} className="" />
          <div className="-ml-96 -mt-20">
            <pre className="font-bold font-brandonBlack text-smarBuildingParagraph">
              <span className="textMessage"> Data</span>
              <br></br>
              to track the end-to-end <br></br>
              <p> experience</p>
            </pre>
          </div>
          <div className="text-right -mr-28 -mt-20">
            <pre className="font-bold font-brandonBlack text-smarBuildingParagraph">
              <span className="textcolor">Automation</span> <br></br>to
              streamline<br></br>touchpoint{" "}
            </pre>
          </div>
          <div className="mt-12">
            <pre className="font-bold font-brandonBlack text-smarBuildingParagraph">
              <span className="textreport">Reporting</span>
              <br></br>to set strategy{" "}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
