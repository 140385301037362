import React, { useState, useRef, useEffect } from "react";

import "./usecase-alt.css";

// asset imports here
import axios from "axios";
import { toast } from "react-toastify";
import HeaderLogo from "../../images/usecase-alt/navigation/logo_color.svg";
import IconLogo from "../../images/usecase-alt/navigation/icon_logo.svg";
import MessageIcon from "../../images/usecase-alt/navigation/bx-message-square-detail.svg";
import Background from "../../images/usecase-alt/section1/section1_background.png";
import Laptop from "../../images/usecase-alt/section1/section1_screens.png";
import MultiViews from "../../images/usecase-alt/section3/section3_image.png";
import BackgroundSecondary from "../../images/usecase-alt/section3/section345_background.svg";
import Check from "../../images/usecase-alt/section3/icon_check.svg";
import Light from "../../images/usecase-alt/section4/icon_light.svg";
import Pencil from "../../images/usecase-alt/section4/icon_pencil.svg";
import Screen from "../../images/usecase-alt/section4/icon_screens.svg";
import BackgroundTeritary from "../../images/usecase-alt/section7/section78_background.svg";
import OptifluxDesign from "../../images/usecase-alt/section7/section7_image.svg";
import LaptopWBackground from "../../images/usecase-alt/section8/section8_image.png";
import FooterLogo from "../../images/usecase-alt/footer/logo_footer.svg";
import FooterLinkedin from "../../images/usecase-alt/footer/Icon awesome-linkedin-in.svg";

// carousel parts
import Screen1 from "../../images/usecase-alt/section5/screen1.png";
import Screen2 from "../../images/usecase-alt/section5/screen2.png";
import Screen3 from "../../images/usecase-alt/section5/screen3.png";

// react icons here
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

const UsecaseAlt = () => {
    const [ carouselIndex, setCarouselIndex ] = useState(1);
    const [ firstname, setFirstname ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ message, setMessage ] = useState("");
    const [ renderPhase, setRenderPhase ] = useState(0);

    const targetRef = useRef(null);

    const pageScrollRef = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", handlePageScroll);
        setRenderPhase(1);

        return () => {
            window.removeEventListener("scroll", handlePageScroll);
        }
    }, [])

    const handleCarouselLeft = () => {
        if(carouselIndex === 0) {
            setCarouselIndex(0);
        } else {
            setCarouselIndex(carouselIndex - 1);
        }
    }

    const handleCarouselRight = () => {
        if(carouselIndex === 2) {
            setCarouselIndex(2);
        } else {
            setCarouselIndex(carouselIndex + 1);
        }
    }

    const scrollToBottom = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const goToHome = () => {
        window.location.href = 'https://www.agifly.be/';
    }

    const handleFirstnameChange = (event) => {
        setFirstname(event.target.value)
    }
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const handlePageScroll = (event) => {
        console.log(window.scrollY)
        if(window.scrollY >= 950) {
            setRenderPhase(3);
        }
        else if(window.scrollY >= 500) {
            setRenderPhase(2);
        }
        else if(window.scrollY >= 300) {
            setRenderPhase(1)
        }
    }

    const onFormSubmit = () => {
        // send email here
        axios
        .post(process.env.REACT_APP_BACKEND_URL + "/email/send", {
            "email": email,
            "name": firstname,
            "message": message
        })
        .then((response) => {
          if (response.status === 200) {
            // Notification
            window.lintrk('track', { conversion_id: 13977202 });
            toast.success("Your message has been sent successfully!", {
                position: "top-right",
            });
          }
        })
        .catch((error) => {});
    }

    return(
        <>
            {window.screen.width > 600 ? (
                <div className = "w-full flex flex-col overflow-y-hidden" style = {{backgroundColor: "#FAFAFA"}} ref = {pageScrollRef} onScroll = {handlePageScroll}>
                    <div className = "w-full flex bg-white items-center justify-between box-border relative z-10" style = {{paddingLeft: "72px", paddingRight: "72px", height: "72px"}}>
                        <div className = "flex">
                            <a href="https://www.agifly.be/">
                                <img className = "object-contain" style = {{height: "47px"}} src = {HeaderLogo} alt = "header logo" />
                            </a>
                        </div>
                        <div className = "flex items-center">
                            <button type = "button" className = "usecase-alt-header-button-one font-sofia" style = {{marginRight: "18px"}} onClick = {scrollToBottom}>
                                <img className = "object-contain" style = {{height: "17px", marginRight: "14px"}} src = {MessageIcon} alt = "message icon" />
                                <p>CONTACT US</p>
                            </button>
                            <button type = "button" className = "usecase-alt-header-button-two font-sofia" onClick = {goToHome}>
                                <img className = "object-contain" style = {{height: "15px", marginRight: "10px"}} src = {IconLogo} alt = "icon logo" />
                                <p>OUR WEBSITE</p>
                            </button>
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex items-center relative" style = {{paddingLeft: "72px", paddingRight: "72px", height: "calc(100vh - 72px)"}}>
                        <img className = "w-full object-cover absolute left-0" style = {{top: "-10%"}} src = {Background} alt = "background" />
                        <div className = "w-full h-full absolute left-0 bg-white duration-700 ease-in-out" style = {{transitionDelay: "0.3s", top: renderPhase >= 1 ? "-100%" : "0%"}} />
                        <div className = "flex flex-1 shrink-0 flex-col items-start justify-start relative" style = {{height: "461px"}}>
                            <p className = "font-sofia font-black text-white usecase-alt-heading duration-500" style = {{transitionDelay: "1s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateY(0px)" : "translateY(15px)"}}>Optiflux</p>
                            <p className = "font-sofia font-regular text-white text-left duration-500" style = {{transitionDelay: "1.1s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateY(0px)" : "translateY(15px)", fontSize: 24}}>The creation of an IoT-enabled SAAS<br></br>platform, allowing the founders to<br></br>focus on the growth of their business</p>
                        </div>
                        <div className = "flex shrink-0 relative justify-center" style = {{flex: 2}}>
                            <img className = "object-fit duration-700 w-full" style = {{transitionDelay: "1.2s", opacity: renderPhase >= 1 ? 1 : 0, transform: renderPhase >= 1 ? "translateX(0px)" : "translateX(15px)"}} src = {Laptop} alt = "laptop" />
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex flex-col items-center justify-center" style = {{marginTop: "160px"}}>
                        <p className = "font-sofia font-black text-black duration-500 ease-in-out" style = {{fontSize: "38px", opacity: renderPhase >= 2 ? 1 : 0, transform: renderPhase >= 2 ? "translateY(0px)" : "translateY(-15px)"}}>How did we get started?</p>
                        <p className = "font-sofia font-regular text-black duration-500 ease-in-out" style = {{fontSize: "16px", maxWidth: "800px", marginTop: "32px", opacity: renderPhase >= 2 ? 1 : 0, transform: renderPhase >= 2 ? "translateY(0px)" : "translateY(15px)", transitionDelay: "0s"}}>The technical proof of concept was carried out by their first developer, but he failed to meet expectations. After our initial contacts, it became clear that there were still issues with the existing solution, and after our technical manager resolved them, things started to progress. Through the rectification of the web application, Optiflux was able to attract its first clients.</p>
                    </div>
                    <div className = "w-full flex flex-col box-border relative" style = {{marginTop: "70px"}}>
                        {/* background layer */}
                        <div className = "flex flex-col w-full absolute top-0 left-0">
                            <img className = "w-full object-contain relative" alt = "background secondary" src = {BackgroundSecondary} />
                            <img className = "w-full object-contain relative" style = {{marginTop: "400px"}} alt = "background territary" src = {BackgroundTeritary} />
                        </div>
                        <div className = "flex w-full box-border relative" style = {{paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                <img className = "w-full object-contain duration-1000" alt = "multiviews" src = {MultiViews} style = {{opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateX(0px)" : "translateX(-25px)",}} />
                            </div>
                            <div className = "flex" style = {{flex: 1.5, marginTop: "60px"}}>
                                <div className = "flex flex-1" />
                                <div className = "flex flex-col duration-1000" style = {{flex: 5, opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateY(0px)" : "translateY(-25px)", transitionDelay: "100ms"}}>
                                    <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>What have we done?</p>
                                    <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px"}}>We helped Optiflux acquire expertise in designing and developing<br></br>custom software platforms to support their technology.</p>
                                    <div className = "flex flex-col" style = {{marginTop: "32px"}}>
                                        <div className = "flex items-center duration-1000 ease-in-out" style = {{opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateX(0px)" : "translateX(25px)", transitionDelay: "200ms"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px",}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>Accelerated time to market of SaaS platform</p>
                                        </div>
                                        <div className = "flex items-center duration-1000 ease-in-out" style = {{marginTop: "18px", opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateX(0px)" : "translateX(25px)", transitionDelay: "300ms"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>Cost-effective solution for a scale-up</p>
                                        </div>
                                        <div className = "flex items-center duration-1000 ease-in-out" style = {{marginTop: "18px", opacity: renderPhase >= 3 ? 1 : 0, transform: renderPhase >= 3 ? "translateX(0px)" : "translateX(25px)", transitionDelay: "400ms"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>Increasing technological maturity of inhouse team</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center justify-center relative" style = {{marginTop: "96px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "42px"}} src = {Light} alt = "light" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>To create an initial robust and scalable version of the Optiflux platform, Agifly and Optiflux embarked on a design/analysis phase. During the design phase, we organised several workshops to align the teams and create a common understanding of the challenges and solutions to be built.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "36px"}} src = {Pencil} alt = "pencil" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Once Optiflux approved the designs and requirements, we were able to start development of the hardware-enabled SAAS platform. Delivery was done in two-week iterations, followed by a demonstration of the developed features.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{width: "58px"}} src = {Screen} alt = "screen" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>The first version of the Optiflux platform consisted of three parts. Part 1, Watchdog, Storage insights and Opti Control DCA. Together these three parts provide a comprehensive solution for fruit traders.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "158px"}}>
                            <div className = "w-full flex justify-center items-center relative overflow-hidden" style = {{height: "487px"}}>
                                <div className = "usecase-alt-carousel-button absolute left-0 z-10 duration-300" onClick = {() => handleCarouselLeft()} style = {{opacity: carouselIndex === 0 ? 0 : 1, marginLeft: "72px"}}>
                                    <BiLeftArrowAlt size = "32px" color = "white" />
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(0 - carouselIndex) * 60}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Screen1} alt = "screen 1" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(1 - carouselIndex) * 60}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Screen2} alt = "screen 2" />  
                                </div>
                                <div className = "w-full w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(2 - carouselIndex) * 60}%`}}>
                                    <img className = "object-contain disable-highlight" style = {{height: "487px"}} src = {Screen3} alt = "screen 3" />  
                                </div>
                                <div className = "usecase-alt-carousel-button absolute right-0 z-10 duration-300" onClick = {() => handleCarouselRight()} style = {{opacity: carouselIndex === 2 ? 0 : 1, marginRight: "72px"}}>
                                    <BiRightArrowAlt size = "32px" color = "white" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style = {{marginTop: "48px"}}>
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 0 ? 1 : 0.3}} onClick={() => setCarouselIndex(0)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 1 ? 1 : 0.3}} onClick={() => setCarouselIndex(1)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 2 ? 1 : 0.3}} onClick={() => setCarouselIndex(2)}/>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center justify-center relative" style = {{marginTop: "96px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Time to market</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>By working with a digital product studio specialized in designing and developing innovative products, the Optiflux team was able to dedicate their precious time to marketing their platform.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                            <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Cost-effectiveness</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>The design and development of the first version were completed in approximately four months. Assembling a team and setting up a process for similar development would have been more expensive and taken longer to have a first version in production.</p>
                            </div>
                            <div className = "usecase-alt-spacing" />
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Knowledge transfer </p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Throughout the different phases of design and development, the founders of Optiflux learned by practicing the challenges of digital product development. Choosing the right partner is important, and the product studio should be comfortable in a complex technical environment.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center relative" style = {{marginTop: "82px", paddingLeft: "72px", paddingRight: "72px"}}>
                            <div className = "flex flex-1 justify-center items-center">
                                <img className = "object-contain" style = {{height: "317px"}} src = {OptifluxDesign} alt = "optiflux design" />
                            </div>
                            <div className = "flex flex-col justify-center" style = {{flex: 2}}>
                                <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "24px"}}>By unburdening us of the technical challenges, Agifly<br></br> allowed us to focus on growth. Ideal partner for technical<br></br>challenges.</p>
                                <p className = "font-sofia font-bold text-black text-left" style = {{fontSize: "16px", marginTop: "16px"}}>Niels Bessemans<br></br>CEO Optiflux</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex items-center relative" style = {{marginTop: "50px"}} ref={targetRef}>
                            <div className = "flex w-full items-center">
                                <div className = "flex flex-1">
                                    <img className = "object-contain" style = {{height: "803px"}} src = {LaptopWBackground} alt = "laptop with background" />
                                </div>
                                <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                    <div className = "flex flex-col" style = {{width: "396px", marginLeft: "50px"}}>
                                        <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>Ask us anything</p>
                                        <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px"}}>Opportunities for digitization search, an idea validate or<br></br>develop a digital solution based on your requirements?<br></br>Contact us.</p>
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "First name" value = {firstname} onChange = {handleFirstnameChange} style = {{marginTop: "32px"}} />
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Email" value = {email} onChange = {handleEmailChange} style = {{marginTop: "16px"}} />
                                        <textarea className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Message" value = {message} onChange = {handleMessageChange} style = {{marginTop: "16px", height: "166px", paddingTop: "16px"}} />
                                        <button type = "button" className = "usecase-alt-button font-sofia" style = {{marginTop: "21px"}} onClick = {onFormSubmit}>CONTACT US</button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full flex justify-between items-center box-border relative" style = {{marginTop: "0px", paddingLeft: "72px", paddingRight: "72px", height: "85px", backgroundColor: "#072D5A"}}>
                            <div className = "flex items-center">
                                <img className = "object-contain" style = {{height: "36px"}} src = {FooterLogo} alt = "footer logo" />
                                <p className = "font-sofia font-regular text-white" style ={{fontSize: "12px", marginLeft: "10px"}}>© 2023 Agifly . Privacy | Terms and Conditions</p>
                            </div>
                            <div className = "flex">
                                <img className = "object-contain" style = {{height: "24px"}} src = {FooterLinkedin} alt = "footer linkedin" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className = "w-full flex flex-col" style = {{backgroundColor: "#FAFAFA"}}>
                    <div className = "w-full flex bg-white items-center justify-between box-border relative z-10" style = {{paddingLeft: "15px", paddingRight: "15px", height: "72px"}}>
                        <div className = "flex">
                            <a href="https://www.agifly.be/">
                                <img onClick={goToHome} className = "object-contain" style = {{height: "47px"}} src = {HeaderLogo} alt = "header logo" />
                            </a>
                        </div>
                        <div className = "flex items-center">
                            <button type = "button" className = "usecase-alt-header-button-one font-sofia" style = {{marginRight: "18px"}} onClick = {scrollToBottom}>
                                <img className = "object-contain" style = {{height: "17px"}} src = {MessageIcon} alt = "message icon" />
                                {/* <p>NOUS CONTACTER</p> */}
                            </button>
                            <button type = "button" className = "usecase-alt-header-button-two font-sofia" onClick = {goToHome}>
                                <img className = "object-contain" style = {{height: "15px"}} src = {IconLogo} alt = "icon logo" />
                                {/* <p>NOTRE SITE</p> */}
                            </button>
                        </div>
                    </div>
                    <div className = "w-full box-border flex flex-col items-center relative" style = {{paddingLeft: "15px", paddingRight: "15px"}}>
                        <img className = "w-full object-cover absolute left-0 top-0" src = {Background} alt = "background" />
                        <div className = "w-full flex shrink-0 flex-col items-center justify-center relative">
                            <p className = "font-sofia font-black text-white text-center" style = {{fontSize: "72px"}}>Optiflux</p>
                            <p className = "font-sofia font-regular text-white usecase-alt-header-subtext text-left">The creation of an IoT-enabled SAAS<br></br>platform, allowing the founders to<br></br>focus on the growth of their business</p>
                        </div>
                        <div className = "w-full flex shrink-0 relative">
                            <img className = "object-fit w-full" style = {{marginTop: '24px'}} src = {Laptop} alt = "laptop" />
                        </div>
                    </div>
                    <div className = "w-full flex box-border flex flex-col items-center justify-center" style = {{marginTop: "15px", paddingLeft: "15px", paddingRight: "15px"}}>
                        <p className = "font-sofia font-black text-black" style = {{fontSize: "38px"}}>How did we get started?</p>
                        <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", maxWidth: "800px", marginTop: "16px"}}>The technical proof of concept was carried out by their first developer, but he failed to meet expectations. After our initial contacts, it became clear that there were still issues with the existing solution, and after our technical manager resolved them, things started to progress. Through the rectification of the web application, Optiflux was able to attract its first clients.</p>
                    </div>
                    <div className = "w-full flex flex-col box-border relative" style = {{marginTop: "25px"}}>
                        {/* background layer */}
                        <div className = "flex flex-col w-full absolute top-0 left-0">
                            <img className = "w-full object-contain relative" alt = "background secondary" src = {BackgroundSecondary} />
                            <img className = "w-full object-contain relative" style = {{marginTop: "400px"}} alt = "background territary" src = {BackgroundTeritary} />
                        </div>
                        <div className = "flex flex-col w-full box-border relative" style = {{paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex flex-1" style = {{marginTop: "25px"}}>
                                <img className = "w-full object-contain" alt = "multiviews"  src = {MultiViews} />
                            </div>
                            <div className = "flex" style = {{flex: 1.5, marginTop: "20px"}}>
                                <div className = "flex flex-col" style = {{flex: 5}}>
                                    <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>What have we done?</p>
                                    <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "32px"}}>We helped Optiflux acquire expertise in designing and developing<br></br>custom software platforms to support their technology.</p>
                                    <div className = "flex flex-col" style = {{marginTop: "32px"}}>
                                        <div className = "flex items-center">
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>Accelerated time to market of SaaS platform</p>
                                        </div>
                                        <div className = "flex items-center" style = {{marginTop: "18px"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>Cost-effective solution for a scale-up</p>
                                        </div>
                                        <div className = "flex items-center" style = {{marginTop: "18px"}}>
                                            <img className = "object-contain" style = {{height: "24px", marginRight: "16px"}} alt = "check" src = {Check} />
                                            <p className=  "font-sofia font-bold text-black text-left" style = {{fontSize: "16px"}}>Increasing technological maturity of inhouse team</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center justify-between relative" style = {{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px"}}>
                            <div className = "flex w-full flex-col items-center usecase-alt-card">
                                <img className = "object-contain" style = {{height: "42px"}} src = {Light} alt = "light" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>To create an initial robust and scalable version of the Optiflux platform, Agifly and Optiflux embarked on a design/analysis phase. During the design phase, we organised several workshops to align the teams and create a common understanding of the challenges and solutions to be built.</p>
                            </div>
                            <div className = "flex w-full flex-col items-center usecase-alt-card" style = {{marginTop: "25px"}}>
                                <img className = "object-contain" style = {{height: "36px"}} src = {Pencil} alt = "pencil" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Once Optiflux approved the designs and requirements, we were able to start development of the hardware-enabled SAAS platform. Delivery was done in two-week iterations, followed by a demonstration of the developed features.</p>
                            </div>
                            <div className = "flex flex-col items-center w-full usecase-alt-card" style = {{marginTop: "25px"}}>
                                <img className = "object-contain" style = {{width: "58px"}} src = {Screen} alt = "screen" />
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>The first version of the Optiflux platform consisted of three parts. Part 1, Watchdog, Storage insights and Opti Control DCA. Together these three parts provide a comprehensive solution for fruit traders.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px"}}>
                            <div className = "w-full flex justify-center items-center relative overflow-hidden" style = {{height: "250px"}}>
                                <div className = "usecase-alt-carousel-button absolute left-0 z-10 duration-300" onClick = {() => handleCarouselLeft()} style = {{opacity: carouselIndex === 0 ? 0 : 1, marginLeft: "15px"}}>
                                    <BiLeftArrowAlt size = "32px" color = "white" />
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(0 - carouselIndex) * 100}%`}}>
                                    <img className = "object-contain disable-highlight w-full" src = {Screen1} alt = "screen 1" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(1 - carouselIndex) * 100}%`}}>
                                    <img className = "object-contain disable-highlight w-full" src = {Screen2} alt = "screen 2" />  
                                </div>
                                <div className = "w-full flex justify-center items-center absolute top-0 duration-300 disable-highlight" style = {{left: `${(2 - carouselIndex) * 100}%`}}>
                                    <img className = "object-contain disable-highlight w-full" src = {Screen3} alt = "screen 3" />  
                                </div>
                                <div className = "usecase-alt-carousel-button absolute right-0 z-10 duration-300" onClick = {() => handleCarouselRight()} style = {{opacity: carouselIndex === 2 ? 0 : 1, marginRight: "15px"}}>
                                    <BiRightArrowAlt size = "32px" color = "white" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style = {{marginTop: "25px"}}>
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 0 ? 1 : 0.3}} onClick={() => setCarouselIndex(0)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 1 ? 1 : 0.3}} onClick={() => setCarouselIndex(1)}/>
                                <div className = "usecase-alt-carousel-dot-spacing" />
                                <div className = "usecase-alt-carousel-dot bg-black cursor-pointer duration-300" style = {{opacity: carouselIndex === 2 ? 1 : 0.3}} onClick={() => setCarouselIndex(2)}/>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center justify-between relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex flex-col items-center usecase-alt-card">
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Time to market</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>By working with a digital product studio specialized in designing and developing innovative products, the Optiflux team was able to dedicate their precious time to marketing their platform.</p>
                            </div>
                            <div className = "flex flex-col items-center usecase-alt-card" style = {{marginTop: "25px"}}>
                            <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Cost-effectiveness</p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>The design and development of the first version were completed in approximately four months. Assembling a team and setting up a process for similar development would have been more expensive and taken longer to have a first version in production.</p>
                            </div>
                            <div className = "flex flex-col items-center usecase-alt-card" style = {{marginTop: "25px"}}>
                                <p className = "font-sofia font-bold text-black" style = {{fontSize: "24px"}}>Knowledge transfer </p>
                                <p className = "font-sofia font-regular text-black" style = {{fontSize: "16px", marginTop: "32px"}}>Throughout the different phases of design and development, the founders of Optiflux learned by practicing the challenges of digital product development. Choosing the right partner is important, and the product studio should be comfortable in a complex technical environment.</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                            <div className = "flex w-full justify-center items-center">
                                <img className = "object-contain" style = {{height: "317px"}} src = {OptifluxDesign} alt = "optiflux design" />
                            </div>
                            <div className = "flex flex-col w-full justify-center items-center" style = {{marginTop: "25px"}}>
                                <p className = "font-sofia font-regular text-black text-center" style = {{fontSize: "24px"}}>By unburdoning is of the technical challenges, Agifly<br></br> allowed us to focus on growth. Ideal partner for technical<br></br>challenges.</p>
                                <p className = "font-sofia font-bold text-black text-center" style = {{fontSize: "16px", marginTop: "16px"}}>Niels Bessemans<br></br>CEO Optiflux</p>
                            </div>
                        </div>
                        <div className = "w-full box-border flex flex-col items-center relative" style = {{marginTop: "25px"}} ref={targetRef}>
                            <div className = "flex flex-col w-full items-center">
                                <div className = "flex w-full">
                                    <img className = "object-contain" src = {LaptopWBackground} alt = "laptop with background" />
                                </div>
                                <div className = "flex w-full" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px"}}>
                                    <div className = "flex flex-col w-full">
                                        <p className = "font-sofia font-black text-black text-left" style = {{fontSize: "38px"}}>Ask us anything</p>
                                        <p className = "font-sofia font-regular text-black text-left" style = {{fontSize: "16px", marginTop: "16px"}}>Opportunities for digitization search, an idea validate or<br></br>develop a digital solution based on your requirements?<br></br>Contact us.</p>
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "First name" value = {firstname} onChange = {handleFirstnameChange} style = {{marginTop: "32px"}} />
                                        <input className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Email" value = {email} onChange = {handleEmailChange} style = {{marginTop: "16px"}} />
                                        <textarea className = "w-full usecase-alt-input font-sofia font-regular outline-none" placeholder = "Message" value = {message} onChange = {handleMessageChange} style = {{marginTop: "16px", height: "166px", paddingTop: "16px"}} />
                                        <button type = "button" className = "usecase-alt-button font-sofia" style = {{marginTop: "21px"}} onClick = {onFormSubmit}>CONTACT US</button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "w-full flex flex-col box-border items-center justify-center relative" style = {{marginTop: "25px", paddingLeft: "15px", paddingRight: "15px", height: "100px", backgroundColor: "#072D5A"}}>
                            <div className = "w-full flex items-center justify-between">
                                <div className = "flex items-center">
                                    <img className = "object-contain" style = {{height: "36px"}} src = {FooterLogo} alt = "footer logo" />
                                </div>
                                <div className = "flex">
                                    <img className = "object-contain" style = {{height: "24px"}} src = {FooterLinkedin} alt = "footer linkedin" />
                                </div>
                            </div>
                            <div className = "flex w-full justify-center" style ={{marginTop: "15px"}}>
                                <p className = "font-sofia font-regular text-white" style ={{fontSize: "12px"}}>© 2023 Agifly . Privacy | Terms and Conditions</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>  
    )
}

export default UsecaseAlt;