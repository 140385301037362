import React from "react";
import CookieConsent from "react-cookie-consent";

import "./cookies.scss";

export default function Cookies() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="ACCEPT"
      cookieName="agifly_cookie"
      style={{
        backgroundColor: "rgb(25, 77, 139)",
        fontFamily: "Brandon Grotesque Light",
      }}
      buttonStyle={{
        color: "white",
        fontSize: "16px",
        padding: "8px 16px",
        backgroundColor: "#EEAC56",
      }}
      expires={150}
    >
      <span style={{ fontSize: "16px" }}>
        We use cookies to improve user experience and analyse website traffic.
        By clicking “Accept“, you agree to our website's cookie use as described
        in our Cookie Policy.
      </span>
    </CookieConsent>
  );
}
