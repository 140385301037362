import { useEffect, useState } from "react";
import TopSection from "../../components/ourSolution/smartBuilding/topSection";
import SecondSection from "../../components/ourSolution/smartBuilding/secondSection";
import Planning from "../../components/ourSolution/smartBuilding/planning";
import Design from "./../../components/ourSolution/smartBuilding/design";
import Build from "./../../components/ourSolution/smartBuilding/build";
import Operate from "../../components/ourSolution/smartBuilding/operate";
import Loader from "../../components/loader/loader";

// Services
import { getAllSplash } from "../../service/splash";
import { getAll } from "../../service/smartBuilding";
const SmartBuilding = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    splashData: [],
    animation: [],
    planning: [],
    design: [],
    build: [],
    operate: [],
  });
  useEffect(() => {
    setLoading(true);
    // Get Splash Screen
    getAllSplash()
      .then((response) => {
        setState((st) => ({
          ...st,
          splashData: response?.data?.data?.filter(
            (row) => row.pagesId === "3"
          ),
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
    // Get Page Data
    getAll()
      .then((response) => {
        setState((st) => ({
          ...st,
          planning: response?.data?.data?.filter(
            (row) => row.section === "planning"
          ),
          design: response?.data?.data?.filter(
            (row) => row.section === "design"
          ),
          animation: response?.data?.data?.filter(
            (row) => row.section === "animation"
          ),
          build: response?.data?.data?.filter((row) => row.section === "build"),
          operate: response?.data?.data?.filter(
            (row) => row.section === "operate"
          ),
        }));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TopSection data={state?.splashData} />
          <SecondSection data={state?.animation} />
          <Planning data={state?.planning} />
          <Design data={state?.design} />
          <Build data={state?.build} />
          <Operate data={state?.operate} />
        </div>
      )}
    </>
  );
};
export default SmartBuilding;
