import React from "react";
import { Markup } from "interweave";
// import AboutAnimation from "../../lottiesAnimation/office.json";
import TopSectionImage from "../../../images/hubspot/logo-tech-hubspot_0.png";
// import "./smartBuilding.scss";

// Assets Url
const AssetsUrl = process.env.REACT_APP_BACKEND_ASSETS_URL;

const OurSolutionTopSection = (props) => {
  const { data } = props;
  return (
    <div className="bg-primary">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1 items-center">
          {data.length > 0 && data.map((single, index) => (
            <>
              <div className="xl:bg-forms-homepage-tri lg:bg-forms-homepage-tri py-10 xl:py-20 lg:py-20 col-span-7 xl:col-span-7 lg:col-span-7 md:col-span-6 xs:col-span-6">
                <h1 className="text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl font-brandonBlack leading-homeSplashHeadin text-center lg:text-left xl:text-left text-white">
                  <Markup content={single.title} />
                  <p className="text-tiny xl:text-homeSplashDetail lg:text-homeSplashDetail text-center xl:text-left lg:text-left md:text-center text-white font-brandonLight">
                    <Markup content={single.description} />
                  </p>
                </h1>
              </div>
              <div className="col-span-5 xl:col-span-5 lg:col-span-5 md:col-span-6 xs:col-span-6 order-last xs:order-first md:order-first lg:order-last xl:order-last mx-0 xl:mx-0 lg:mx-0 xs:mx-auto">
                <img src={AssetsUrl + single.image} className="float-right " />
              </div>
            </>
          ))}

        </div>
      </div>
    </div>
  );
};

export default OurSolutionTopSection;
