import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAll } from "../../service/cta";
import parse from "html-react-parser";

import "./cta.scss";

export default function CTA() {
  const [data, setData] = useState();
  useEffect(() => {
    getAll()
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  return (
    <div className="cta-container flex justify-center items-center">
      {data?.map((single) => (
        <div className="flex sm:flex-col md:flex-row justify-center items-center cta-content-container">
          <img
            src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${single?.image}`}
            alt="paper plane"
            className="cta-icon"
          />
          <div className="flex flex-col items-center">
            <h2 className="text-primary fnt-brandon-black text-3xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl">
              {/* <span className="text-primary">LET'S GET A</span>{" "}
              <span className="text-orange">COFFEE </span>
              <span className="text-primary">TOGETHER</span> */}
              {parse(single?.title)}
            </h2>
            <Link
              to="/contact"
              type="button"
              className="cta-button flex items-center justify-center"
            >
              <h2 className="fnt-brandon-black text-2xl xl:text-2xl lg:text-2xl md:text-2xl xs:text-base text-white">
                {single?.button_text}
              </h2>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}
