import React from "react";
// import Aws from "../../../images/hosting/aws1.png";
// import Azure from "../../../images/hosting/azure1.png";
// import Ovh from "../../../images/hosting/OVH1.png";
// import Scaleway from "../../../images/hosting/scaleway1.png";
// import Alibaba from "../../../images/hosting/alibaba.png";
import { scroller } from "react-scroll";
import parse from "html-react-parser";

const ManagedCloud = (props) => {
  const { data } = props;
  const scrollToSection = () => {
    scroller.scrollTo("ContactForm", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-4">
      <div className="grid xl:grid-cols-1 lg:grid-cols-1 xs:grid-cols-1 gap-3 xl:gap-20 lg:gap-20 py-0 xl:py-10 lg:py-10 xs:py-10 text-heading">
        <div className="">
          <h1 className="text-left uppercase font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal">
            {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
          </h1>
          <p className="  font-brandonLight font-bold text-smarBuildingParagraph pt-4">
            {/* We develop{" "}
            <span className="text-orange font-brandonBold">
              customized solutions
            </span>{" "}
            based on one or more public/private or local cloud platforms
            (Hybrid). The solutions are{" "}
            <span className="text-orange font-brandonBold">fully managed</span>{" "}
            with SLA’s of 99.95% minimum. Our{" "}
            <span className="text-orange font-brandonBold">multi-cloud</span>{" "}
            solutions allows you to select the best possible service for your
            needs. This type of deployment{" "}
            <span className="text-orange font-brandonBold">
              increased the reliability / redundancy
            </span>{" "}
            of your infrastructure. In the case that a cloud fails for example,
            the multi-cloud redundancy will make sure that the infrastructure
            remains online.
            <br />
            <br />
            At Agifly, we do not depend on 1 supplier, and we strongly believe
            that this should also be the case for your project. By all means, we
            guarantee{" "}
            <span className="text-orange font-brandonBold">
              full independence
            </span>{" "}
            and give you the possibility to add/ remove any cloud providers
            without notice. Our team will suggest the{" "}
            <span className="text-orange font-brandonBold">best value</span> for
            money options throughout the lifecycle of your product or service.
            <br />
            We can connect our cloud solutions to your{" "}
            <span className="text-orange font-brandonBold">
              local datacenter / service / office
            </span>{" "}
            to offer{" "}
            <span className="text-orange font-brandonBold">hybrid cloud</span>{" "}
            and enjoy advantages of both world. */}
            {parse(`<p>${data && data[1] && data[1]?.description}</p>`)}
          </p>

          {/* <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-8">
            To meet your most demanding needs in terms of <span className="text-orange font-brandonBold">performance</span>, <span className="text-orange font-brandonBold">availability</span> and features.
             We develop <span className="text-orange font-brandonBold">customized solutions</span> based on one or more <span className="text-orange font-brandonBold">Public / Private</span> or local cloud platforms (<span className="text-orange font-brandonBold">Hybrid cloud</span>).
             These solutions are <span className="text-orange font-brandonBold">fully managed</span> and have an SLA of at least <span className="text-orange font-brandonBold">99.99%</span>.
          </p>
          <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-4">
          Our <span className="text-orange font-brandonBold">multi-cloud solutions</span> allow you to take advantages of the different platforms and to select the best solutions from each of them.
             By using this type of deployment, you increase the <span className="text-orange font-brandonBold">reliability / redundancy</span> of your infrastructure. If one cloud fails, multi-cloud redundancy allows you to keep your infrastructure online.  
          </p>
          <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-4">
            Agifly does not have any dependence on a supplier and we strongly believe that your projects deserve it too. The multi-cloud approach allows you this <span className="text-orange font-brandonBold">full independence</span> and to add / remove any cloud providers without notice.
           This will potentially allow <span className="text-orange font-brandonBold">savings</span>, by choosing the services with the best value for money for each provider.
          </p>
          <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-4">
           If you require, we can add direct access to the multi-cloud platform to <span className="text-orange font-brandonBold">your local datacenter / office</span>, offering to build and hybrid cloud and take up both advantages of cloud and local plateforme.
          </p> */}
          <div className="flex flex-wrap pt-16">
            <div className="md:w-1/3 lg:w-1/5 xs:w-full sm:w-1/2 px-4 mb-8 ">
              <div className="flex justify-center">
                <img
                  class=" "
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[2] && data[2].image
                  }`}
                  alt=""
                />
              </div>
            </div>
            <div className="md:w-1/3 lg:w-1/5 xs:w-full sm:w-1/2 px-4 mb-8 ">
              <div className="flex justify-center">
                <img
                  class=" "
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[3] && data[3].image
                  }`}
                  alt=""
                />
              </div>
            </div>
            <div className="md:w-1/3 lg:w-1/5 xs:w-full sm:w-1/2 px-4 mb-8 ">
              <div className="flex justify-center">
                <img
                  class=" "
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[4] && data[4].image
                  }`}
                  alt=""
                />
              </div>
            </div>
            <div className="md:w-1/3 lg:w-1/5 xs:w-full sm:w-1/2 px-4 mb-8 ">
              <div className="flex justify-center">
                <img
                  class=" "
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[5] && data[5].image
                  }`}
                  alt=""
                />
              </div>
            </div>
            <div className="md:w-1/3 lg:w-1/5 xs:w-full sm:w-1/2 px-4 mb-8 ">
              <div className="flex justify-center">
                <img
                  class=" "
                  src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
                    data && data[6] && data[6].image
                  }`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              className="cta-button flex items-center justify-center"
              onClick={scrollToSection}
            >
              <h2 className="fnt-brandon-black text-2xl xl:text-2xl lg:text-2xl md:text-2xl xs:text-base text-white">
                {data && data[7] && data[7]?.title}
              </h2>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagedCloud;
