import React from "react";
import { useNavigate } from "react-router-dom";

const ProductOwnerHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-primary py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-4">
        <div className="grid xl:grid-cols-12 lg:grid-cols-12 xs:grid-cols-1">
          <div className="col-span-12 ">
            <div className="flex">
              <h4
                className="text-yellow-400 text-sm underline border-yellow-400 cursor-pointer"
                onClick={() => {
                  navigate("/join-us");
                }}
              >
                Join Us
              </h4>
              <h4
                className="text-yellow-400 text-sm mx-1 border-yellow-400 cursor-pointer"
                //   onClick={() => {
                //     navigate("/join-us");
                //   }}
              >
                {">"}
              </h4>
              <h4 className="text-yellow-400 text-sm border-yellow-400 ">
                Product Owner
              </h4>
            </div>
            <h1 className="lg:text-2xl text-left text-white fnt-brandon-black pt-4">
              PRODUCT OWNER
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductOwnerHeader;
