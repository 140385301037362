import React from "react";
import BuildImage from "../../../images/hubspot/image2.png";
import CheckImage from "../../../images/smart-building/check.svg";
import parse from "html-react-parser";

const WhyHubSpot = (props) => {
  const { data } = props;
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-4">
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 xs:grid-cols-1 gap-3 xl:gap-20 lg:gap-20 py-0 xl:py-20 lg:py-20 xs:py-10 text-heading">
        <div className="">
          <h1 className="text-left font-brandonBlack text-2xl xl:text-homeSplashHeading lg:text-homeSplashHeading md:text-3xl xs:text-xl leading-normal">
            {parse(`<h1>${data && data[0] && data[0]?.title}</h1>`)}
          </h1>
          <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph">
            {parse(`<p>${data && data[1] && data[1]?.description}</p>`)}
          </p>
          {/* <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph">
            {data &&
              data[1] &&
              data[1]?.description?.split(/;?\r\n|;?\n|;?\r/)[2]}
          </p> */}

          <div className="font-brandonLight font-bold text-smarBuildingParagraph pt-12">
            <div className="col-span-2 xl:col-span-1 lg:col-span-1 md:col-span-1">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-1 my-auto">
                  <img src={CheckImage} className="h-4" alt="" />
                </div>
                <div className="col-span-11">
                  <p className="text-left">
                    {parse(`<p>${data && data[3] && data[3]?.sub_title}</p>`)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-1">
              {parse(`<p>${data && data[4] && data[4]?.description}</p>`)}
            </p>
          </div>
          <div className="font-brandonLight font-bold text-smarBuildingParagraph pt-12">
            <div className="col-span-2 xl:col-span-1 lg:col-span-1 md:col-span-1">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-1 my-auto">
                  <img src={CheckImage} className="h-4" alt="" />
                </div>
                <div className="col-span-11">
                  <p className="text-left">
                    {" "}
                    {parse(`<p>${data && data[5] && data[5]?.sub_title}</p>`)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-1">
              {parse(`<p>${data && data[6] && data[6]?.description}</p>`)}
            </p>
          </div>
          <div className="font-brandonLight font-bold text-smarBuildingParagraph pt-12">
            <div className="col-span-2 xl:col-span-1 lg:col-span-1 md:col-span-1">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-1 my-auto">
                  <img src={CheckImage} className="h-4" alt="" />
                </div>
                <div className="col-span-11">
                  <p className="text-left">
                    {parse(`<p>${data && data[7] && data[7]?.sub_title}</p>`)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-1">
              {parse(`<p>${data && data[8] && data[8]?.description}</p>`)}
            </p>
          </div>
        </div>
        <div className="xs:flex xs:flex-col-reverse lg:block">
          <img
            src={`${process.env.REACT_APP_BACKEND_ASSETS_URL}/${
              data && data[2] && data[2].image
            }`}
            alt=""
            className="xs:pt-14 lg:pt-0 w-3/4 xl:w-auto lg:w-auto mx-auto xl:mx-0 lg-mx-0"
          />
          <div className="font-brandonLight font-bold text-smarBuildingParagraph pt-12">
            <div className="col-span-2 xl:col-span-1 lg:col-span-1 md:col-span-1">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-1 my-auto">
                  <img src={CheckImage} className="h-4" alt="" />
                </div>
                <div className="col-span-11">
                  <p className="text-left">
                    {parse(`<p>${data && data[9] && data[9]?.sub_title}</p>`)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-1">
              {parse(`<p>${data && data[10] && data[10]?.description}</p>`)}
            </p>
          </div>
          <div className="font-brandonLight font-bold text-smarBuildingParagraph pt-12">
            <div className="col-span-2 xl:col-span-1 lg:col-span-1 md:col-span-1">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-1 my-auto">
                  <img src={CheckImage} className="h-4" alt="" />
                </div>
                <div className="col-span-11">
                  <p className="text-left">
                    {parse(`<p>${data && data[11] && data[11]?.sub_title}</p>`)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-left font-brandonLight font-bold text-smarBuildingParagraph pt-1">
              {parse(`<p>${data && data[12] && data[12]?.description}</p>`)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyHubSpot;
